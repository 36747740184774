import React, { useState } from 'react'
import PatientDetails from '../tabpanes/PatientDetails'
import DiagnosisDetailsTransfer from './components/DiagnosisDetailsTransfer'
import TreatmentDetailsTransfer from './components/TreatmentDetailsTransfer'

const TransferPopup = ({value,setValue,functions}) => {
    const [activeBtn,setActiveBtn] = useState('patient')
    const handleSelectButton = (data) =>{
        setActiveBtn(data)
    }
  return (
    <div className='p-2'>
        <div className='col-12 d-flex justify-content-between align-items-center mb-1'>
            <button className={`col p-3 rounded-3 sample-button ${activeBtn === 'patient' ? 'active' : ''}`}  onClick={()=>handleSelectButton("patient")}>Patient Information</button>
            <button className={`col p-3 rounded-3 sample-button ${activeBtn === 'diagnosis' ? 'active' : ''}`}  onClick={()=>handleSelectButton("diagnosis")}>Diagnosis/Assessment</button>
            <button className={`col p-3 rounded-3 sample-button ${activeBtn === 'treatment' ? 'active' : ''}`}  onClick={()=>handleSelectButton("treatment")}>Treatment Plan</button>
        </div>
        <div style={{overflowY:"scroll",overflowX:"hidden"}}>
           {activeBtn==="patient"&& <PatientDetails lookChange={true} patientDetails={value?.patientDetails} borderChange={true}/>}
           {activeBtn==="diagnosis"&&<DiagnosisDetailsTransfer lookChange={true} value={value} setValue={setValue} functions={functions}/> }
           {activeBtn==="treatment"&& <TreatmentDetailsTransfer lookChange={true} value={value}/>}
        </div>
    </div>
  )
}

export default TransferPopup