import React from 'react'
import AdmPatientDetails from '../AdmProcessTabs/AdmPatientDetails'
import AdmDiagnosisDetails from '../AdmProcessTabs/AdmDiagnosisDetails'

const DisChargeBasicDetails = ({handleBack,handleNext}) => {
  return (
    <div>
        <div className=''>
            <AdmPatientDetails hideButton={true}/>
            <AdmDiagnosisDetails hideButton={true}/>
        </div>
        <div className='container-fluid mt-4 p-4 ps-3'>
        <div className='co1-12 d-flex justify-content-end p-2'>
            <div className='buttonviewdown' onClick={handleBack}>Back</div>
            <div className='buttonviewdown'  onClick={handleNext}>Next</div>
        </div>
        </div>
    </div>
  )
}

export default DisChargeBasicDetails