import React from "react";
import "./DischargeList.css";

const DischargeList = () => {
  return (
    <div className="mb-5 px-3">
      <div className="bg-light p-3 px-5 w-100 rounded">
        <h5>Patient Information</h5>
      </div>

      <div class=" ">
        <div class="Information-container row px-4">
          <div class="col-2  p-2 color-faid">First Name</div>
          <div class="col-2  p-2 color-bright">: Rizly Jenzy</div>
          <div class="col-1  p-2 color-faid">Last Name</div>
          <div class="col-1  p-2 color-bright">: Diza</div>
          <div class="col-1  p-2 "></div>
          <div class="col-2  p-2 color-faid">Admission Time</div>
          <div class="col-1  p-2 color-bright">: 09:30 AM</div>
          <div class="col-1  p-2 color-faid">File No</div>
          <div class="col-1  p-2 color-bright">: 05846</div>
        </div>

        <div class="Information-container row px-4">
          <div class="col-2  p-2 color-faid">Mobile Number</div>
          <div class="col-2  p-2 color-bright">: 9562017332</div>
          <div class="col-1  p-2 color-faid">Date of Birth</div>
          <div class="col-1  p-2 color-bright">: 09/06/1955</div>
          <div class="col-1  p-2 "></div>
          <div class="col-2  p-2 color-faid">Reffered Type</div>
          <div class="col-1  p-2 color-bright">: Internal</div>
          <div class="col-1  p-2 color-faid">Patient Type</div>
          <div class="col-1  p-2 color-bright">: Foringner</div>
        </div>

        <div class="Information-container row px-4">
          <div class="col-2  p-2 color-faid">Age</div>
          <div class="col-2  p-2 color-bright">: 35</div>
          <div class="col-1  p-2 color-faid">Gender</div>
          <div class="col-1  p-2 color-bright">: Female</div>
          <div class="col-1  p-2 "></div>
          <div class="col-2  p-2 color-faid">Emergency Contact</div>
          <div class="col-3  p-2 color-bright">: 9562017332</div>
        </div>

        <div class="Information-container row px-4">
          <div class="col-2  p-2 color-faid">Blood Group</div>
          <div class="col-2  p-2 color-bright">: O-ve</div>
          <div class="col-1  p-2 color-faid">Merital Status</div>
          <div class="col-1  p-2 color-bright">: Single</div>
          <div class="col-1  p-2 "></div>
        </div>

        <div class="Information-container row px-4">
          <div className="col-6">
            <div className="row">
              <div class="col-4  p-2 color-faid">Email ID</div>
              <div class="col-4  p-2 color-bright">: akhil@gmail.com</div>
              <div class="col-2  p-2 color-faid">UIN</div>
              <div class="col-2  p-2 color-bright">: 1955</div>
            </div>
          </div>
          <div className="col-6">
            <div className="row">
              <div class="col-2  p-2 "></div>
              <div class="col-4  p-2 ">Note :</div>
            </div>
          </div>
        </div>

        <div class="Information-container row px-4">
          <div className="col-6">
            <div className="row">
              <div class="col-4  p-2 color-faid">Address Line 1</div>
              <div class="col-4  p-2 color-bright">: Rizly</div>
              <div class="col-2  p-2 color-faid">Pincode </div>
              <div class="col-2  p-2 color-bright">: 15052</div>
            </div>
            <div className="row">
              <div class="col-4  p-2 color-faid">City/Location</div>
              <div class="col-4  p-2 color-bright">: malappuram</div>
              <div class="col-2  p-2 color-faid">Country</div>
              <div class="col-2  p-2 color-bright">: India</div>
            </div>
            <div className="row">
              <div class="col-4  p-2 color-faid">State</div>
              <div class="col-4  p-2 color-bright">: keral</div>
              <div class="col-2  p-2 color-faid">Admission Date</div>
              <div class="col-2  p-2 color-bright">: 09/06/1955</div>
            </div>

          </div>
          <div className="col-6">
            <div className="row">
              <div class="col-2  p-2 "></div>
              <div class="col-10  p-2 ">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="bg-light mt-5 p-3 px-5 w-100 rounded">
          <h5>Primary Diagnosis</h5>
        </div>

        <div className="Information-bottom mt-3 row px-2">
          <div className="col-6">
            <h6 className="bottom-head">Resons For Admission</h6>
            <p className="ps-2">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s.
            </p>
            <div className="d-flex gp-5 mt-5 ">
              <div className="d-flex justify-content-between  duration">
                <p className="bottom-head">Duration</p>
                <p>: 30 days</p>
              </div>
              <div className="d-flex justify-content-around  admission">
                <p className="bottom-head">Date of Admission</p>
                <p>: 15/12/2222</p>
              </div>
            </div>

            <h5 className="bottom-head">Present Issue</h5>
            <p className="ps-2">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s.
            </p>
            <h5 className="bottom-head">Present Issue</h5>
            <ul>
              <li>Fever</li>
              <li>Issue IvI</li>
              <li>15 P</li>
            </ul>

            <div className="ashtavidha-head mt-5  w-100 rounded">
              <h5 className=" bottom-head text-white ">
                Ashtavidha Pariraksha
              </h5>
            </div>

            <div className="col-md-12 gap-2 mt-2 d-flex">
              <button className="px-3 text-white rounded dis-button">Nadi</button>
              <div className="p-2 border w-100 h-100 bg-light rounded">
                <input
                  type="text"
                  name="room_id"
                  placeholder="xxxxxxxxxxxxx"
                  className="w-100 h-100"
                />
              </div>
            </div>
            <div className="col-md-12 gap-2 mt-2 d-flex">
              <button className="px-3 text-white rounded dis-button">Mutra</button>
              <div className="p-2 border w-100 h-100 bg-light rounded">
                <input
                  type="text"
                  name="room_id"
                  placeholder="xxxxxxxxxxxxx"
                  className="w-100 h-100"
                />
              </div>
            </div>
            <div className="col-md-12 gap-2 mt-2 d-flex">
              <button className="px-3 text-white rounded dis-button">Jihwa</button>
              <div className="p-2 border w-100 h-100 bg-light rounded">
                <input
                  type="text"
                  name="room_id"
                  placeholder="xxxxxxxxxxxxx"
                  className="w-100 h-100"
                />
              </div>
            </div>
            <div className="col-md-12 gap-2 mt-2 d-flex">
              <button className="px-3 text-white rounded dis-button">Shabda</button>
              <div className="p-2 border w-100 h-100 bg-light rounded">
                <input
                  type="text"
                  name="room_id"
                  placeholder="xxxxxxxxxxxxx"
                  className="w-100 h-100"
                />
              </div>
            </div>
            <div className="col-md-12 gap-2 mt-2 d-flex">
              <button className="px-3 text-white rounded dis-button">Sparsha</button>
              <div className="p-2 border w-100 h-100 bg-light rounded">
                <input
                  type="text"
                  name="room_id"
                  placeholder="xxxxxxxxxxxxx"
                  className="w-100 h-100"
                />
              </div>
            </div>
          </div>

          <div className="col-6">
            <h5 className="bottom-head">Prakrity Assessment</h5>
            <div className="Prakrity d-flex">
              <div className="d-flex justify-content-between">
                <p>Vatta :</p>
                <p>in%</p>
              </div>
              <div className="d-flex justify-content-around">
                <p>Pitta :</p>
                <p>in%</p>
              </div>
              <div className="d-flex justify-content-between">
                <p>kapha :</p>
                <p>in%</p>
              </div>
            </div>
            <h5 className="bottom-head">Remark</h5>
            <p className="ps-2" >
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s.
            </p>

            <div className="col-md-12">
              
              <label htmlFor="" className="modal-label">
                CID-10 Code
              </label>
              <div className="p-2 border bg-light rounded">
                <input
                  type="text"
                  name="room_id"
                  placeholder="xxxxxxxxxxxxx"
                  className="w-100 h-100"
                />
              </div>
            </div>

            <h5 className="bottom-head">Daiagnosis Summery</h5>
            <p className="ps-2">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s.
            </p>

            <div className="ashtavidha-head mt-3  w-100 rounded">
              <h5 className=" bottom-head text-white ">
                Health Assessment
              </h5>
            </div>

            <div className="col-md-12 gap-2 mt-2 d-flex">
              <button className="px-3 text-white rounded dis-button">General</button>
              <div className="p-2 border w-100 h-100 bg-light rounded">
                <input
                  type="text"
                  name="room_id"
                  placeholder="xxxxxxxxxxxxx"
                  className="w-100 h-100"
                />
              </div>
            </div>

            <div className="col-md-12 gap-2 mt-2 d-flex">
              <button className="px-3 text-white rounded dis-button">Skin</button>
              <div className="p-2 border w-100 h-100 bg-light rounded">
                <input
                  type="text"
                  name="room_id"
                  placeholder="xxxxxxxxxxxxx"
                  className="w-100 h-100"
                />
              </div>
            </div>

            <div className="col-md-12 gap-2 mt-2 d-flex">
              <button className="px-3 text-white rounded dis-button">Head and neck</button>
              <div className="p-2 border w-100 h-100 bg-light rounded">
                <input
                  type="text"
                  name="room_id"
                  placeholder="xxxxxxxxxxxxx"
                  className="w-100 h-100"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DischargeList;
