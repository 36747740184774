import useAxiosPrivate from '../../hooks/useAxiosPrivate'

const useConsultationServices = () => {
    const axiosPrivate = useAxiosPrivate()
    // const axios = useAxios()
    // appointment/patient_convert/<int:appointment_id>/
    //GET REQUESTS
    //GET Medical Prescription
    const getMedicalPrescription = async(id)=>{
        const response = await axiosPrivate.get(`/consultation/medical_prescriptions/list_create/${id}/`) 
        return response.data
    }

  // const getSortFilterPagination = async (data) => {
  //   const response = await axiosPrivate.get(`consultation/appointment/appointment_lists/`, {
  //     params: { ...data },
  //   });
  //   return response.data;
  // };

  const getSortFilterPagination = async (data) => {
    const response = await axiosPrivate.get(
      `consultation/appointment/schedule/`,
      {
        params: { ...data },
      }
    );
    return response.data;
  };

  //GET VISITING HISTORY
  const getVisitingHistory = async (id) => {
    const response = await axiosPrivate.get(
      `/consultation/patient_visit_history/list/patient/${id}/`
    );
    return response.data;
  };

  //GET Test Reports
  const getTestReport = async (id) => {
    const response = await axiosPrivate.get(
      `/consultation/medical_test/list_create/${id}/`
    );
    return response.data;
  };
  //GET Health assessment
  const getHealthAssessment = async (id) => {
    const response = await axiosPrivate.get(
      `/consultation/health/assessment/create/${id}/`
    );
    return response.data;
  };

  //GET ashtavidha data
  const getAshtaVidha = async (id) => {
    const response = await axiosPrivate.get(
      `/consultation/ashtavidha_pariraksha/list_create/${id}/`
    );
    return response.data;
  };
  //GET ashtavidha choices
  const getAshtaChoice = async () => {
    const response = await axiosPrivate.get(
      `/consultation/ashtavidha_pariraksha/choices/`
    );
    return response.data;
  };
  // Get Symptoms
  const getSymptoms = async (id) => {
    const response = await axiosPrivate.get(
      `/consultation/symptoms/list_create/${id}/`
    );
    return response.data;
  };
  //Get Assessment Details - present issue,diagnosis
  const getAppointmentAssessmentdetails = async (id) => {
    const response = await axiosPrivate.get(
      `/consultation/appointment/assessment/create/${id}/`
    );
    return response.data;
  };

  //Get Appointment Information
  const getPatientDetail = async (id) => {
    const response = await axiosPrivate.get(
      `/consultation/appointment/schedule/${id}/`
    );
    return response.data;
  };

  //Get Appointments
  const getAppointmentList = async (data) => {
    const response = await axiosPrivate.get(
      `/consultation/appointment/schedule/`,
      {
        params: { ...data },
      }
    );
    return response.data;
  };

  //get Physician Appointment
  const getPhysicianAppointment = async (data) => {
    const response = await axiosPrivate.get(
      `/consultation/appointment/appointment_lists/`,
      {
        params: { ...data },
      }
    );
    return response.data;
  };

  //Get Appointment Token Settings
  const getAllTokens = async () => {
    const response = await axiosPrivate.get(`/consultation/token/`);
    return response.data;
  };

  //Get Physician Token Settings
  const getPhysicianToken = async (id) => {
    const response = await axiosPrivate.get(`/consultation/token/${id}/`);
    return response.data;
  };

  //Get All vital types
  const getVitalTypes = async () => {
    const response = await axiosPrivate.get(
      `/consultation/vital_types/list_create/`
    );
    return response.data;
  };

  //Get All Medical Test Documents
  const getTestDocuments = async (id) => {
    const response = await axiosPrivate.get(
      `/consultation/medical_document/list/medical_test/${id}/`
    );
    return response.data;
  };

  const getTreatmentDetails = async (id) => {
    const response = await axiosPrivate.get(`/consultation/therapy/create/${id}/`);
    return response.data;
  };

  const getMedicalPrescriptionDetails = async (id) => {
    const response =await axiosPrivate.get(`/consultation/medical_prescriptions/list_create/${id}/`)
    return response.data;
  };
  //POST REQUESTS

  //POST Medical Prescription
  const postMedicalPrescription = async (id, data) => {
    const response = await axiosPrivate.post(
      `/consultation/medical_prescriptions/list_create/${id}/`,
      data
    );
    return response.data;
  };

  // POST Test Reports
  const postTestReport = async (id, data) => {
    const response = await axiosPrivate.post(
      `/consultation/medical_test/list_create/${id}/`,
      data
    );
    return response.data;
  };
  // POST Health Assesment
  const postHealthAssessment = async (id, data) => {
    const response = await axiosPrivate.post(
      `/consultation/health/assessment/create/${id}/`,
      data
    );
    return response.data;
  };
  // POST Ashtavidha
  const postAshtaVidha = async (id, data) => {
    const response = await axiosPrivate.post(
      `/consultation/ashtavidha_pariraksha/list_create/${id}/`,
      data
    );
    return response.data;
  };
  // Post Symptoms
  const postSymptoms = async (id, data) => {
    const response = await axiosPrivate.post(
      `/consultation/symptoms/list_create/${id}/`,
      data
    );
    return response.data;
  };
  //Post Assessment Details - present issue,diagnosis
  const postAppointmentAssessmentdetails = async (id, data) => {
    const response = await axiosPrivate.post(
      `/consultation/appointment/assessment/create/${id}/`,
      data
    );
    return response.data;
  };

  const postTreatmentDetails = async (id, data) => {
    const response = await axiosPrivate.post(`/consultation/therapy/create/${id}/`,data);
    return response.data;
  };

  const postMedicalPrescriptionDetails = async (id, data) => {
    const response = await axiosPrivate.post(`/consultation/medical_prescriptions/list_create/${id}/`,data);
    return response.data;
  };


  //POst Appointment Token Settings
  const postNewToken = async (data) => {
    const response = await axiosPrivate.post(`/consultation/token/`, data);
    return response.data;
  };

  //Post new vital type
  const postNewVitalType = async (data) => {
    const response = await axiosPrivate.post(
      `/consultation/vital_types/list_create/`,
      data
    );
    return response.data;
  };

  //Post Patient Appointment
  const postPatientAppointment = async (data) => {
    const response = await axiosPrivate.post(
      `/consultation/appointment/schedule/`,
      data
    );
    return response.data;
  };

  //Post convert temp patient to registered
  const postConvertAppointmentPatient = async (id, data) => {
    const response = await axiosPrivate.post(
      `/consultation/appointment/patient_convert/${id}/`,
      data
    );
    return response.data;
  };

  const postMedicalDocumentAdd = async (id, data) => {
    const response = await axiosPrivate.post(
      `/consultation/medical_document/create/medical_test/${id}/`,
      data
    );
    return response.data;
  };

  //PUT REQUESTS

  //Edit Patient Appointment
  const putPatientAppointment = async (id, data) => {
    const response = await axiosPrivate.put(
      `/consultation/appointment/schedule/${id}/`,
      data
    );
    return response.data;
  };

  //Edit new vital type
  const putVitalType = async (id, data) => {
    const response = await axiosPrivate.put(
      `/consultation/vital_types/details/${id}/`,
      data
    );
    return response.data;
  };

  //Edit Test Report
  const putTestReport = async (id, data) => {
    const response = await axiosPrivate.put(
      `/consultation/medical_test/detail/${id}/`,
      data
    );
    return response.data;
  };
  //Edit MEDICAL Prescription
  const putMedicalPrescription = async (id, data) => {
    const response = await axiosPrivate.put(
      `/consultation/medical_prescriptions/detail/${id}/`,
      data
    );
    return response.data;
  };

  //Edit AshtaVidha Pariraksha
  const putAshtaVidha = async (id, data) => {
    const response = await axiosPrivate.put(
      `/consultation/ashtavidha_pariraksha/detail/${id}/`,
      data
    );
    return response.data;
  };

  //Edit Health Assessment
  const putHealthAssessment = async (id, data) => {
    const response = await axiosPrivate.put(
      `/consultation/health/assessment/detail/${id}/`,
      data
    );
    return response.data;
  };

  const putTreatmentDetails = async (id, data) => {
    const response = await axiosPrivate.put(`/consultation/therapy/update/${id}/`,data);
    return response.data;
  };

  const putMedicinePrescriptionDetails = async (id, data) => {
    const response = await axiosPrivate.put(`/consultation/medical_prescriptions/detail/${id}/`,data);
    return response.data;
  };
  //DELETE REQUESTS
  // Delete Symptoms
  const delSymptoms = async (id) => {
    const response = await axiosPrivate.delete(
      `/consultation/symptom/delete/${id}/`
    );
    return response.data;
  };

  const delMedicalPrescription = async (id) => {
    const response = await axiosPrivate.delete(
      `/consultation/medical_prescriptions/detail/${id}/`
    );
    return response.data;
  };

  const delMedicalTestReport = async (id) => {
    const response = await axiosPrivate.delete(
      `/consultation/medical_test/detail/${id}/`
    );
    return response.data;
  };

  const delAshtaVidha = async (id) => {
    const response = await axiosPrivate.delete(
      `/consultation/ashtavidha_pariraksha/detail/${id}/`
    );
    return response.data;
  };

  const delHealthAssessment = async (id) => {
    const response = await axiosPrivate.delete(
      `/consultation/health/assessment/detail/${id}/`
    );
    return response.data;
  };

    const delPatientAppointment = async (id)=>{
        const response = await axiosPrivate.delete(`/consultation/appointment/schedule/${id}/`)
        return response.data
    }

    const delTreatmentDetails = async (id) => {
      const response = await axiosPrivate.delete(`/consultation/therapy/update/${id}/`);
      return response.data;
    };

    const delMedicinePrescriptionDetails = async (id) => {
      const response = await axiosPrivate.delete(`/consultation/medical_prescriptions/detail/${id}/`);
      return response.data;
    };

    const getDietRecommended = async(id) =>{
      const response = await axiosPrivate.get(`/consultation/diet/recommended/create/${id}/`);
      return response.data;
    }

    const postDietRecommended = async(id,data) => {
      const response = await axiosPrivate.post(
        `/consultation/diet/recommended/create/${id}/`,
        data
      );
      return response.data;
    };

    const delDietRecommended =async(id) =>{
      const response = await axiosPrivate.delete(
        `/consultation/diet/recommended_food/update/${id}/`
      )
      return response.data;
    }

    const getDietRestricted = async(id) =>{
      const response = await axiosPrivate.get(`/consultation/diet/restricted_food/create/${id}/`);
      return response.data;
    }

    const postDietRestricted = async(id,data) => {
      const response = await axiosPrivate.post(
        `/consultation/diet/restricted_food/create/${id}/`,
        data
      );
      return response.data;
    };

    const delDietRestricted =async(id) =>{
      const response = await axiosPrivate.delete(
        `/consultation/diet/restricted_food/updation/${id}/`
      )
      return response.data;
    }

    const getDietInstruction = async(id) =>{
      const response = await axiosPrivate.get(`/consultation/patient/instruction/create/${id}/`);
      return response.data;
    }

    const postDietInstruction = async(id,data) => {
      const response = await axiosPrivate.post(
        `/consultation/patient/instruction/create/${id}/`,
        data
      );
      return response.data;
    };

    const delDietInstruction =async(id) =>{
      const response = await axiosPrivate.delete(
        `/consultation/patient/instruction/updation/${id}/`
      )
      return response.data;
    }

    const getLifeStyle = async(id) =>{
      const response = await axiosPrivate.get(`/consultation/patient/life_style/create/${id}/`);
      return response.data;
    }

    const postLifeStyle = async(id,data) => {
      const response = await axiosPrivate.post(
        `/consultation/patient/life_style/create/${id}/`,
        data
      );
      return response.data;
    };

    const delLifeStyle =async(id) =>{
      const response = await axiosPrivate.delete(
        `/consultation/patient/life_style/updation/${id}/`
      )
      return response.data;
    }



    return {
    //GET REQUESTS
    getAllTokens,
    getVitalTypes,
    getPhysicianToken,
    getPatientDetail,
    getAppointmentList,
    getPhysicianAppointment,
    getAppointmentAssessmentdetails,
    getSymptoms,
    getAshtaChoice,
    getAshtaVidha,
    getHealthAssessment,
    getTestReport,
    getVisitingHistory,
    getMedicalPrescription,
    getTestDocuments,
    getSortFilterPagination,
    getTreatmentDetails,
    getMedicalPrescriptionDetails,
    getDietRecommended,
    getDietRestricted,
    getDietInstruction,
    getLifeStyle,

    //POST REQUESTS
    postNewToken,
    postNewVitalType,
    postPatientAppointment,
    postConvertAppointmentPatient,
    postAppointmentAssessmentdetails,
    postSymptoms,
    postAshtaVidha,
    postHealthAssessment,
    postTestReport,
    postMedicalPrescription,
    postMedicalDocumentAdd,
    postTreatmentDetails,
    postMedicalPrescriptionDetails,
    postDietRecommended,
    postDietRestricted,
    postDietInstruction,
    postLifeStyle,

    //PUT REQUESTS
    putVitalType,
    putPatientAppointment,
    putTestReport,
    putMedicalPrescription,
    putAshtaVidha,
    putHealthAssessment,
    putTreatmentDetails,
    putMedicinePrescriptionDetails,

    //DELETE REQUESTS
    delSymptoms,
    delMedicalPrescription,
    delMedicalTestReport,
    delAshtaVidha,
    delHealthAssessment,
    delPatientAppointment,
    delTreatmentDetails,
    delMedicinePrescriptionDetails,
    delDietRecommended,
    delDietRestricted,
    delDietInstruction,
    delLifeStyle,
    }
}

export default useConsultationServices;
