import React, { useEffect, useRef, useState } from 'react'
import usePatientServices from '../../../services/api-services/patientServices'
import Swal from 'sweetalert2'
import { GrSearch } from 'react-icons/gr'
import { ButtonToolbar, OverlayTrigger, Popover } from 'react-bootstrap'
import './SearchBox.css'
import Arrow from '../../../../assets/svgs/right-arrow.svg'
const PatientSearchBox = ({
    handleData,
    placeholder,
    icon,
    mobileOnly,
    onChange,
    customButton,
    buttonText,
    buttonClick,
    toggleControl,
    toggleValue,
    defValue,
    handleReset,
    InputType,
}) => {
    const [showSearch, setShowSearch] = useState(false)
    const [focused, setFocused] = useState(false)
    const [search, setSearch] = useState('')
    const [patientList, setPatientList] = useState('')
    const [containerWidth, setContainerWidth] = useState('')
    const [marginLeft, setMarginLeft] = useState('')
    const [error, setError] = useState(null);
    const [isValid, setIsValid] = useState(false);
    const searchBox = useRef()
    const searchBtn = useRef()
    const wrapperRef = useRef(null);
    // console.log(containerWidth)

    const {
        getPatientList
    } = usePatientServices()

    const getPatientData = async () => {
        try {
            const data = {
                search: search
            }
            const response = await getPatientList(data)
            if (response?.success) {
                setPatientList(response?.data?.patients)
            }
            else {
                Swal.fire("", response?.message, "error")
            }
        } catch { }
    }

    const validatePhoneNumber = (value) => {
        if (value.length>9){
            const phoneRegex = /^[0-9]{10,13}$/; 
            return phoneRegex.test(value);
        }
        return false
        
    };

    const handleSearch = (e) => {
        console.log("handleSearch",e)
        const value = e.target.value;
        if (toggleValue) {
            toggleControl(false);
        }
        if (InputType === 'tel') {
            if (!validatePhoneNumber(value)) {
                setError('Please enter a valid phone number (10-13 digits).');
                setIsValid(false);
            } else {
                setError(null);
                setIsValid(true);
            }
        } else {
            setError(null);
            setIsValid(true);
        }
        if (defValue) {
            onChange(value);
        } else {
            try {
                onChange(value);
            } catch (error) {
                console.error("Error in onChange:", error);
            }
            setSearch(value);
        }
    }  

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setShowSearch(false);
                setFocused(false)
                // setSearch('')
            }
        };
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [wrapperRef]);

    useEffect(() => {
        if (search && (!mobileOnly || !isNaN(search))) {
            if (mobileOnly && search.length>=10 && search.length<=13) {
                setShowSearch(false);
            } else {
                setShowSearch(true);
                getPatientData();
                setContainerWidth(
                    searchBox.current.offsetWidth +
                    (icon ? searchBtn.current.offsetWidth : 0)
                );
                setMarginLeft(
                    icon ? searchBtn.current.offsetWidth / 2 : 'auto'
                );
            }
        } else {
            setPatientList('');
            setShowSearch(false);
        }
    }, [search]);
    

    useEffect(()=>{
        if(toggleValue){
            setSearch('')
            setShowSearch(false)
        }
    }, [toggleValue])

    useEffect(()=>{
        setShowSearch(false)
    },[])

    useEffect(()=>{
        if(defValue){
            setSearch(defValue)
        }
        else{
            setSearch('')
        }
    },[defValue])

    const searchOptionPopup = (
        <Popover
            className='patient-search-popup'
            style={
                {
                    "width":containerWidth,
                    "marginLeft":marginLeft
                }
            }
        >
            <div className='patient-search-content'>
                {patientList.length>0 ?
                    patientList.map((item, index) => {
                        const handleSelect = (e) => {
                            
                            console.log("Selected Patient:",e)
                            handleData(item)
                            // setPatientList('')
                            if(mobileOnly){
                                setSearch(item?.mobile)
                                setError(null);
                                setIsValid(true)
                            }
                            else{
                                setSearch('')
                            }
                            setShowSearch(false)
                            setFocused(false)
                        }
                        return (
                                    <div key={index} className='patient-search-options row mx-0'>
                                        <div className='col-8 basic-details'>
                                            <div className="id">
                                                {item.patient_id}
                                            </div>
                                            <div className="name">
                                                {item.full_name}
                                            </div>
                                        </div>
                                        <div className='col-4 mobile-number'>{item.mobile} 
                                            <button className='ms-3 btn rounded' onClick={handleSelect} style={{backgroundColor:"#E3B075"}} role='button' tabIndex={0}><img className='p-1' src={Arrow} alt="" /></button>
                                        </div>
                                    </div>
                        )
                    })
                    :
                    'No Data Found'
                }
            </div>
            {customButton&&
                <div>
                    <button 
                        onClick={()=>buttonClick(search)}
                        className="btn btn-dark w-100"
                    >
                        {buttonText?buttonText:'Add'}
                    </button>                
                </div>
            }
        </Popover>
    )
    const handleBlur=()=>{
        // setShowSearch(false)
    }
    const handleCancel = () => {
        setSearch('');
        if (handleReset) {
            handleReset()
        }
        setFocused(false)
    };
    return (
        <div className="patient-search-box" ref={wrapperRef}>
            <ButtonToolbar className='w-100'>
                <OverlayTrigger
                    show={showSearch}
                    trigger={['click']}
                    placement={"bottom"}
                    overlay={searchOptionPopup}
                >
                    <div 
                        ref={searchBox} 
                        className={`w-100 patient-search-container ${focused && isValid ? 'is-valid' : error ? 'is-invalid' : ''}`}
                    >
                        <span className='px-3'>
                            <GrSearch color='#A5A5A5' size='15px' />
                        </span>
                        <input
                            onFocus={() => setFocused(true)}
                            onBlur={()=>handleBlur()}
                            value={search}
                            placeholder={placeholder?placeholder:'Enter Patient ID or Mobile Number...'}
                            onChange={(e) => handleSearch(e)}
                            type= {InputType?InputType:'text'}
                        />
                        {search && (
                            <button onClick={handleCancel} className="cancel-button">
                                ✕
                            </button>
                        )}
                        
                    </div>
                </OverlayTrigger>
            </ButtonToolbar>
            {icon&&<span ref={searchBtn} onClick={() => { setShowSearch(!showSearch) }}>{icon}</span>}
        </div>
    )
}

export default PatientSearchBox
