import React, { useState } from 'react'
import DropDownBox from "../../../../../../shared/form-components/dropdown/CustomDropDown";
import TextAreaBox from "../../../../../../shared/form-components/form-textarea/TextAreaBox";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {FaRegSquarePlus} from "react-icons/fa6";
import { MdOutlineModeEdit } from "react-icons/md";
import { Modal } from 'react-bootstrap'
import ChooseItem from '../../subcontents/ChooseItem';
import "./LabTestTable.css"
import { IoDocumentTextOutline } from "react-icons/io5";
import { CgSoftwareUpload } from "react-icons/cg";
const LabTestTable = ({ value, setValue, functions,subfunctions,uploadData}) => {
    const handleSelectTestTab = (item,i) =>{
        setValue?.setSelectedLabTest(i);
        setValue?.setTestTabName(item.fk_test_name)
        setValue?.setTestTabType(item.fk_medical_test_type)
        setValue?.setTestTabInstruction(item.instruction)
        setValue?.setTestTabRemark(item.remark)
        setValue?.setSlnoLab(i+1)
        setValue?.setLabId(item.id)
    }
    

  const handleSetChooose = (item) =>{
    console.log("Item ID:", item?.id);
    console.log("setShowChoose function:", uploadData?.showChoose);
    uploadData?.setShowChoose(item?.id)
  }
  return (
    <div className="container labtest mx-0 ps-5  w-100">
    <table style={{ "text-align": "center" }}>
      <tr>
        <th>
          <div>Sl.No</div>
        </th>
        <th>
          <div>Test/Scan Name</div>
        </th>
        <th>
          <div>Type</div>
        </th>
        <th>
          <div>Instruction</div>
        </th>
        <th>
          <div>Remarks</div>
        </th>
        <th>
          <div></div>
        </th>
      </tr>
      <tr>
        <td>
          <div className="custom_input1 d-flex align-items-center justify-content-center">
            {value?.slnoLab}
          </div>
        </td>
        <td>
       
            <DropDownBox
              containerClass={"w-100 d-flex align-items-center"}
              addNew={true}
              fluid={true}
              search={true}
              selection={true}
              state={"medical_test_name"}
              setNew={functions?.addPatientProperty}
              options={value?.testTabNameList}
              setDataValue={setValue?.setTestTabName}
              selectedValue={value?.testTabName}
            />
        
        </td>
        <td>
        
         
            <DropDownBox
              containerClass={"d-flex align-items-center"}
              addNew={true}
              fluid={true}
              search={true}
              selection={true}
              state={"medical_test_type"}
              setNew={functions?.addPatientProperty}
              options={value?.testTabTypeList}
              setDataValue={setValue?.setTestTabType}
              selectedValue={value?.testTabType}
            />
         
        </td>
        <td>
         
             <TextAreaBox
              rows={"1"}
              containerClass={"d-flex align-items-center mb-0"}
              onChange={setValue?.setTestTabInstruction}
              defvalue={value?.testTabInstruction}
              removeMb={true}
            />
        
        </td>
       
        <td>
      
            <TextAreaBox
              rows={"1"}
              containerClass={"d-flex align-items-center mb-0"}
              onChange={setValue?.setTestTabRemark}
              defvalue={value?.testTabRemark}
              removeMb={true}
            />
         
        </td>
        <td>
            <div className='d-flex jusitify-content-center'>
                 <button
                    className="btn text-light px-2 me-1"
                    style={{ backgroundColor: "#E3B075" }}
                    onClick={(e)=>subfunctions?.handleSubmitTestTab(e)}
                    >
                    {value?.slnoLab ? <MdOutlineModeEdit  size={20}/> : <FaRegSquarePlus size={20}/>} 
                </button>

                {/* <button
                    className="btn text-light px-2 me-1"
                    style={{ backgroundColor: "#E3B075" }}
                    onClick={()=>setOpenTestAdd(true)}
                    >
                    <IoDocumentTextOutline  size={20}/> 
                </button> */}

                {/* <button
                    className="btn text-light px-2"
                    style={{ backgroundColor: "#1A212F" }}
                    onClick={()=>setOpenTestAdd(true)}
                    >
                    <CgSoftwareUpload  size={20}/> 
                </button> */}
                
            </div>
               
        </td>
      </tr>
      {value?.labTestListData?.length > 0 &&
        value?.labTestListData?.map((item, i) => (
          <tr>
            <td>
              <div className="custom_input d-flex align-items-center justify-content-center">
                {i + 1}
              </div>
            </td>
            <td>
              <div
                className="custom_input d-flex align-items-center justify-content-start mouse-pointer"
                style={
                  value?.selectedMedicine === i
                    ? { backgroundColor: "#e3b075" }
                    : {}
                }
                onClick={(e) => handleSelectTestTab(item, i)}
              >
                {item?.medical_test_name?.property_value}
              </div>
            </td>
            <td>
              <div className="custom_input d-flex align-items-center justify-content-start">
                {item?.medical_test_type?.property_value}
              </div>
            </td>
            <td>
              <div className="custom_input d-flex align-items-center justify-content-start">
                {item?.instruction}
              </div>
            </td>
            <td>
              <div className="custom_input d-flex align-items-center justify-content-start">
                {item?.remark}
              </div>
            </td>
            <td>
                <div className='d-flex jusitify-content-evenly'>
                <button
                  className="btn text-light px-2"
                  onClick={() => subfunctions?.handleLabTestDelete(item)}
                  style={{ backgroundColor: "#E3B075" }}
                >
                  <DeleteOutlineIcon size={18} />
                </button>

                <button
                    className="btn text-light px-2 me-1"
                    style={{ backgroundColor: "#EDF6FF" }}
                    // style={{ backgroundColor: "#EFEFEF" }}
                    // onClick={()=>setOpenTestAdd(true)}
                    >
                    <IoDocumentTextOutline  size={20}/> 
                </button>

                <button
                    className="btn text-light px-2"
                    style={{ backgroundColor: "#1A212F" }}
                    onClick={()=>handleSetChooose(item)}
                    >
                    <CgSoftwareUpload  size={20}/> 
                </button>
                </div>
            </td>
          </tr>
        ))}
    </table>
    <Modal
        show={uploadData?.showChoose}
        onHide={() => uploadData?.setShowChoose(false)}
        backdrop={true} keyboard={false} size="md"  centered
    >
        <Modal.Header closeButton style={{backgroundColor:"#e3b075",color:"#fafafa"}}>
            Test Documents
        </Modal.Header>
        <Modal.Body>
            <div>
                <ChooseItem
                    documents={uploadData?.uploads}
                    setDocuments={uploadData?.setUploads}
                    handleSubmit={subfunctions?.handleUploadDocument}
                />
            </div>
        </Modal.Body>
    </Modal>
  </div>
  )
}

export default LabTestTable