import React from 'react'

const AccomodationPopupDetails = ({patientData}) => {
  return (
    <div className='adm-popup-height'>
        <div className='row'>
            <div className='col-3'>
                <label className='label-text-content'>{patientData?.accommodation_type == "ward"?"Ward ":"Room "}ID</label>
                <input type="text" className="form-control p-2" value={patientData?.accommodation_type == "ward" ? patientData?.bed_detail?.fk_ward_details?.ward_id:patientData?.bed_detail?.fk_room_details?.room_id} disabled/>
            </div>
            <div className='col-3'>
                <label className='label-text-content'>{patientData?.accommodation_type == "ward"?"Ward ":"Room "}Name</label>
                <input type="text" className="form-control p-2" value={patientData?.accommodation_type == "ward" ? patientData?.bed_detail?.fk_ward_details?.ward_name:patientData?.bed_detail?.fk_room_details?.room_name} disabled/>
            </div>
            <div className='col-3'>
                <label className='label-text-content'>{patientData?.accommodation_type == "ward"?"Ward ":"Room "}Type</label>
                <input type="text" className="form-control p-2" value={patientData?.accommodation_type == "ward" ? patientData?.bed_detail?.fk_ward_details?.ward_type:patientData?.bed_detail?.fk_room_details?.room_type}disabled/>
            </div>
            <div className='col-3'>
                <label className='label-text-content'>Bed Number</label>
                <input type="text" className="form-control p-2" value={patientData?.accommodation_type == "ward" ? patientData?.bed_detail?.fk_ward_details?.id:patientData?.bed_detail?.fk_room_details?.id} disabled/>
            </div>
        </div>

        <div className='row'>
            <div className='col-6'>
            <div className='col-12 p-1'>
                <label className='label-text-content'>Rate Per Date</label>
                <input type="text" className="form-control p-3" value={patientData?.accommodation_type == "ward" ? patientData?.bed_detail?.fk_ward_details?.rate_per_day:patientData?.bed_detail?.fk_room_details?.rate_per_day} disabled/>
            </div>
            <div className='col-12 p-1'>
                    <label className='label-text-content'>Instructions</label>
                    <div className="col-12 p-3 rounded-3" style={{height:"15rem",overflowY:"scroll",backgroundColor:"#f4f4f4"}}>
                    { patientData?.symptoms?.map((data)=>{
                        return(<input type="text" className="form-control" placeholder="Fever" disabled value={data}/>)
                    })}
                    </div>
            </div>
            <div className="col-12 p-1">
                    <label className='label-text-content'>Other Details</label>
                    <textarea className="form-control" style={{ resize: 'none',backgroundColor:"#f4f4f4" }}  rows="10" placeholder="Remark" disabled>{patientData?.assessment_details?.remark}</textarea>
                    </div>
            </div>
            <div className='col-6'>
                    <label className='label-text-content'>Instructions</label>
                    <div className="col-12 p-3 rounded-3" style={{height:"36rem",overflowY:"scroll",backgroundColor:"#f4f4f4"}}>
                    { patientData?.symptoms?.map((data)=>{
                        return(<input type="text" className="form-control" placeholder="Fever" disabled value={data}/>)
                    })}
                    </div>
            </div>
        </div>
    </div>
  )
}

export default AccomodationPopupDetails