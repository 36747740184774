import React, { useState } from 'react'
import InputTextBox from '../../../../../shared/form-components/form-text/InputTextBox'
import { Popover } from 'react-bootstrap'
import Calendar from 'react-calendar'
import moment from 'moment'
import InputBtnGroup from '../../../../../shared/form-components/form-text/InputBtnGroup'
import calender_icon from '../../../../../../assets/pictures/icons/calender-icon.png'
import DropDownBox from '../../../../../shared/form-components/dropdown/CustomDropDown'

const EducationForm = ({value,setValue,functions,educationSubmit,educationIndex}) => {
    const [showPassDateCalendar, setShowPassDateCalendar] = useState(false)
    const handlePassDateCalendar = (e) => {
        setValue?.setPassDate(e)
        setShowPassDateCalendar(false)
    }
    const passDateCalendar = (
        <Popover
            className='calendar-popup-container'
        >
            <Calendar onChange={handlePassDateCalendar} />
        </Popover>
    )
    const handleSubmit = (e) => {
        e.preventDefault();
        educationSubmit()
    };
    return (
        <div className="container-fluid mt-4 leave-form1 px-0">
            <form onSubmit={handleSubmit}>
                <div className="row">

                    <div className="col-2">
                           <DropDownBox 
                            label={'Course'}
                            containerClass={'col-12'}
                            addNew={true}
                            fluid={true}
                            search={true}
                            selection={true}
                            state={'course'}
                            setNew={functions?.addPatientProperty}
                            options={value?.courseList}
                            setDataValue={setValue?.setCourse}
                            selectedValue={value?.course}
                            />
                    </div>

                    <div className="col-2">
                        <InputBtnGroup
                            label={'Passed Date'}
                            required={true}
                            icon={
                                <img
                                    src={calender_icon}
                                    alt=""
                                    className='search-icon-img'
                                />
                            }
                            containerClass={'col-12 mb-3'}
                            defvalue={value?.passDate && moment(new Date(value?.passDate)).format("DD - MM - YYYY")}
                            setTogglePopup={setShowPassDateCalendar}
                            togglePopup={showPassDateCalendar}
                            popupContent={passDateCalendar}
                            popupPlacement={"bottom"}
                        />
                    </div>

                    <div className="col-3 col-4">
                        <InputTextBox
                            label={'Institute'}
                            containerClass={'col-12 mb-3'}
                            onChange={setValue?.setInstitution}
                            defvalue={value?.institution}
                            // disabled={true}
                            // required={true}
                        />
                    </div>

                    <div className="col-3 col-4">
                    <InputTextBox
                            label={'Mark/Grade'}
                            containerClass={'col-12 mb-3'}
                            onChange={setValue?.setMark}
                            defvalue={value?.mark}
                            // disabled={true}
                            // required={true}
                        />
                    </div>

                    <div className="col-1 d-flex align-items-start justify-content-center submit-btn">
                        <button type="submit" className="btn custom-btn mt-4">
                            {/* {editID?'Edit':'ADD'} */}
                            {educationIndex !== null ? 'Edit' : 'Add'}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default EducationForm