import React, { useEffect, useState } from 'react';
import './LeaveForm.css';
import cal from '../../../../assets/pictures/icons/uim_calender.png'
import Swal from 'sweetalert2';
const LeaveForm = (params) => {
 const {
  postLeaveRequest,
  physician,
  phyData,
  leaveReason,
  toDate,
  fromDate,
  days,
  isDisabled,
  editID,
  setPhysician,
  setLeaveReason,
  setToDate,
  setFromDate,
  setDays,
  setIsDisabled,
  setEditID,
  getallLeaveRequests,
  putLeaveRequest,
  handleReset
 } =params;

  const handleSubmit = async(e) => {
    e.preventDefault()
    const data = {
      fk_employee:physician,
      from_date:fromDate,
      to_date:toDate==""?null:toDate,
      no_days:days,
      remark:leaveReason}
    try{
      if (editID) {
        console.log(editID)
        const response = await putLeaveRequest(editID,data);
        if (response.success){
          handleReset()
          getallLeaveRequests();
          Swal.fire(
            "Leave Request Updated",'',"success"
          )
        }
      }else{
        const response = await postLeaveRequest(data);
        if (response.success){
          handleReset()
          getallLeaveRequests();
          Swal.fire(
            "Leave Request Submitted",'',"success"
          )
        }
      }
      
    }catch(e){
      console.log(e)
    }
  };

  const handleFromDate = (e) =>{
    setFromDate(e.target.value);
    setIsDisabled(false)
    if (!toDate) {
    setDays(1)
    } else {
      handleNoDays()
    }
  }
  const handleNoDays = () =>{
    if (fromDate && toDate){
      const startDate = new Date(fromDate);
      const endDate = new Date(toDate);
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(0, 0, 0, 0);
      const timeDiff = endDate - startDate;
      const daysDiff = timeDiff / (1000 * 3600 * 24);
      setDays(daysDiff+1);
    }
  }

  useEffect(()=>{
    handleNoDays()
  },[fromDate,toDate])

  return (
<div className="container-fluid mt-4 leave-form px-0">
      <form onSubmit={handleSubmit}>
        <div className="row g-3">

          <div className="col-12 col-sm-2">
            <label className="form-label label-text-content">Select Physician <span className='text-danger'>*</span></label>
            <select
              className="form-select"
              value={physician}
              onChange={(e) => setPhysician(parseInt(e.target.value))}
              required
            >
              <option value={0}>select</option>
              {phyData.map((d, i) => (
                <option key={i} value={d.docID}>
                  {d.docName.toUpperCase()}
                </option>
              ))}
            </select>
          </div>

          <div className="col-12 col-sm-2">
            <label className="form-label label-text-content">Number of Days</label>
            <input
              type="number"
              className="form-control"
              value={days}
              readOnly
            />
          </div>

          <div className="col-12 col-sm-2">
            <label className="form-label label-text-content">From Date <span className='text-danger'>*</span></label>
            <input
              type="date"
              className="form-control"
              value={fromDate}
              onChange={(e) =>handleFromDate(e)}
              required
            />
          </div>


          <div className="col-12 col-sm-2">
            <label className="form-label label-text-content">To Date</label>
            <input
              type="date"
              className="form-control"
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
              disabled={isDisabled}
            />
          </div>

          <div className="col-12 col-sm-2">
            <label className="form-label label-text-content">Leave Reason <span className='text-danger'>*</span></label>
            <input
              type="text"
              className="form-control"
              value={leaveReason}
              onChange={(e) => setLeaveReason(e.target.value)}
              required
            />
          </div>


          <div className="col-12 col-sm-2 d-flex align-items-start justify-content-center submit-btn">
            <button type="submit" className="btn custom-btn mt-4">
              {editID?'Edit':'ADD'}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default LeaveForm;
