import React from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useAxios from "../../hooks/useAxios";

const usePatientServices = () => {
  const axiosPrivate = useAxiosPrivate();
  const axios = useAxios();

  //GET REQUESTS
  //get patient info choices
  const getPatientInfoChoices = async () => {
    const response = await axiosPrivate.get("patient/patient_info_choices/");
    return response.data;
  };

  //get temporary patient details
  const getTempPatientDetails = async (id) => {
    const response = await axiosPrivate.get(
      `consultation/temperory_patient/${id}/`
    );
    return response.data;
  };

  const getSinglePatient = async (data) => {
    const response = await axiosPrivate.get(`patient/patient/details/${data}`);
    return response.data;
  };

  const getpatientPatientIpOp = async (id) => {
    const response = await axiosPrivate.get(`patient/patient/ip/op/count/${id}/`);
    return response.data;
  };

  const getSortFilterPagination = async (data) => {
    const response = await axiosPrivate.get(`patient/patients/`, {
      params: { ...data },
    });
    return response.data;
  };

  // get patient document categories

  const getPatientDocumentCategories = async () => {
    const response = await axiosPrivate.get(
      "patient/patient_document_category/"
    );
    return response.data;
  };

  // get patient medical issues

  const getPatientMedicalIssues = async (id, params) => {
    const response = await axiosPrivate.get(
      "patient/patient_medical_issues/" + id + "/",
      {
        params: { ...params },
      }
    );
    return response.data;
  };

  // get patient treatment history

  const getPatientTreatmentHistory = async (id) => {
    const response = await axiosPrivate.get(
      "patient/patient_treatment_history/" + id + "/"
    );
    return response.data;
  };

  // get patient allergy issues

  const getPatientAllergicIssues = async (id) => {
    const response = await axiosPrivate.get(
      "patient/patient_allergic_issues/" + id + "/"
    );
    return response.data;
  };

  // get patient medical documents

  const getPatientMedicalDocuments = async (id) => {
    const response = await axiosPrivate.get(
      "patient/patient_document_record/" + id + "/"
    );
    return response.data;
  };

  //get patient List

  const getPatientList = async (data) => {
    const response = await axiosPrivate.get("patient/patients/", {
      params: { ...data },
    });
    return response.data;
  };

  //get new patient id

  const getNewPatientId = async () => {
    const response = await axiosPrivate.get("patient/patient_id/");
    return response.data;
  };

  const getProperty = async (data) => {
    const response = await axiosPrivate.get("patient/property_type/",{params:{...data},});
    return response.data;
  };

  //POST REQUESTS

  //post patient basic info

  const createPatientBasicsRegister = async (data) => {
    const response = await axiosPrivate.post("patient/patients/", data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data;
  };

  //post patient passport and visa documents

  const createPassportVisaDocuments = async (id, data) => {
    const response = await axiosPrivate.post(
      `patient/patient_id_upload/${id}/`,
      data,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
    return response.data;
  };

  //post patient medical issues

  const postPatientMedicalIssues = async (id, data) => {
    const response = await axiosPrivate.post(
      "patient/patient_medical_issues/" + id + "/",
      data
    );
    return response.data;
  };

  //post patient treatment history

  const postPatientTreatmentHistory = async (id, data) => {
    const response = await axiosPrivate.post(
      "patient/patient_treatment_history/" + id + "/",
      data
    );
    return response.data;
  };

  //post patient allergic issues

  const postPatientAllergicIssues = async (id, data) => {
    const response = await axiosPrivate.post(
      "patient/patient_allergic_issues/" + id + "/",
      data
    );
    return response.data;
  };

  // post patient document categories

  const postPatientDocumentCategories = async (data) => {
    const response = await axiosPrivate.post(
      "patient/patient_document_category/",
      data
    );
    return response.data;
  };

  //post patient medical documents

  const postPatientMedicalDocuments = async (id, data) => {
    const response = await axiosPrivate.post(
      "patient/patient_document_record/" + id + "/",
      data,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
    return response.data;
  };

  //post patient health record

  const postPatientHealthRecord = async (id, data) => {
    const response = await axiosPrivate.post(
      "patient/patient_health_record/" + id + "/",
      data,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
    return response.data;
  };

  //post aptient card content template

  const postPatientCardTemplate = async (data) => {
    const response = await axiosPrivate.post(
      "patient/card_template/create/",
      data
    );
    return response.data;
  };

  const postProperty = async (data) => {
    const response = await axiosPrivate.post("patient/property_type/",{ property_value: data.property_value },{ params: { property_type: data.property_type } });
    return response.data;
  };

  // PUT REQUESTS
  const putPatientDocument= async(patient_id, doc_id, data)=>{
    const response = await axiosPrivate.put(`patient/patient_document_record/${patient_id}/${doc_id}/update/delete/`,data,{
      headers: { "Content-Type": "multipart/form-data" }
    })
    return response.data
  }
  //DELETE REQUESTS

  // delete patient medical issues
  const deletePatientDocument = async(patient_id, doc_id)=>{
    const response = await axiosPrivate.delete(`patient/patient_document_record/${patient_id}/${doc_id}/update/delete/`)
    return response.data
  }

  const deletePatientMedicalIssue = async (patient_id, issue_id) => {
    const response = await axiosPrivate.delete(
      `patient/patient_medical_issues/${patient_id}/${issue_id}/delete/`
    );
    return response.data;
  };

  // delete patient treatment history

  const deletePatientTreatmentHistory = async (patient_id, treatment_id) => {
    const response = await axiosPrivate.delete(
      `patient/patient_treatment_history/${patient_id}/${treatment_id}/delete/`
    );
    return response.data;
  };

  // delete patient allergic issues

  const deletePatientAllergicIssues = async (patient_id, issue_id) => {
    const response = await axiosPrivate.delete(
      `patient/patient_allergic_issues/${patient_id}/${issue_id}/delete/`
    );
    return response.data;
  };

  const deletePatientDetail = async (patient_id, password) => {
    const response = await axiosPrivate.delete(`patient/patient/delete/${patient_id}/`,{
      data: { password: password }
  });
    return response.data;
}

  return {
    //GET services
    getPatientInfoChoices,
    getTempPatientDetails,
    getPatientDocumentCategories,
    getPatientMedicalIssues,
    getPatientTreatmentHistory,
    getPatientAllergicIssues,
    getPatientMedicalDocuments,
    getPatientList,
    getNewPatientId,
    getSinglePatient,
    getSortFilterPagination,
    getpatientPatientIpOp,
    getProperty,

    //POST services
    createPatientBasicsRegister,
    createPassportVisaDocuments,
    postPatientMedicalIssues,
    postPatientTreatmentHistory,
    postPatientAllergicIssues,
    postPatientDocumentCategories,
    postPatientMedicalDocuments,
    postPatientHealthRecord,
    postPatientCardTemplate,
    postProperty,
    // PUT services
    putPatientDocument,
    // DELETE services
    deletePatientMedicalIssue,
    deletePatientTreatmentHistory,
    deletePatientAllergicIssues,
    deletePatientDocument,
    deletePatientDetail
  }
}

export default usePatientServices;
