import React, { useEffect, useState } from "react";
import {Tab, TabPane} from "react-bootstrap";
import "./Ipadmissions.css";
import Swal from "sweetalert2";
import moment from "moment";
import HashLoader from "react-spinners/ClockLoader";
import { BsThreeDotsVertical, BsInfoCircle } from "react-icons/bs";
import { GoPlusCircle } from "react-icons/go";
import DisChargeBasicDetails from "./DischargeProcess/DisChargeBasicDetails";
import useInpatientServices from "../../services/api-services/inpatientServices";


const DischargeList = (props) => {
	const {
		IPAdmissionList, loading,
		status, isMd,
		setStatus,admPopup,setAdmPopup,setPopPage,handleGetIpDetails
	} = props;
	const [currentDStep, setCurrentDStep] = useState(1);
	const [patientData, setPatientData] = useState([]);
	const handleNext = () => {
		setCurrentDStep(currentDStep + 1);
	};
	
	const handleBack = () => {
		setCurrentDStep(currentDStep - 1);
	};
	const {getIpPatientDetails} = useInpatientServices();
	const handleStartAdmissionProcess = async(id) => {
		try{
		  const response = await getIpPatientDetails(id)
		  if (response?.success){
			setPatientData(response?.data)
			Swal.fire({
			  icon: 'success',          
			  title: response.message,  
			  toast: true,
			  position: 'top-end',
			  showConfirmButton: false, 
			  timer: 2000,
			  timerProgressBar: true, 
			  customClass: {
				popup: 'colored-toast'
			  },
			  background: '#f0f9eb', 
			  showClass: { 
				popup: 'slide-in-right'
			  },
			  hideClass: { 
				popup: 'slide-out-left'
			  },
			});
			setCurrentDStep(2);
		  }else{
			Swal.fire("Error",response.message,"error")
		  }
	
		}catch(e){
	
		}
		 
	  };

	useEffect(() => {
		setStatus(status)
	}, [
		// pagination.page,
		// sortBy,
		// search,
		// filtered,
		// selectedDate,
		// fromDate,
		// toDate,
		// filterType,
	]);

	const handlePopup = (id) =>{
		setAdmPopup(!admPopup)
		setPopPage('discharge')
		handleGetIpDetails(id)
	  }

	return (
		<>
			{currentDStep === 1 && (<Tab.Container activeKey={"tab1"}>
				<div className="patient-list-container">
					<Tab.Content className="patient-table-container container-white">
						<TabPane eventKey="tab1" className="">
							<div className="table-responsive">
								<table className="table patient-list-table px-2 ">
									<thead className="patient-list-table-head">
										<tr>
											<th style={{ width: 0 }}>Sl.no</th>
											<th style={{ width: 0 }}>Patient ID</th>
											<th style={{ width: 0 }}>IP Number</th>
											<th style={{ width: 0 }}>Patient Name</th>
											<th style={{ width: 0 }}>Incharge Physician</th>
											<th style={{ width: 0 }}>Admitted Date</th>
                                            <th style={{ width: 0 }}>Discharge Date</th>
											<th style={{ width: 0 }}>Status</th>
											{/* <th style={{ width: 0 }}></th> */}
											{isMd && (
												<th className="text-center" style={{ width: 0 }}>
													Action
												</th>
											)}
										</tr>
									</thead>

									{loading ? (
										<tbody>
											<tr>
												<td colSpan="7">
													<div className="d-flex justify-content-center align-items-center loader-container">
														<HashLoader color="#e3b075" size={100} />
													</div>
												</td>
											</tr>
										</tbody>
									) : (
										<>
											<tbody className="patient-list-table-body">
												{IPAdmissionList &&
													IPAdmissionList.map((item, index) => {
														return (
															<tr key={index} className="position-relative">
																<td>
																	<label className="appointment-list-text">
																		{index + 1}
																	</label>
																</td>
																<td>
																	<label className="appointment-list-text patient-id-color">
																		{item?.patient_info?.patient_id}
																	</label>
																</td>

																<td>
																	<label className="appointment-list-text patient-id-color">
																		{item?.ip_number}
																	</label>
																</td>

																{/* <td onClick={() => handleShowAppointment(item)}> */}
																<td>
																	<label
																		className="appointment-list-text text-uppercase text-wrap"
																		role="button"
																	>
																		{item?.patient_info?.full_name}
																	</label>
																</td>
																{/* <td>
																	<label className="appointment-list-text">
																		{
																			`${
																				item?.patient_accommodation_details?.accommodation_type == 'ward' ? 
																				item?.patient_accommodation_details?.bed_detail?.fk_ward_details?.ward_name+'/'+item?.patient_accommodation_details?.bed_detail?.bed_no 
																				: item?.patient_accommodation_details?.accommodation_type == 'room' ? 
																				item?.patient_accommodation_details?.private_room_detail?.room_name+'('+item?.patient_accommodation_details?.private_room_detail?.room_number+')'
																				: 'Not Available'
																			}`
																		}
																	</label>
																</td> */}
																<td>
																	<label className="appointment-list-text">
																		{item?.physician_info?.first_name}
																	</label>
																</td>
																<td >
																	<label className="appointment-list-text">
																		{item?.admitted_date &&
																			moment(new Date(item?.admitted_date)).format(
																			"DD/MM/YYYY"
																			)}
																	</label>
																</td>
                                                                <td >
																	<label className="appointment-list-text">
																		{item?.admitted_date &&
																			moment(new Date(item?.discharge_date)).format(
																			"DD/MM/YYYY"
																			)}
																	</label>
																</td>
																<td>
																		{
																			item?.admission_status == 'pending' ?
																			<span className='admitted-text text-primary'>Pending</span>:item?.admission_status == 'discharged' ?
																			<span className='admitted-text'>Discharged</span> : <span className='admitted-text'>----</span>
																		}
																</td>
																{/* <td>
																	
																</td> */}
																<td>
																	<div className='d-flex justify-content-center align-items-center'>
                                                                    <div className='btn' onClick={()=>handlePopup(item?.id)} ><BsInfoCircle className='fs-4'/> </div>
																		{/* <div className='btn'><BsThreeDotsVertical className='fs-3'/></div> */}
																		{
																			item?.admission_status == 'pending' ?
																			<button className='admit-btn' onClick={()=>handleStartAdmissionProcess(item.id)}>Discharge</button> :item?.admission_status == 'discharged' ?
																			<span className='admitted-text'>Discharged</span> : <span className='admitted-text'>----</span>
																		}
																	</div>
																</td>
																{/* {activeDE && (
                                <div className="d-flex gap-3 position-absolute" style={{right:"60px"}}><span
                                className="appointment-edit-btn"
                                // onClick={() => handleAppointmentEdit(item)}
                              >
                                <MdOutlineModeEditOutline size={18} />
                              </span>
                              <span
                                  className="appointment-del-btn"
                                  // onClick={() =>
                                  //   handleDeleteWithConfirmation(item)
                                  // }
                                >
                                  <DeleteOutlineIcon size={18} />
                                </span>
                              </div>
                              )} */}
															</tr>
														);
													})}
											</tbody>
										</>
									)}

								</table>
							</div>
						</TabPane>
					</Tab.Content>
				</div>
			</Tab.Container>
		)}

	{currentDStep === 2 && (
        <DisChargeBasicDetails 
        handleNext={handleNext} 
        handleBack={handleBack} 
        patientDetails={patientData?.patient_basic_info}
        />
      )}


		</>
	);
};

export default DischargeList;