// import React, { useState } from 'react'
// import './TreatmentTables.css'
// import MultiInputBox from '../../../../../../shared/form-components/multi-input/MultiInputBox'
// const TreatmentDetailsTransfer = ({value,lookChange}) => {
//   const [recommended, setRecommented] = useState([]);
//   const [restricted, setRestricted] = useState([]);
//   const [lifestyleList, setLifestyleList] = useState([]);
//   const [instructionList, setInstructionList] = useState([]);

//   const [activeArea,setActiveArea] = useState('med');
//   const handleActiveArea =(d)=>{
//     setActiveArea(d);
//   }
//   return (
//     <div
//         className={`card card-container ${
//         lookChange ? "lookChange m-0" : ""
//         } bg-light`}
//     >
//     <div className="row row-container p-3 py-5 pt-0">

//         <div className='col-12 d-flex justify-content-start mb-4 position-sticky bg-light p-5 ps-3 pb-0' style={{top:"0",zIndex:"2"}}>
//           <div className='col-1 col-2 p-3 pt-0 fw-bold fs-6' onClick={()=>handleActiveArea('med')}>Medicine
//           <span 
//           className={`rounded w-100 ${activeArea === "med" ? 'divunderline' : ''}`}>
//           </span> 
//           </div>
//           <div className='col-1 col-2 p-3 pt-0 fw-bold fs-6' onClick={()=>handleActiveArea('the')}>Therapies
//           <span 
//           className={`rounded w-100 ${activeArea === "the" ? 'divunderline' : ''}`}>
//           </span> 
//           </div>
//           <div className='col-3 col-4 p-3 pt-0 fw-bold fs-6' onClick={()=>handleActiveArea('diet')}>Diet plan & Life style modifications 
//           <span 
//           className={`rounded w-100 ${activeArea === "diet" ? 'divunderline' : ''}`}>
//           </span> 
//           </div>
//         </div>
//         {activeArea==="med" && (<div className='col-12'>
//           <h4 className='ps-4' style={{color:"#E3B075"}}>Medicine</h4>
//           <div className="container medicine mx-0 w-100">
//           <table style={{ "text-align": "center" }}>
//           <tr>
//             <th>
//               <div>Sl.No</div>
//             </th>
//             <th>
//               <div>Medicine Name</div>
//             </th>
//             <th>
//               <div>Strength/Unit</div>
//             </th>
//             <th>
//               <div>Dosage (Form)</div>
//             </th>
//             <th>
//               <div>Dose (Metric Measure)</div>
//             </th>
//             <th>
//               <div>Frequancy & Timing</div>
//             </th>
//             <th>
//               <div>Total Quantity</div>
//             </th>
//             <th>
//               <div>Duration</div>
//             </th>
//             <th>
//               <div>Remark</div>
//             </th>
//           </tr>
//           {value?.medicineListData?.length > 0 &&
//             value?.medicineListData?.map((item, i) => (
//               <tr>
//                 <td>
//                   <div className="input-text-control px-3 w-100 custom_input d-flex align-items-center justify-content-center">
//                     {i + 1}
//                   </div>
//                 </td>
//                 <td>
//                   <div className="input-text-control px-3 w-100 custom_input d-flex align-items-center justify-content-start">
//                     {item?.medicine_name?.property_value}
//                   </div>
//                 </td>
//                 <td>
//                   <div className="input-text-control px-3 w-100 custom_input d-flex align-items-center justify-content-start">
//                     {item?.strength?.property_value}
//                   </div>
//                 </td>
//                 <td>
//                   <div className="input-text-control px-3 w-100 custom_input d-flex align-items-center justify-content-start">
//                     {item?.dosage?.property_value}
//                   </div>
//                 </td>
//                 <td>
//                   <div className="input-text-control px-3 w-100 custom_input d-flex align-items-center justify-content-start">
//                     {item?.dose?.property_value}
//                   </div>
//                 </td>
//                 <td>
//                   <div className="input-text-control px-3 w-100 custom_input d-flex align-items-center justify-content-start">
//                     {item?.frequency?.property_value}
//                   </div>
//                 </td>
//                 <td>
//                   <div className="input-text-control px-3 w-100 custom_input d-flex align-items-center justify-content-start">
//                     {item?.total_quantity?.property_value}
//                   </div>
//                 </td>
//                 <td>
//                   <div className="input-text-control px-3 w-100 custom_input d-flex align-items-center justify-content-start">
//                     {item?.duration?.property_value}
//                   </div>
//                 </td>
//                 <td>
//                   <div className="input-text-control px-3 w-100 custom_input d-flex align-items-center justify-content-start">
//                     {item?.remark}
//                   </div>
//                 </td>
//               </tr>
//             ))}
//         </table>
//           </div>
//         </div>)}
//         {activeArea==="the" && (<div className='col-12'>
//           <h4 className='ps-4' style={{color:"#E3B075"}}>Therapies</h4>
//           <div  className="container treatment mx-0 w-100">
//             <table style={{ "text-align": "center" }}>
//               <tr>
//                 <th>
//                   <div>Sl.No</div>
//                 </th>
//                 <th>
//                   <div>Name of Therapy</div>
//                 </th>
//                 <th>
//                   <div>Duration</div>
//                 </th>
//                 <th>
//                   <div>Frequency</div>
//                 </th>
//                 <th>
//                   <div>Specific Instruction</div>
//                 </th>
//                 <th>
//                   <div>Total Session</div>
//                 </th>
//               </tr>
//               {value?.treatmentListData?.length > 0 &&
//                 value?.treatmentListData?.map((item, i) => (
//                   <tr>
//                     <td>
//                       <div className="custom_input d-flex align-items-center justify-content-center">
//                         {i + 1}
//                       </div>
//                     </td>
//                     <td>
//                       <div className="custom_input d-flex align-items-center justify-content-start">
//                         {item?.name_of_therapy?.property_value}
//                       </div>
//                     </td>
//                     <td>
//                       <div className="custom_input d-flex align-items-center justify-content-start">
//                         {item?.therapy_duration?.property_value}
//                       </div>
//                     </td>
//                     <td>
//                       <div className="custom_input d-flex align-items-center justify-content-start">
//                         {item?.therapy_frequency?.property_value}
//                       </div>
//                     </td>
//                     <td>
//                       <div className="custom_input d-flex align-items-center justify-content-start">
//                         {item?.specific_instruction}
//                       </div>
//                     </td>
//                     <td>
//                       <div className="custom_input d-flex align-items-center justify-content-start">
//                         {item?.therapy_total_session?.property_value}
//                       </div>
//                     </td>
//                   </tr>
//                 ))}
//             </table>
//           </div>

//         </div>)}
//         {activeArea==="diet" && (<div className='col-12'>
//         <h4 className='ps-4' style={{color:"#E3B075"}}>Diet Plan & Life Style Modification</h4>
//         <h5 className='ps-4'>Diet Plan</h5>
//         <div className='col-12 p-4 pt-2 d-flex justify-content-between align-items-center'>
//                 <div className='col-6 p-2'>
//                 <MultiInputBox
//                   label={'Recommended'}
//                   data={recommended}
//                   // onAdd={functions?.addPrevMedIssues}
//                   // onRemove={functions?.deleteMedIssue}
//                   containerClass={'col-12 mb-3'}
//                   // icon={'+'}
//                   sizePad={true}
//                   disabled={true}
//                 />
//                 </div>
//                 <div className='col-6 p-2'>
//                 <MultiInputBox
//                   label={'Restricted'}
//                   data={restricted}
//                   // onAdd={functions?.addPrevMedIssues}
//                   // onRemove={functions?.deleteMedIssue}
//                   containerClass={'col-12 mb-3'}
//                   // icon={'+'}
//                   sizePad={true}
//                   disabled={true}
//                 />
//                 </div>
//         </div>
//         <div className='col-12  p-4 pt-2'>
//               <label className='label-text-content ps-1 pb-2'>Instructions</label>
//               <div className='col-12 p-1'>
//                 <div className='col-12 p-4 rounded-3' style={{backgroundColor:"#eeeeee"}}>
//                 <ul>
//                   {instructionList.map((item, index) => (
//                     <li key={index}>{item}</li>
//                   ))}
//                 </ul>
//                 </div>
//               </div>
              
//         </div>
         
//           <h5 className='ps-4'>Life Style Modification</h5>
//           <div className='col-12  p-4 pt-2'>
//               <div className='col-12 p-1'>
//                 <div className='col-12 p-4 rounded-3' style={{backgroundColor:"#eeeeee"}}>
//                 <ul>
//                   {lifestyleList.map((item, index) => (
//                     <li key={index}>{item}</li>
//                   ))}
//                 </ul>
//                 </div>
//               </div>
//           </div>
//         </div>)}
//     </div>
//     </div>
//   )
// }

// export default TreatmentDetailsTransfer

import React, { useState, useRef, useEffect } from 'react';
import './TreatmentTables.css';
import MultiInputBox from '../../../../../../shared/form-components/multi-input/MultiInputBox';

const TreatmentDetailsTransfer = ({ value, lookChange }) => {
  const [activeArea, setActiveArea] = useState('med');

  // Refs for each section
  const medRef = useRef(null);
  const theRef = useRef(null);
  const dietRef = useRef(null);

  // Function to handle changing active area
  const handleActiveArea = (area) => {
    setActiveArea(area);
  };

  // Scroll to the selected section when activeArea changes
  useEffect(() => {
    if (activeArea === 'med') {
      medRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else if (activeArea === 'the') {
      theRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else if (activeArea === 'diet') {
      dietRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [activeArea]);

  return (
    <div className={`card card-container ${lookChange ? "lookChange m-0" : ""} bg-light`} style={{border:'0',backgroundColor:'fafafa'}}>
      <div className="row row-container p-3 py-5 pt-0">
        {/* Tabs for selecting the focus area */}
        <div className='col-12 d-flex justify-content-start mb-4 position-sticky bg-light p-5 ps-3 pb-0' style={{ top: "0", zIndex: "2" }}>
          <div className={`col-1 col-2 p-3 pt-0 pb-0 fw-bold fs-6 ${activeArea === 'med' ? 'treatmentbottomborder' : ''}`} onClick={() => handleActiveArea('med')}>
            Medicine
          </div>
          <div className={`col-1 col-2 p-3 pt-0 pb-0 fw-bold fs-6 ${activeArea === 'the' ? 'treatmentbottomborder' : ''}`} onClick={() => handleActiveArea('the')}>
            Therapies
          </div>
          <div className={`col-3 col-4 p-3 pt-0 pb-0 fw-bold fs-6 ${activeArea === 'diet' ? 'treatmentbottomborder' : ''}`} onClick={() => handleActiveArea('diet')}>
            Diet plan & Life style modifications
          </div>
        </div>
        <div className='container'>
          <div ref={medRef} className='col-12'>
            <h4 className='ps-4' style={{ color: "#E3B075" }}>Medicine</h4>
            <div className="container medicine mx-0 w-100">
              <table style={{ "text-align": "center" }}>
                <tr>
                  <th>Sl.No</th>
                  <th>Medicine Name</th>
                  <th>Strength/Unit</th>
                  <th>Dosage (Form)</th>
                  <th>Dose (Metric Measure)</th>
                  <th>Frequency & Timing</th>
                  <th>Total Quantity</th>
                  <th>Duration</th>
                  <th>Remark</th>
                </tr>
                {/* Medicine data rendering */}
                {value?.medicineListData?.map((item, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{item?.medicine_name?.property_value}</td>
                    <td>{item?.strength?.property_value}</td>
                    <td>{item?.dosage?.property_value}</td>
                    <td>{item?.dose?.property_value}</td>
                    <td>{item?.frequency?.property_value}</td>
                    <td>{item?.total_quantity?.property_value}</td>
                    <td>{item?.duration?.property_value}</td>
                    <td>{item?.remark}</td>
                  </tr>
                ))}
              </table>
            </div>
          </div>

          <div ref={theRef} className='col-12'>
            <h4 className='ps-4' style={{ color: "#E3B075" }}>Therapies</h4>
            <div className="container treatment mx-0 w-100">
              <table style={{ "text-align": "center" }}>
                <tr>
                  <th>Sl.No</th>
                  <th>Name of Therapy</th>
                  <th>Duration</th>
                  <th>Frequency</th>
                  <th>Specific Instruction</th>
                  <th>Total Session</th>
                </tr>
                {/* Therapies data rendering */}
                {value?.treatmentListData?.map((item, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{item?.name_of_therapy?.property_value}</td>
                    <td>{item?.therapy_duration?.property_value}</td>
                    <td>{item?.therapy_frequency?.property_value}</td>
                    <td>{item?.specific_instruction}</td>
                    <td>{item?.therapy_total_session?.property_value}</td>
                  </tr>
                ))}
              </table>
            </div>
          </div>

          <div ref={dietRef} className='col-12'>
            <h4 className='ps-4' style={{ color: "#E3B075" }}>Diet Plan & Life Style Modification</h4>
            <h5 className='ps-4'>Diet Plan</h5>
            <div className='col-12 p-4 pt-2 d-flex justify-content-between align-items-center'>
              <div className='col-6 p-2'>
                <MultiInputBox
                  label={'Recommended'}
                  data={[]}
                  containerClass={'col-12 mb-3'}
                  sizePad={true}
                  disabled={true}
                />
              </div>
              <div className='col-6 p-2'>
                <MultiInputBox
                  label={'Restricted'}
                  data={[]}
                  containerClass={'col-12 mb-3'}
                  sizePad={true}
                  disabled={true}
                />
              </div>
            </div>
            <div className='col-12 p-4 pt-2'>
              <label className='label-text-content ps-1 pb-2'>Instructions</label>
              <div className='col-12 p-1'>
                <div className='col-12 p-4 rounded-3' style={{ backgroundColor: "#eeeeee" }}>
                  <ul>
                    {[] /* Render instructionList here */}
                  </ul>
                </div>
              </div>
            </div>
            <h5 className='ps-4'>Life Style Modification</h5>
            <div className='col-12 p-4 pt-2'>
              <div className='col-12 p-1'>
                <div className='col-12 p-4 rounded-3' style={{ backgroundColor: "#eeeeee" }}>
                  <ul>
                    {[] /* Render lifestyleList here */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TreatmentDetailsTransfer;
