import React from 'react'
import { Tab, TabPane } from 'react-bootstrap';
import { MdOutlineModeEditOutline } from "react-icons/md";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { BsThreeDotsVertical } from "react-icons/bs";

const EducationList = ({value,allEducationList,handleEdit,handleDelete}) => {
  // const findCourseName = (cid) => {
  //   // return value?.courseList.find(course => course.value === cid).text;
  // };
  const getCourseText = (value, id) => {
    const course = (value?.courseList || [])
      .filter(course => course.value === id)
      .map(course => course.text);
      
    return course.length ? course[0] : undefined;
  };
  return (
    <div>
    <Tab.Container activeKey={"tab1"}>
      <div className="patient-list-container p-0">
        <Tab.Content className="patient-table-container container-white">
          <TabPane eventKey="tab1" className="">
            <div className="table-responsive">
              <table className="table patient-list-table">
                <thead className="patient-list-table-head">
                  <tr>
                    <th>No</th>
                    <th>Course</th>
                    <th>Passed Date</th>
                    <th>Institute</th>
                    <th>Mark/Grade</th>
                    <th style={{ width: 0 }}></th>
                    <th style={{ width: 0 }}></th>
                  </tr>
                </thead>
                <tbody className="patient-list-table-body">
                  {allEducationList !== null &&
                    allEducationList &&
                    allEducationList.map((item, index) => {
                      return (
                        <tr key={index} className="position-relative">
                          <td>
                            <label className="appointment-id-text">
                              {index+1}
                            </label>
                          </td>
                          <td>
                            <label className="appointment-list-text text-uppercase text-wrap appointmenttextdata">
                              {getCourseText(value,item?.fk_course)}
                            </label>
                          </td>
                          <td>
                            <label className="appointment-list-text">
                               {item?.year_of_pass ? new Date(item?.year_of_pass).toLocaleDateString() : ''}
                            </label>
                          </td>
                          <td>
                            <label className="appointment-list-text text-center">
                              {item?.institution}
                            </label>
                          </td>
                          <td>
                            <label className="appointment-list-text">
                              {item?.score}
                            </label>
                          </td>
                          <td className="">
                              <span
                                className="appointment-edit-btn"
                                onClick={() => handleEdit(index)}
                              >
                                <MdOutlineModeEditOutline size={18} />
                              </span>
                          </td>
                          <td className="">
                            <span
                              className="appointment-del-btn"
                               onClick={() =>handleDelete(index)}
                            >
                              <DeleteOutlineIcon size={18} />
                            </span>
                          </td>
                          {/* {isMd ? (
                            <td className="">
                              <span
                                className="appointment-edit-btn"
                                onClick={() => handleLeaveEdit(item)}
                              >
                                <MdOutlineModeEditOutline size={18} />
                              </span>
                            </td>
                          ) : (
                            <td>
                              <BsThreeDotsVertical
                                onClick={() => setActiveDE((prev) => !prev)}
                              />
                            </td>
                          )}
                          {isMd && (
                            <td className="">
                              <span
                                className="appointment-del-btn"
                                onClick={() =>
                                  handleDeleteWithConfirmation(item)
                                }
                              >
                                <DeleteOutlineIcon size={18} />
                              </span>
                            </td>
                          )} */}

                          {/* {activeDE && (
                            <div
                              className="d-flex gap-3 position-absolute"
                              style={{ right: "60px" }}
                            >
                              <span
                                className="appointment-edit-btn"
                                onClick={() => handleLeaveEdit(item)}
                              >
                                <MdOutlineModeEditOutline size={18} />
                              </span>
                              <span
                                className="appointment-del-btn"
                                onClick={() =>
                                  handleDeleteWithConfirmation(item)
                                }
                              >
                                <DeleteOutlineIcon size={18} />
                              </span>
                            </div>
                          )} */}
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </TabPane>
        </Tab.Content>
      </div>
    </Tab.Container>
  </div>
  )
}

export default EducationList
