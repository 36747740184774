import React from 'react'
import profileImg from "../../../../assets/logos/user_vector.png"
const StaffPopup = () => {

    const educationList = [{
        course:"python",
        year_of_pass:"2022",
        institute:"codeme",
        grade:"A grade"
    },
    {
        course:"html",
        year_of_pass:"2022",
        institute:"codeme",
        grade:"A grade"
    },{
        course:"css",
        year_of_pass:"2022",
        institute:"codeme",
        grade:"A grade"
    }
    ]


    const experienceList = [{
        experience:"codeme",
        location:"calicut",
        fromdate:"2024-12-10",
        todate:"2024-12-11"
    },
    {
        experience:"codeme",
        location:"calicut",
        fromdate:"2024-12-10",
        todate:"2024-12-11"
    },{
        experience:"codeme",
        location:"calicut",
        fromdate:"2024-12-10",
        todate:"2024-12-11"
    }
    ]
  return (
    <div class="container my-5">
 
    <div class="row mb-4">
        <div class="col-md-2">
            <img src={profileImg} class="img-fluid rounded-circle" alt="Profile Image"/>
        </div>
        <div class="col-md-10 d-flex flex-column justify-content-center">
            <h3 className='my-0 py-0'>ID : <span class="text-primary">3456788</span></h3>
            <h3 className='my-0 py-0'>Sample name</h3>
            <p class="text-muted">Nurse</p>
        </div>
    </div>


    <div class="row">
        <div class="col-md-6">
            <div class="p-2">
                <h5>Staff Details</h5>
                <div class="mb-3">
                    <label class="form-label">ID</label>
                    <input type="text" class="form-control"/>
                </div>
                <div class="mb-3">
                    <label class="form-label">Joined Date</label>
                    <input type="date" class="form-control"/>
                </div>
                <div class="mb-3">
                    <label class="form-label">Name</label>
                    <input type="text" class="form-control" />
                </div>
                <div class="mb-3">
                    <label class="form-label">Designation</label>
                    <input type="text" class="form-control" />
                </div>
                <div class="mb-3">
                    <label class="form-label">User Name</label>
                    <input type="text" class="form-control" />
                </div>
                <div class="mb-3">
                    <label class="form-label">Staff Grade</label>
                    <input type="text" class="form-control" />
                </div>
                <div class="mb-3">
                    <label class="form-label">Salary</label>
                    <input type="text" class="form-control"/>
                </div>
            </div>
        </div>

        <div class="col-md-6">
            <div class="p-2">
                <h5>Personal Information</h5>
                <div class="mb-3">
                    <label class="form-label">Email</label>
                    <input type="email" class="form-control" />
                </div>
                <div class="mb-3">
                    <label class="form-label">Mobile</label>
                    <input type="text" class="form-control" />
                </div>
                <div className='col-12 d-flex justify-content-between align-items-center'>
                    <div class="col-6">
                        <label class="form-label">Date of Birth</label>
                        <input type="date" class="form-control"/>
                    </div>
                    <div class="col-6">
                        <label class="form-label">Gender</label>
                        <div>
                            <button type="button" class="btn btn-outline-secondary">Male</button>
                            <button type="button" class="btn btn-success">Female</button>
                            <button type="button" class="btn btn-outline-secondary">Other</button>
                        </div>
                    </div>
                </div>
            
                <div class="row">
                    <div class="col-md-4">
                        <label class="form-label">Country</label>
                        <input type="text" class="form-control" />
                    </div>
                    <div class="col-md-4">
                        <label class="form-label">State</label>
                        <input type="text" class="form-control" />
                    </div>
                    <div class="col-md-4">
                        <label class="form-label">District</label>
                        <input type="text" class="form-control" />
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-md-6">
                        <label class="form-label">City</label>
                        <input type="text" class="form-control" />
                    </div>
                    <div class="col-md-6">
                        <label class="form-label">PIN Code</label>
                        <input type="text" class="form-control" />
                    </div>
                </div>
                <div class="mb-3">
                    <label class="form-label">Address Line 1</label>
                    <input type="text" class="form-control" />
                </div>
                <div class="mb-3">
                    <label class="form-label">Address Line 2</label>
                    <input type="text" class="form-control" />
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-4">
        <div class="col">
            <h5>Education Details</h5>
            <table class="table">
                <thead>
                    <tr>
                        <th>No</th>
                        <th>Course</th>
                        <th>Date</th>
                        <th>Institution</th>
                        <th>Mark/Grade</th>
                    </tr>
                </thead>
                <tbody>
                    {educationList.map((d,i)=>{
                        return(
                        <tr>
                            <td>{i+1}</td>
                            <td>{d.course}</td>
                            <td>{d.year_of_pass}</td>
                            <td>{d.institute}</td>
                            <td>{d.grade}</td>
                        </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    </div>

    <div class="row mt-4">
        <div class="col">
            <h5>Experience Details</h5>
            <table class="table">
                <thead>
                    <tr>
                        <th>No</th>
                        <th>Experience</th>
                        <th>Location</th>
                        <th>From Date</th>
                        <th>To date</th>
                    </tr>
                </thead>
                <tbody>
                    {experienceList.map((d,i)=>{
                        return(
                        <tr>
                            <td>{i+1}</td>
                            <td>{d.experience}</td>
                            <td>{d.location}</td>
                            <td>{d.fromdate}</td>
                            <td>{d.todate}</td>
                        </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    </div>
    </div>

  )
}

export default StaffPopup