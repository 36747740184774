import React from 'react'
const AdmPatientDetails = ({patientDetails,handleNext,handleBack,data,hideButton}) => {
    console.log("$$$$$$$$$$$$$",data)
  return (
    <div>
        <div className='container-fluid mt-4 p-4 ps-3'>
            <div>
                <h4 className=''>Patient Information</h4>
                <hr />
                <div class="row">
                    <div class="col-6 col-7 row mx-0">
                    <div className="col-6 mb-4 mx-0 row">
                        <div className="col-6">First Name</div>
                        <div className="col-6">:&nbsp;{patientDetails?.first_name}</div>
                        
                    </div>
                    <div className="col-6 mb-4 mx-0 row">
                        <div className="col-6">Last Name</div>
                        <div className="col-6">:&nbsp;{patientDetails?.last_name}</div>
                    </div>
                    <div className="col-6 mb-4 mx-0 row">
                        <div className="col-6">Mobile Number</div>
                        <div className="col-6">:&nbsp;{patientDetails?.mobile}</div>
                    </div>
                    <div className="col-6 mb-4 mx-0 row">
                        <div className="col-6">Date of Birth</div>
                        <div className="col-6">:&nbsp;{patientDetails?.dob}</div>
                    </div>
                    <div className="col-6 mb-4 mx-0 row">
                        <div className="col-6">Age</div>
                        <div className="col-6">:&nbsp;{patientDetails?.age_year}</div>
                    </div>
                    <div className="col-6 mb-4 mx-0 row">
                        <div className="col-6">Gender</div>
                        <div className="col-6">:&nbsp;{patientDetails?.gender}</div>
                    </div>
                    <div className="col-6 mb-4 mx-0 row">
                        <div className="col-6">Blood Group</div>
                        <div className="col-6">:&nbsp;{patientDetails?.blood_group}</div>
                    </div>
                    <div className="col-6 mb-4 mx-0 row">
                        <div className="col-6">Marital Status</div>
                        <div className="col-6">:&nbsp;{patientDetails?.marital_status}</div>
                    </div>
                    <div className="col-6 mb-4 mx-0 row">
                        <div className="col-6">Email ID</div>
                        <div className="col-5">:&nbsp;{patientDetails?.email}</div>
                    </div>
                    <div className="col-6 mb-4 mx-0 row">
                        <div className="col-6">UIN</div>
                        <div className="col-6">:&nbsp;{patientDetails?.UIN}</div>
                    </div>
                    <div className="col-6 mb-4 mx-0 row">
                        <div className="col-6">Address Line1</div>
                        <div className="col-6">:&nbsp;{patientDetails?.address_line_1}</div>
                    </div>
                    <div className="col-6 mb-4 mx-0 row">
                        <div className="col-6">Pincode</div>
                        <div className="col-6">:&nbsp;{patientDetails?.pincode}</div>
                    </div>
                    <div className="col-6 mb-4 mx-0 row">
                        <div className="col-6">City/Location</div>
                        <div className="col-6">:&nbsp;{patientDetails?.city}</div>
                    </div>
                    <div className="col-6 mb-4 mx-0 row">
                        <div className="col-6">Country</div>
                        <div className="col-6">:&nbsp;{patientDetails?.country}</div>
                    </div>
                    <div className="col-6 mb-4 mx-0 row">
                        <div className="col-6">State</div>
                        <div className="col-6">:&nbsp;{patientDetails?.state}</div>
                    </div>
                    <div className="col-6 mb-4 mx-0 row">
                        <div className="col-6">Admission Date</div>
                        <div className="col-6">:&nbsp;{patientDetails?.medical_record?.admission_date}</div>
                    </div>
                    </div>
                    <div class="col-5 col-6 row mx-0">
                    <div className="col-6 mb-4 mx-0 row">
                        <div className="col-6">Admission Time</div>
                        <div className="col-6">:&nbsp;{patientDetails?.medical_record?.admission_tme}</div>
                    </div>
                    <div className="col-6 mb-4 mx-0 row">
                        <div className="col-6">File No</div>
                        <div className="col-6">:&nbsp;{patientDetails?.medical_record?.file_no}</div>
                    </div>
                    <div className="col-6 mb-4 mx-0 row">
                        <div className="col-6">Referred Type</div>
                        <div className="col-6">:&nbsp;{patientDetails?.medical_record?.referred_type}</div>
                    </div>
                    <div className="col-6 mb-4 mx-0 row">
                        <div className="col-6">Patient Type</div>
                        <div className="col-6">:&nbsp;{patientDetails?.patient_type}</div>
                    </div>
                    <div className="col-12 mb-4 mx-0 row">
                        <div className="col-3">Emergency Contact</div>
                        <div className="col-9">:&nbsp;{patientDetails?.emergency_contact}</div>
                    </div>
                    {/* <span className="col-6 mb-4" /> */}
                    <div className="col-12 px-4">Notes :</div>
                    <div className="col-12 px-5">
                        {patientDetails?.medical_record?.remark}
                    </div>
                    </div>
                </div>
            </div>
        </div>
        {!hideButton&&( <div className='container-fluid mt-4 p-4 ps-3'>
        <div className='co1-12 d-flex justify-content-end p-2'>
            <div className='buttonviewdown' onClick={handleBack}>Back</div>
            <div className='buttonviewdown'  onClick={handleNext}>Next</div>
        </div>
        </div>)}
    </div>
  )
}

export default AdmPatientDetails