import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import './CheckBox.css'

const CheckBoxBtn = ({ containerClass, label, required, content, value, setSelected, selected }) => {
    const [focus, setFocus] = useState(false)
    return (
        <Form.Group
            className={containerClass}
        >
            <Form.Label
                className='label-text-content'
            >
                {label && label}
                {required && <span className='text-danger'> *</span>}
            </Form.Label>
            <div
                className={`input-checkbox-control ${selected===value?'active ':' '} ${focus?'focus':''}`}
                onClick={()=>setSelected(selected===value?'':value)}
                onFocus={()=>setFocus(true)}
                onBlur={()=>setFocus(false)}
            >   
                {/* <div className="slider-bg"></div>
                <div className="slide"></div> */}
                <div>{content}</div>
            </div>
        </Form.Group>
    )
}

export default CheckBoxBtn
