import React, { useEffect, useState } from "react";
import total_bed from "../../../../../assets/svgs/total-bed.svg";
import available_room from "../../../../../assets/svgs/available-bed.svg";
import occupied_room from "../../../../../assets/svgs/occupied-bed.svg";
import reserved_room from "../../../../../assets/svgs/reserved-bed.svg";
import Mask_group_card from "../../../../../assets/svgs/Mask-group-card.svg";
import edit_icon from "../../../../../assets/svgs/edit-icon.svg";
import delete_red from "../../../../../assets/svgs/delete-red.svg";
import { GoPlus } from "react-icons/go";
import { HiOutlineExclamationCircle } from "react-icons/hi";
import useAccommodationServices from "../../../../services/api-services/accommodationServices";
import { BsThreeDotsVertical } from "react-icons/bs";

const PrivateSuite = ({ handleShowPrivateSuit, getEditSuitRoom }) => {
  const [showModalPrivateDetail, setShowModalPrivateDetail] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("all");
  const [allPrivateSuteRoom, setAllPrivateSuteRoom] = useState([]);
  const [showEdit, setShowEdit] = useState(null);
  const [privateSuteRoomDetails, setPrivateSuteRoomDetails] = useState({});

  const {
    getPrivateSuteRoom,
    deletePrivateSuteRoom,
    getFilteredPrivateSuteRoom,
  } = useAccommodationServices();

  useEffect(() => {
    getPrivateSuteRoomdData();
  }, []);

  const getPrivateSuteRoomdData = async () => {
    try {
      const response = await getPrivateSuteRoom();
      if (response?.success) {
        setAllPrivateSuteRoom(response?.data);
        setPrivateSuteRoomDetails(response?.data[0]);
        console.log(response?.data[0],"=====getPrivateSuteRoomdData");
        
      }
    } catch {}
  };

  const deleteSuteRoom = async (id) => {
    try {
      const response = await deletePrivateSuteRoom(id);
      if (response?.success) {
        getPrivateSuteRoomdData();
      }
    } catch {}
  };

  const showEditContainer = (id) => {
    setShowEdit((prev) => (prev == null ? id : null));
  };
 

  const filteredPrivateSuteRoom = async (value) => {
    try {
      if (value === "all") {
        getPrivateSuteRoomdData();
      } else {
        const response = await getFilteredPrivateSuteRoom(value);
        if (response?.success) {
          setAllPrivateSuteRoom(response?.data);
          console.log(
            response?.data,
            "===========response filteredPrivateSuteRoom"
          );
        }
      }
    } catch (error) {
      console.log(error, "error=========");
    }
  };



  return (
    <div className="px-3">
      <div className="">
        <div className="second-container mb-4 row bg-light rounded">
          <div className="col-8 d-flex  gap-5 "></div>

          <div className="col-4 details-ward  d-flex align-items-center justify-content-end ">
            <button className="px-4">
              <HiOutlineExclamationCircle
                size={15}
                style={{ marginRight: "5PX" }}
              />
              Details
            </button>
            <button className="px-4 ms-3" onClick={handleShowPrivateSuit}>
              <GoPlus size={18} style={{ marginRight: "5PX" }} />
              Add Suit Room
            </button>
          </div>
        </div>
      </div>

      <div className="row gap-2 d-flex mt-3 px-1">
        <div className="col total-bed">
          <div className="d-flex ">
            <div className="me-3">
              <div className="">
                <img src={total_bed} alt="" />
              </div>
              <p className="h3 mt-2">
                {privateSuteRoomDetails?.total_room_count}
              </p>
            </div>
            <div className="">
              <p className="head-bed m-0 mb-1">Total Room</p>
              <p className=" m-0 p-0 total-no">Total No. of Rooms</p>
            </div>
          </div>
        </div>
        <div className="col total-bed">
          <div className="d-flex">
            <div className="me-3">
              <div className="">
                <img src={available_room} alt="" />
              </div>
              <p className="h3 mt-2">
                {privateSuteRoomDetails?.available_room_count}
              </p>
            </div>
            <div className="">
              <p className="head-bed m-0 mb-1">Available Room</p>
              <p className=" m-0 p-0 total-no">Total No. of Available Rooms</p>
            </div>
          </div>
        </div>
        <div className="col total-bed">
          <div className="d-flex">
            <div className="me-3">
              <div className="">
                <img src={occupied_room} alt="" />
              </div>
              <p className="h3 mt-2">
                {privateSuteRoomDetails?.occupied_room_count}
              </p>
            </div>
            <div className="">
              <p className="head-bed m-0 mb-1">Occupied Room</p>
              <p className=" m-0 p-0 total-no">Total No. of Occupied Rooms</p>
            </div>
          </div>
        </div>
        <div className="col total-bed">
          <div className="d-flex ">
            <div className="me-3">
              <div className="">
                <img src={reserved_room} alt="" />
              </div>
              <p className="h3 mt-2">
                {privateSuteRoomDetails?.reserved_room_count}
              </p>
            </div>
            <div className="">
              <p className="head-bed m-0 mb-1">Reserved Room</p>
              <p className=" m-0 p-0 total-no">Total No. of Reserved Rooms</p>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-3 mx-2 ">
        <div className="col-6 d-flex align-items-center ">
          <h3>Bed List</h3>
        </div>
        <div className="bed-list-sub-out col-6 d-flex justify-content-between  ">
          <button
            className={`px-5 ${
              selectedFilter === "all" ? "bed-list-sub " : ""
            }`}
            onClick={() => {
              setSelectedFilter("all");
              filteredPrivateSuteRoom("all");
            }}
          >
            All
          </button>
          <button
            className={`px-5 ${
              selectedFilter === "available" ? "bed-list-sub" : ""
            }`}
            onClick={() => {
              setSelectedFilter("available");
              filteredPrivateSuteRoom("available");
            }}
          >
            Available
          </button>
          <button
            className={`px-5 ${
              selectedFilter === "occupied" ? "bed-list-sub" : ""
            }`}
            onClick={() => {
              setSelectedFilter("occupied");
              filteredPrivateSuteRoom("occupied");
            }}
          >
            Occupied
          </button>
          <button
            className={`px-5 ${
              selectedFilter === "reserved" ? "bed-list-sub" : ""
            }`}
            onClick={() => {
              setSelectedFilter("reserved");
              filteredPrivateSuteRoom("reserved");
            }}
          >
            Reserved
          </button>
        </div>
      </div>

      <div className="row  mt-3 px-1">
        {allPrivateSuteRoom.map((data) => (
          <div className="col-6 p-2 " key={data.id} >
            <div className="d-flex h-100 suite-img2">
              <div className="me-3 h-100 suite-img3">
                <div className="">
                  <img src={Mask_group_card} alt="" />
                </div>
              </div>
              <div className="d-flex pt-2 justify-content-between w-100 pe-4 position-relative">
                <div>
                  <p className="p-0 m-0 card-id-number">{data.suite_id}</p>
                  <p className="p-0 m-0 card-room">{data.suite_name}</p>
                  <p className=" card-floor ">{data.floor_no}</p>
                  <p className="p-0  card-patient-ID">patient ID</p>
                  <p className="p-0  card-patient-name">patient Name</p>
                  <p className="p-0  card-ip-number">IP Number</p>
                </div>
                <div className="card-right-div ">
                  <div
                    className="showEditdiv d-flex justify-content-end p-0 m-0 mb-3"
                    onClick={() => showEditContainer(data.id)}
                  >
                    <BsThreeDotsVertical className="fs-3 p-0 m-0" />
                  </div>
                  <button className="px-2 py-1 mb-1 rounded-2">
                    <p>{data.suite_status}</p>
                  </button>
                  <p className="p-0 m-0 card-Rete-per-ay mb-1">Rete per Day</p>
                  <p className="p-0 m-0 card-pay-number">{data.rate_per_day}</p>
                </div>
                {showEdit == data.id && (
                  <div className="private-sute-edit px-2 py-3 ">
                    <div
                      className="w-100 d-flex align-items-center edit-icon ps-4"
                      onClick={() => getEditSuitRoom(data.id)}
                    >
                      <img src={edit_icon} alt="" className="" />
                      <p className="ms-3">Edit</p>
                    </div>
                    <div
                      className="w-100 d-flex align-items-center ps-4 delete-red"
                      onClick={() => deleteSuteRoom(data.id)}
                    >
                      <img src={delete_red} alt="" className=" " />
                      <p className="ms-3">Delete</p>
                    </div>
                    <div className="detail-icon w-100 d-flex align-items-center ps-4">
                      <HiOutlineExclamationCircle
                        style={{ color: "#5C09E1" }}
                        size={20}
                      />
                      <p className="ms-3">Detail</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PrivateSuite;
