import React, { useEffect, useState } from "react";
import useAccountServices from "../../../../services/api-services/accountServices";
import useAccommodationServices from "../../../../services/api-services/accommodationServices";
import Swal from "sweetalert2";

const AddAyurvedicRoom = ({handleCloseAddAyurvedicRoom }) => {
  const [therapyRoomId, setTherapyRoomId] = useState(0);
  const [editAyurvedicRoomData, setEditAyurvedicRoomData] = useState({});
  
  const { postAyurvedicRoom } = useAccommodationServices();
  const { getNewID } = useAccountServices()


	useEffect(() => {
		getAyurvedicRoomId()
	}, [])

  const handleChangeAyurvedicRoom = (e) => {
    const { name, value } = e.target;

    setEditAyurvedicRoomData((prev) => ({
        ...prev,
        [name]: value,
      }));

  };

  const getAyurvedicRoomId = async () => {
		try {
			const response = await getNewID("therapy_room_id")
			if (response?.success) {
				setTherapyRoomId(response?.data?.complete_id)
			}
		} catch { }
	}

  const handleSubmitAyurvedicRoom = async () => {
    try {

      const data = JSON.stringify(editAyurvedicRoomData);
      const response = await postAyurvedicRoom(data);
      if (response?.success) {
        Swal.fire("", response?.message, "success");
        handleCloseAddAyurvedicRoom();  
      } else {
        Swal.fire("", response?.message, "error");
      }
    } catch (error) {
      console.log(error, "error=========");
    }
  };

  return (
    <div className="px-2">
      <div className="private-suit-container mb-4 row rounded-3  bg-light">
        <div className="col-8 h-100 ">
          <p className="private-suit-head d-flex align-items-center">
            Private Suite Edit
          </p>
        </div>

        <div className="col-4 details-ward  d-flex align-items-center justify-content-end ">
          <button className="px-3" onClick={handleCloseAddAyurvedicRoom}>
            Cancel
          </button>
          <button className="px-4 ms-3" onClick={handleSubmitAyurvedicRoom}>
            Save
          </button>
        </div>
      </div>

      <div
        className="w-100 h-100 private-suit-container pb-3 mb-5"
        role="document"
      >
        <div className="modal-content overflow-hidden  px-3">
          <div className="row gx-5 mt-4 ">
            <div className="col-md-6">
              <label htmlFor="" className="modal-label">
                Therapy Bed ID
              </label>
              <div className="rounded mt-1">
                <input
                  type="text"
                  name="therapy_room_id"
                  value={therapyRoomId}
                  className="w-100 h-100 form-control p-2 m-0 bg-light"
                  readOnly
                />
              </div>
            </div>
            <div className="col-md-6">
              <label htmlFor="" className="modal-label">
                Therapy Bed Name
              </label>
              <div className="rounded mt-1">
                <input
                  type="text"
                  name="room_name"
                  value={editAyurvedicRoomData?.room_name}
                  onChange={handleChangeAyurvedicRoom}
                  className="w-100 h-100 form-control p-2 m-0 bg-light"
                />
              </div>
            </div>
          </div>

          <div className="row gx-5 mt-3">
            <div className="col-md-6">
              <div className="row ">
                <div className="col-12">
                  <label htmlFor="" className="modal-label">
                    Floor
                  </label>
                  <div className="rounded mt-1">
                    <input
                      type="text"
                      value={editAyurvedicRoomData?.floor}
                      name="floor"
                      onChange={handleChangeAyurvedicRoom}
                      className="w-100 h-100 form-control p-2 m-0 bg-light"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row ">
                <div className="col-12">
                  <label htmlFor="" className="modal-label">
                    Room No
                  </label>
                  <div className="rounded mt-1">
                    <input
                      type="text"
                      value={editAyurvedicRoomData?.room_no}
                      name="room_no"
                      onChange={handleChangeAyurvedicRoom}
                      className="w-100 h-100 form-control p-2 m-0 bg-light"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <p className="mt-3">Supported Therapy</p>
          <div className="w-100 px-3 h-100  supported-therapy">
          <div className="row g-3 py-3 ">
            <div className="col-md-4 ">
              <div className="m-0 p-0 border supported-therapy-inner">
              <label className="custom-checkbox m-0 p-2 d-flex align-items-center ">
                <input type="checkbox" className="m-0 p-0"/>
                    <span className="checkmark m-0 p-0"></span> 
              </label>
              </div>
            </div>
            <div className="col-md-4">
            <div className="m-0 p-0 border supported-therapy-inner">
              <label className="custom-checkbox m-0 p-2 d-flex align-items-center ">
                <input type="checkbox" className="m-0 p-0"/>
                    <span className="checkmark m-0 p-0"></span> 
              </label>
              </div>
            </div>
            <div className="col-md-4">
            <div className="m-0 p-0 border supported-therapy-inner">
              <label className="custom-checkbox m-0 p-2 d-flex align-items-center ">
                <input type="checkbox" className="m-0 p-0"/>
                    <span className="checkmark m-0 p-0"></span> 
              </label>
              </div>
            </div>
            <div className="col-md-4">
            <div className="m-0 p-0 border supported-therapy-inner">
              <label className="custom-checkbox m-0 p-2 d-flex align-items-center ">
                <input type="checkbox" className="m-0 p-0"/>
                    <span className="checkmark m-0 p-0"></span> 
              </label>
              </div>
            </div>
            <div className="col-md-4">
            <div className="m-0 p-0 border supported-therapy-inner">
              <label className="custom-checkbox m-0 p-2 d-flex align-items-center ">
                <input type="checkbox" className="m-0 p-0"/>
                    <span className="checkmark m-0 p-0"></span> 
              </label>
              </div>
            </div>
            <div className="col-md-4">
            <div className="m-0 p-0 border supported-therapy-inner">
              <label className="custom-checkbox m-0 p-2 d-flex align-items-center ">
                <input type="checkbox" className="m-0 p-0"/>
                    <span className="checkmark m-0 p-0"></span> 
              </label>
              </div>
            </div>
          </div>
        </div>

        </div>
        
      </div>
    </div>
  );
};

export default AddAyurvedicRoom;
