import React, { useEffect, useState } from 'react'
import EducationForm from './subcomponents/EducationForm'
import EducationList from './subcomponents/EducationList'

const EducationDetails = ({handleBack,handleNext,value,setValue,functions}) => {
    const [educationIndex, setEducationIndex] = useState(null);
    
    // Load data from local storage on initial render
    useEffect(() => {
        const savedEducations = JSON.parse(localStorage.getItem('educations'));
        if (savedEducations) {
            setValue?.setEducations(savedEducations);
        }
      }, []);
    
    // Save data to local storage whenever `educations` changes
    useEffect(() => {
    localStorage.setItem('educations', JSON.stringify(value?.educations));
    }, [value?.educations]);


    const handleEductionSubmit = ()=>{
        const newEducation = {
            id:value?.edcId,
            fk_course: value.course,
            year_of_pass: value.passDate,
            institution: value.institution,
            score: value.mark,
          };
        if (educationIndex !== null) {
        // Update existing education
        const updatedEducations = value?.educations.map((education, index) =>
            index === educationIndex ?newEducation : education
        );
       
        setValue?.setEducations(updatedEducations);
        setEducationIndex(null);
        } else {
        // Add new education
        setValue?.setEducations([...value?.educations, newEducation]);
        }
        setValue?.setEdcId('');
        setValue?.setCourse('');
        setValue?.setPassDate('');
        setValue?.setInstitution('');
        setValue?.setMark('');
    }

    const handleEdit = (index) => {
        setEducationIndex(index);
        value?.educations[index].id ? setValue?.setEdcId(value?.educations[index].id) : setValue?.setEdcId('');
        setValue?.setCourse(value?.educations[index].fk_course);
        setValue?.setPassDate(value?.educations[index].year_of_pass);
        setValue?.setInstitution(value?.educations[index].institution);
        setValue?.setMark(value?.educations[index].score);
      };
    
      const handleDelete = (index) => {
        const updatedUsers = value?.educations.filter((_, i) => i !== index);
        setValue?.setEducations(updatedUsers);
      };
  return (
    <div>
        <div className="row">
                <div className="col-9 page-title-label">
                    Education Details
                </div>
                <div>
                    <EducationForm {...{value,setValue,functions,educationIndex}} educationSubmit={handleEductionSubmit}/>
                </div>
                <div>
                    <EducationList {...{value,setValue,functions,handleEdit,handleDelete}} allEducationList={value?.educations}/>
                </div>
        </div>
        <div className='container-fluid mt-4 p-4 ps-3'>
            <div className='co1-12 d-flex justify-content-end p-2'>
                <div className='buttonviewdown' onClick={handleBack}>Back</div>
                <div className='buttonviewdown'  onClick={handleNext}>Next</div>
            </div>
        </div>
    </div>
  )
}

export default EducationDetails