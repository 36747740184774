import React, { useState } from 'react'
import "./PayrollReport.css";
import staff_attendance from"../../../assets/svgs/add-icon.svg"
import uiw_pay from"../../../assets/svgs/uiw-pay.svg"
import calendar from"../../../assets/svgs/calendar.svg"
import vector from"../../../assets/svgs/vector.svg"
import { Tab, TabPane } from "react-bootstrap";
import { HashLoader } from 'react-spinners';
import { Modal } from "antd";


const PayrollReport = () => {
    const [loading,setLoading] = useState(true)
    const [payrollReport,setPayrollReport] = useState([])
    const [staffDetails, setStaffDetails] = useState(false);
    const [testVal, setTestVal] = useState(1);


  return (
    <div>
      <Modal
        title={null}
        centered
        open={staffDetails}
        width={900}
        closable={false}
        footer={null}
        onCancel={() => setStaffDetails(false)}
        className='payroll-report p-0'
      >
        <div className=' pt-3'>
        <p className='fs-6 fw-bold'>Payroll</p>
        <div className="row mt-4 m-0 ">
        <div className="col-4 p-0  d-flex align-items-center justify-content-between ">
              <p className="p-0 m-0 fs-6 ">Payroll ID</p>
              <div className="p-1 border bg-light rounded  position-relative w-75">
                <input type="text" className="w-100 h-100 pe-4 " />
                <img src={calendar} alt="" className='calendar-img ' />
              </div>
            </div>
            <div className="col-4 p-0 ps-3 d-flex align-items-center justify-content-between ">
              <p className="p-0 m-0 fs-6">Pay Date</p>
              <div className="p-1 border bg-light rounded  position-relative w-75">
                <input type="text" className="w-100 h-100 pe-4" />
                <img src={calendar} alt="" className='calendar-img ' />
              </div>
            </div>
            <div className="col-4 p-0 d-flex align-items-center justify-content-between ">
              <p className="p-0 m-0 fs-6 ms-3">Remark</p>
              <div className="p-1 border bg-light rounded ">
                <input type="text"  className="w-100 h-100" />
              </div>
            </div>
        </div>

        <p className='mt-3 fs-6 fw-bold salary-date'>Salary Date Aloction</p>
        <div className="row mt-3 m-0 ">
        <div className="col-4 p-0 pe-3 d-flex align-items-center justify-content-between ">
              <p className="p-0 m-0 fs-6">From</p>
              <div className="p-1 border bg-light rounded  position-relative">
                <input type="text" className="w-100 h-100 pe-4" />
                <img src={calendar} alt="" className='calendar-img ' />
              </div>
            </div>
            <div className="col-4 p-0 pe-3 d-flex align-items-center justify-content-between ">
              <p className="p-0 m-0 fs-6 ms-2">Upto</p>
              <div className="p-1 border bg-light rounded  position-relative">
                <input type="text" className="w-100 h-100 pe-4" />
                <img src={calendar} alt="" className='calendar-img ' />
              </div>
            </div>
           
        </div>
        
        <div className="row p-0 m-0 mt-4 bg-light border border-1">
          <Tab.Container activeKey={"tab1"} className=" p-0  m-0 w-100">
          <Tab.Content className="bg-light p-0">
              <TabPane eventKey="tab1" className="">
              <table className="d-none d-sm-table  table-payroll-report w-100">
                  <thead className=" bg-light table-payroll-report-head">
                    <tr className="bg-light ">
                      <th>Staff ID</th>
                      <th>Staff Name</th>
                      <th>Allowed Leave</th>
                      <th>Leave Taken</th>
                      <th>Leave cut</th>
                      <th>PF</th>
                      <th>ESI</th>
                      <th>Insurance</th>
                      <th>Other cut</th>
                    </tr>
                  </thead>
                  <tbody
                        className="custom-table-body"
                        style={{ objectFit: "contain" }}
                      >
                       <tr className=" bg-white m-4 rounded-3 border border-1">
                                <td>1</td>
                                <td>2</td>
                                <td>3</td>
                                <td>4</td>
                                <td>5</td>
                                <td>6</td>
                                <td>7</td>
                                <td>8</td>
                                <td>8</td>
                              </tr>
                       <tr className=" bg-white m-4 rounded-3 border border-1">
                                <td>1</td>
                                <td>2</td>
                                <td>3</td>
                                <td>4</td>
                                <td>5</td>
                                <td>6</td>
                                <td>7</td>
                                <td>8</td>
                                <td>8</td>
                              </tr>
                      </tbody>
                  </table>

              </TabPane>
              </Tab.Content>
              </Tab.Container>
              </div>
              </div>
      </Modal>

      <Tab.Container activeKey={"tab1"}>
        <div className="px-3 w-100 h-100">
          <div className="row staff-attendance-head-row mt-2 py-2">
            <div className="col-3 ">
              <div className="p-2 border rounded staff-attendance-head-col d-flex align-items-center">
                <img src={uiw_pay} alt="" />
                <p className="ms-3">Payroll Report</p>
              </div>
            </div>
          </div>
        </div>
        <div className="staff-attendance-main ps-3 mt-4 pt-3">
          <p className="h5">Payroll Report</p>
          <div className="row px-3 mt-4 gap-5">
            <div className="col-3 p-0 d-flex align-items-center justify-content-between">
              <p className="p-0 m-0">from</p>
              <div className="p-1 border bg-light rounded position-relative">
                {/* <input type="text" name="room_id" className="w-100 h-100" /> */}
                <input type="text" className="w-100 h-100 pe-4 " />
                <img src={calendar} alt="" className='calendar-img ' />
              </div>
            </div>

            <div className="col-3 p-0 d-flex align-items-center justify-content-between">
              <p className="p-0 m-0">upto</p>
              <div className="p-1 border bg-light rounded position-relative">
                <input type="text" name="room_id" className="w-100 h-100 pe-4" />
                <img src={calendar} alt="" className='calendar-img ' />
              </div>
            </div>
          </div>
          <div className="patient-list-container">
            <Tab.Content className="patient-table-container container-white">
              <TabPane eventKey="tab1" className="">
                <table className="table patient-list-table d-none d-sm-table">
                  <thead className="patient-list-table-head">
                    <tr>
                      <th>Sl.No</th>
                      <th>Payroll ID</th>
                      <th>Payment Date</th>
                      <th>From</th>
                      <th>To</th>
                      <th>Total</th>
                      <th>Remark</th>
                      <th>Detail</th>
                    </tr>
                  </thead>
                  <tbody className="patient-list-table-body" style={{objectFit:"contain"}}>
                  <tr>
                          <td>1</td>
                          <td>2</td>
                          <td>3</td>
                          <td>4</td>
                          <td>5</td>
                          <td>6</td>
                          <td>7</td>
                          <td onClick={()=>setStaffDetails(true)}><img src={vector} className='vector-icon' alt="" /></td>
                        </tr>
                  </tbody>
                  {/* {loading ? (
              <tbody>
              <tr>
                <td colSpan="7">
                  <div className="d-flex justify-content-center align-items-center loader-container">
                    <HashLoader color="#e3b075" size={100} />
                  </div>
                </td>
              </tr>
            </tbody>
            ) :<>
            <tbody className="patient-list-table-body" style={{objectFit:"contain"}} >
                  {payrollReport &&
                    payrollReport.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      );
                    })}
                </tbody>
                </> } */}
                </table>
                
              </TabPane>
            </Tab.Content>
          </div>
        </div>
      </Tab.Container>
    </div>
  )
}

export default PayrollReport
