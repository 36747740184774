import React from "react";
import "./Profile.css";
import { RiLockPasswordFill } from "react-icons/ri";
import { MdDeleteForever } from "react-icons/md";

const Profile = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="con col-12 col-md-3 d-md-block d-flex justify-content-center align-items-center flex-column">
          <div className="img-div mb-4">
            <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTOuxrvcNMfGLh73uKP1QqYpKoCB0JLXiBMvA&s"
              className="w-100 h-100 rounded-circle"
              alt=""
            />
          </div>
          <p className="profile">Admin</p>
          <p className="profile mb-4">Phone Number</p>
        </div>
        <div className="col-12 col-md-8 shadowed-div p-4 rounded mb-4">
          <h4>Personal Information</h4>
          <div className="row input-main ">
            <div className="col-12 col-sm-6 first-inp mt-2">
              <label htmlFor="">First Name</label>
              <br />
              <input
                type="text"
                placeholder="First Name"
                className="p-2 w-100 rounded"
              />
            </div>
            <div className="col-12 col-sm-6 last-inp mt-2">
              <label htmlFor="">Last Name</label>
              <br />
              <input
                type="text"
                placeholder="Last Name"
                className="p-2 w-100 rounded"
              />
            </div>
            <div className="col-12 mt-2">
              <label htmlFor="">Email</label>
              <input
                type="text"
                placeholder="Email"
                className="p-2 w-100 rounded"
              />
            </div>
            <div className="col-12 mt-2">
              <label htmlFor="">Phone Number</label>
              <input
                type="text"
                placeholder="Phone Number"
                className="p-2 w-100 rounded"
              />
            </div>
          </div>
        </div>
        <div>
          <div>
            <RiLockPasswordFill size={15} />
            <span className="change-password">Change Password</span>
          </div>
          <div className="mt-2">
            <MdDeleteForever size={15} className="delete-forever" />
            <span className="user-delete">User Delete</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
