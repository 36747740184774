import React, { useEffect, useState } from 'react'
import DropDownBox from '../../../../../../../shared/form-components/dropdown/CustomDropDown'

const PropertyDropDownBox = ({state,setnew,options,setData,selectedValue,listData,remove,handleAdd}) => {
    return (
        <div className='patient-symptoms-container col-12 mx-0 pt-0'>
            <div className='patient-symptoms-content col-12 row mx-0 mt-0 pt-0 property'>
                    <div className='col-12 d-flex align-items-start p-1 ps-0 pe-2 pt-1' style={{height:"3rem"}}>
                        <DropDownBox
                            containerClass={"col-11 p-1"}
                            addNew={true}
                            fluid={true}
                            search={true}
                            selection={true}
                            state={state}
                            setNew={setnew}
                            options={options}
                            setDataValue={setData}
                            selectedValue={selectedValue}
                            placeholder={'Type Here ...'}
                            nolabel={true}
                        />
                        <button className="btn text-light col-1 fs-6 fw-bold mt-1 p-1 rounded-3"
                        style={{ backgroundColor: "#e3b075" }}
                        type="button"
                        onClick={handleAdd}>+</button>
                    </div>
                    <br />
                    {listData&& (
                        <div className='mx-0 p-1 pe-2'>
                            {listData?.map((item, i) => (
                                <div className="bg-light rounded-2 d-flex align-items-center mb-1" key={i}>
                                    <span className='col-11 ps-3'>{state==="restricted_food"&&item.restricted_food?.property_value}
                                    {state==="recommended_food"&&item.recommended_food?.property_value}</span>
                                    <button className="btn col-1 fs-5 fw-300" onClick={() => remove(item)}>x</button>
                                </div>
                            ))}
                        </div>
                    )}
            </div>
        </div>
    )
}


export default PropertyDropDownBox