import React from 'react'
import PatientSymptoms from '../../PatientSymptoms'

const LifeList = ({value,setValue,functions,subfunctions}) => {
  return (
    <div className='p-5 pt-0'>
        <div className='col-12'>
        <label className='label-text-content'>Life style modifications</label>
        <PatientSymptoms
            onAdd={subfunctions?.handleAddLifeStyle}
            data={value?.lifeStyleList}
            onRemove={subfunctions?.handleDeleteLifeStyle}
            iconCol={true}
            plusBtn={true}
        />
        </div>
    </div>
  )
}
export default LifeList