import React from 'react'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import useAxios from '../../hooks/useAxios'

const useAccommodationServices = () => {
	const axiosPrivate = useAxiosPrivate()
	const axios = useAxios()

	const postWardAddition = async (data) => {
		const response = await axiosPrivate.post('/accommodation/ward/detail/create/', data)
		return response?.data
	}

	const getAllWard = async () => {
		const response = await axiosPrivate.get('/accommodation/ward/detail/create/')
		return response?.data
	}

	const getFilteredWard = async (data) => {
		const response = await axiosPrivate.get(`/accommodation/ward/detail/create/`, {
		  params: { status: data }
		});
		return response?.data;
	  };

	const getWardUpdate = async (id) => {
		const response = await axiosPrivate.put(`/accommodation/ward/bed/update/${id}/`);
		return response.data;
	};

	const putWardUpdate = async (id, data) => {
		const response = await axiosPrivate.put(
			`/accommodation/ward/detail/update/${id}/`,
			data
		);
		return response.data;
	};


	const getPrivateRoomDetails = async (data) => {
		const response = await axiosPrivate.get('/accommodation/private_rooms/details/create/')
		return response?.data
	}

	const getFilteredPrivateRoom = async (data) => {
		const response = await axiosPrivate.get('/accommodation/private_rooms/details/create/', {
		  params: { status: data }
		});
		return response?.data;
	  };

	const postPrivateRoomId = async (data) => {
		const response = await axiosPrivate.post('/accommodation/private_rooms/create/', data)
		return response?.data
	}

	const postPrivateRoom = async (data) => {
		const response = await axiosPrivate.post('/accommodation/private_rooms/create/', data)
		return response?.data
	}


	// Accommodation Details
	const getAccommodationDetails = async (params) =>  {
		const response = await axiosPrivate.get(
			'/accommodation/accommodation/details/create/',
			{
				params: {...params},
			}
		)
		return response?.data
	}	

	const postAccommodationDetails = async (data) => {
		const response = await axiosPrivate.post('/accommodation/accommodation/details/create/', data)
		return response?.data
	}	

	const deleteAccommodationDetails = async (id) => {
		const response = await axiosPrivate.delete(`/accommodation/accommodation/details/${id}/`);
		return response.data;
	};

	const postPrivateRoomsDetails = async (data) => {

		const response = await axiosPrivate.post('/accommodation/private_rooms/details/create/', data)
		return response?.data
	}

	const putPrivateRoomsRateperDay = async (data,id) => {
		
		const response = await axiosPrivate.put(`/accommodation/private_rooms/detail/update/${id}/`,data)
		return response?.data
	}

	const postAddBed = async (data) => {		
		const response = await axiosPrivate.post('/accommodation/ward/bed/create/',data)
		return response?.data
	}

	const putAddBed = async (id,data) => {		
		const response = await axiosPrivate.put(`/accommodation/private_rooms/update/${id}/`,data)
		return response?.data
	}

	const getPrivateSuteRoom = async () => {
		const response = await axiosPrivate.get('/accommodation/suite/create/')
		return response?.data
	}

	const getFilteredPrivateSuteRoom = async (data) => {
		const response = await axiosPrivate.get('/accommodation/suite/create/', {
		  params: { status: data }
		});
		return response?.data;
	  };

	const deletePrivateSuteRoom = async (id) => {
		const response = await axiosPrivate.delete(`/accommodation/suite/update/${id}/`)
		return response?.data
	}

	const postSuteAddition = async (data) => {
		const response = await axiosPrivate.post('/accommodation/suite/create/', data)
		return response?.data
	}
	
	const getSuteEdit = async (id) => {
		const response = await axiosPrivate.get(`/accommodation/suite/update/${id}/`)
		return response?.data
	}
	const putSuteEdit = async (id,data) => {
		const response = await axiosPrivate.put(`/accommodation/suite/update/${id}/`,data)
		return response?.data
	}

	// const getStaffAttendanceReport = async () => {
	// 	const response = await axiosPrivate.get('/account/staff/attendance/report/')
	// 	return response?.data
	// }

	// // const getStaffAttendanceReport = async () => {
	// // 	const response = await axiosPrivate.get('/account/staff/attendance/report/',{
	// // 		params: {
	// // 			from_date:'2024-01-01',
	// // 			to_date:'2024-12-31',
	// // 		  }
	// // 	})
	// // 	return response?.data
	// // }

	// const getStaffDetail = async (id) => {
	// 	const response = await axiosPrivate.get(`/account/staff/detail/${id}/`)
	// 	return response?.data
	// }

	const getAyurvedicRoom = async () => {
		const response = await axiosPrivate.get('/accommodation/therapy/room/create/')
		return response?.data
	}

	const postAyurvedicRoom = async (data) => {
		const response = await axiosPrivate.post('/accommodation/therapy/room/create/', data)
		return response?.data
	}



	return {
		postWardAddition,
		getAllWard,
		getFilteredWard,
		putWardUpdate,
		getWardUpdate,
		getPrivateRoomDetails,
		postPrivateRoom,
		postPrivateRoomId,
		putPrivateRoomsRateperDay,
		// Accommodation Details
		postAccommodationDetails,
		getAccommodationDetails,
		deleteAccommodationDetails,
		postPrivateRoomsDetails,
		postAddBed,
		putAddBed,
		getPrivateSuteRoom,
		postSuteAddition,
		getSuteEdit,
		putSuteEdit,
		deletePrivateSuteRoom,
		getFilteredPrivateRoom,
		getFilteredPrivateSuteRoom,
		// getStaffAttendanceReport,
		// getStaffDetail,
		getAyurvedicRoom,
		postAyurvedicRoom
	}




}

export default useAccommodationServices
