import React, { useEffect, useRef, useState } from "react";
// import React, { useEffect, useState } from 'react'
import { Form, Tab, TabPane, Nav } from "react-bootstrap";
import "./Ipadmissions.css";
import Swal from "sweetalert2";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import moment from "moment";
import { MdOutlineModeEditOutline } from "react-icons/md";
import HashLoader from "react-spinners/ClockLoader";
import AdmissionPagination from "../reception/patient/patient-list/components/AdmissionPagination";
// import useConsultationServices from "../../../../services/api-services/consultationServices";
import useInpatientServices from "../../../../src/app/services/api-services/inpatientServices";
import { BsThreeDotsVertical, BsInfoCircle } from "react-icons/bs";
import { GoPlusCircle } from "react-icons/go";
import dischargeImg from "../../../assets/svgs/dropdowns/healthicons_discharge-outline.svg"
import consultImg	from "../../../assets/svgs/dropdowns/maki_doctor.svg"
import checkupImg from "../../../assets/svgs/dropdowns/tabler_checkup-list.svg"
import editImg from "../../../assets/svgs/dropdowns/bitcoin-icons_edit-outline.svg"
import deleteImg from "../../../assets/svgs/dropdowns/mingcute_delete-line.svg"
import detailImg from "../../../assets/svgs/dropdowns/clarity_help-line.svg"


const Admission = (props) => {
	const {
		IPAdmissionList, loading,
		status, isMd, setIsMd,
		setStatus,admPopup,setAdmPopup,setPopPage,handleGetIpDetails
	} = props;

	useEffect(() => {
		setStatus(status)
	}, [
		// pagination.page,
		// sortBy,
		// search,
		// filtered,
		// selectedDate,
		// fromDate,
		// toDate,
		// filterType,
	]);

	const handlePopup = (id) =>{
		setAdmPopup(!admPopup)
		setPopPage('admission')
		handleGetIpDetails(id)
	  }
	  const [activeDropdown, setActiveDropdown] = useState(null);
	  const dropdownRef = useRef(null);

	  const toggleDropdown = (id) => {
		setActiveDropdown((prev) => (prev === id ? null : id));
	  };

	  const handleAction = (actionType, item) => {
		switch (actionType) {
		  case "discharge":
			console.log("Discharge action for:", item);
			// Add logic to handle discharge
			break;
		  case "consult":
			console.log("Consult action for:", item);
			// Add logic to handle consult
			break;
		  case "checkup":
			console.log("Checkup action for:", item);
			// Add logic to handle checkup
			break;
		  case "edit":
			console.log("Edit action for:", item);
			// Add logic to handle edit
			break;
		  case "delete":
			console.log("Delete action for:", item);
			// Add logic to handle delete
			break;
		  case "detail":
			console.log("Detail action for:", item);
			// Add logic to handle detail
			break;
		  default:
			console.log("Unknown action");
		}
	  };

	  const handleClickOutside = (event) => {
		if (!event.target.closest(".dropdown-menu1")) {
		  setActiveDropdown(null);
		}
	  };
	
	  useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		return () => document.removeEventListener("mousedown", handleClickOutside);
	  }, []);

	return (
		<>
			<Tab.Container activeKey={"tab1"}>
				<div className="patient-list-container">
					<Tab.Content className="patient-table-container container-white">
						<TabPane eventKey="tab1" className="">
							<div className="table-responsive">
								<table className="table patient-list-table px-2 ">
									<thead className="patient-list-table-head">
										<tr>
											<th style={{ width: 0 }}>Sl.no</th>
											<th style={{ width: 0 }}>Patient ID</th>
											<th style={{ width: 0 }}>IP Number</th>
											<th style={{ width: 0 }}>Patient Name</th>
											<th style={{ width: 0 }}>Room/W.no</th>
											<th style={{ width: 0 }}>Incharge Physician</th>
											<th style={{ width: 0 }}>Admitted Date</th>
											<th style={{ width: 0 }}>Status</th>
											<th style={{ width: 0 }}></th>
											{isMd && (
												<th className="text-center" style={{ width: 0 }}>
													Action
												</th>
											)}
										</tr>
									</thead>

									{loading ? (
										<tbody>
											<tr>
												<td colSpan="7">
													<div className="d-flex justify-content-center align-items-center loader-container">
														<HashLoader color="#e3b075" size={100} />
													</div>
												</td>
											</tr>
										</tbody>
									) : (
										<>
											<tbody className="patient-list-table-body">
												{IPAdmissionList &&
													IPAdmissionList.map((item, index) => {
														return (
															<tr key={index} className="position-relative">
																<td>
																	<label className="appointment-list-text">
																		{index + 1}
																	</label>
																</td>
																<td>
																	<label className="appointment-list-text patient-id-color">
																		{item?.patient_info?.patient_id}
																	</label>
																</td>

																<td>
																	<label className="appointment-list-text patient-id-color">
																		{item?.ip_number}
																	</label>
																</td>

																{/* <td onClick={() => handleShowAppointment(item)}> */}
																<td>
																	<label
																		className="appointment-list-text text-uppercase text-wrap"
																		role="button"
																	>
																		{item?.patient_info?.full_name}
																	</label>
																</td>
																<td>
																	<label className="appointment-list-text" ref={dropdownRef}>
																		{
																			`${
																				item?.patient_accommodation_details?.accommodation_type == 'ward' ? 
																				item?.patient_accommodation_details?.bed_detail?.fk_ward_details?.ward_name+'/'+item?.patient_accommodation_details?.bed_detail?.bed_no 
																				: item?.patient_accommodation_details?.accommodation_type == 'room' ? 
																				item?.patient_accommodation_details?.private_room_detail?.room_name+'('+item?.patient_accommodation_details?.private_room_detail?.room_number+')'
																				: 'Not Available'
																			}`
																		}
																	</label>
																</td>
																<td>
																	<label className="appointment-list-text">
																		{item?.physician_info?.first_name}
																	</label>
																</td>
																<td >
																	<label className="appointment-list-text">
																		{item?.admitted_date &&
																			moment(new Date(item?.admitted_date)).format(
																			"DD/MM/YYYY"
																			)}
																	</label>
																</td>
																<td>
																		{
																			item?.admission_status == 'pending' ?
																			<button className='admit-btn'><GoPlusCircle className='fs-4'/> Admit</button> :
																			<span className='admitted-text'>Admitted</span>
																		}
																</td>
																<td>
																	<div className='btn' onClick={()=>handlePopup(item?.id)} ><BsInfoCircle className='fs-4'/> </div>
																</td>
																<td>
																	<div className='d-flex justify-content-center align-items-center'>
																		<div className='btn'><BsThreeDotsVertical className='fs-3' onClick={() => toggleDropdown(item.id)}/></div>
																		{activeDropdown === item.id && (
																			<div className="dropdown-menu1">
																				<button onClick={() => handleAction("discharge", item)} style={{color:"#F02F2F"}}>
																					<img src={dischargeImg} alt="" className="pe-2"/> Discharge
																				</button>
																				<button onClick={() => handleAction("consult", item)} style={{color:"#45A71E"}}>
																					<img src={consultImg} alt="" className="pe-2"/> Consult
																				</button>
																				<button onClick={() => handleAction("checkup", item)} style={{color:"#000000"}}>
																					<img src={checkupImg} alt="" className="pe-2"/> Checkup
																				</button>
																				<button onClick={() => handleAction("edit", item)} style={{color:"#0A2278"}}>
																					<img src={editImg} alt="" className="pe-2"/> Edit
																				</button>
																				<button onClick={() => handleAction("delete", item)} style={{color:"#CB0000"}}>
																					<img src={deleteImg} alt="" className="pe-2"/> Delete
																				</button>
																				<button onClick={() => handleAction("detail", item)} style={{color:"#5C09E1"}}>
																					<img src={detailImg} alt="" className="pe-2"/> Detail
																				</button>
																			</div>
																		)}
																	</div>
																</td>
															</tr>
														);
													})}
											</tbody>
										</>
									)}

								</table>
							</div>
						</TabPane>
					</Tab.Content>
				</div>
			</Tab.Container>
		</>
	);
};

export default Admission;
