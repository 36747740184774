import React, { useState } from 'react'
import InputTextBox from '../../../../../shared/form-components/form-text/InputTextBox'
import { Popover } from 'react-bootstrap'
import Calendar from 'react-calendar'
import moment from 'moment'
import InputBtnGroup from '../../../../../shared/form-components/form-text/InputBtnGroup'
import calender_icon from '../../../../../../assets/pictures/icons/calender-icon.png'
import DropDownBox from '../../../../../shared/form-components/dropdown/CustomDropDown'
const ExperienceForm = ({value,setValue,functions,experienceSubmit,experienceIndex}) => {
    const [showFromDateCalendar, setShowFromDateCalendar] = useState(false)
    const [showToDateCalendar, setShowToDateCalendar] = useState(false)
    const handleFromDateCalendar = (e) => {
        setValue?.setFromDate(e)
        setShowFromDateCalendar(false)
    }
    const handleToDateCalendar = (e) => {
        setValue?.setToDate(e)
        setShowToDateCalendar(false)
    }
    const fromDateCalendar = (
        <Popover
            className='calendar-popup-container'
        >
            <Calendar onChange={handleFromDateCalendar} />
        </Popover>
    )
    const toDateCalendar = (
        <Popover
            className='calendar-popup-container'
        >
            <Calendar onChange={handleToDateCalendar} />
        </Popover>
    )
    const handleSubmit = (e) => {
        e.preventDefault();
        experienceSubmit()
    };
    return (
        <div className="container-fluid mt-4 leave-form1 px-0">
            <form onSubmit={handleSubmit}>
                <div className="row g-3">

                    <div className="col-2 col-3">
                         <DropDownBox 
                            label={'Profession'}
                            containerClass={'col-12'}
                            addNew={true}
                            fluid={true}
                            search={true}
                            selection={true}
                            state={'profession'}
                            setNew={functions?.addPatientProperty}
                            options={value?.professionList}
                            setDataValue={setValue?.setProfession}
                            selectedValue={value?.profession}
                            />
                    </div>

                    <div className="col-2">
                        <InputTextBox
                                label={'Experience'}
                                containerClass={'col-12 mb-3'}
                                onChange={setValue?.setExperience}
                                defvalue={value?.experience}
                                // disabled={true}
                                // required={true}
                            />
                    </div>

                    <div className="col-2 col-3">
                            <DropDownBox 
                            label={'Location'}
                            containerClass={'col-12'}
                            addNew={true}
                            fluid={true}
                            search={true}
                            selection={true}
                            state={'location'}
                            setNew={functions?.addPatientProperty}
                            options={value?.cityList}
                            setDataValue={setValue?.setLocation}
                            selectedValue={value?.location}
                            />
                    </div>

                    <div className="col-2">
                    <InputBtnGroup
                            label={'From Date'}
                            required={true}
                            icon={
                                <img
                                    src={calender_icon}
                                    alt=""
                                    className='search-icon-img'
                                />
                            }
                            containerClass={'col-12 mb-3'}
                            defvalue={value?.fromDate && moment(new Date(value?.fromDate)).format("DD - MM - YYYY")}
                            setTogglePopup={setShowFromDateCalendar}
                            togglePopup={showFromDateCalendar}
                            popupContent={fromDateCalendar}
                            popupPlacement={"bottom"}
                        />
                    </div>

                    <div className="col-2">
                    <InputBtnGroup
                            label={'To Date'}
                            required={true}
                            icon={
                                <img
                                    src={calender_icon}
                                    alt=""
                                    className='search-icon-img'
                                />
                            }
                            containerClass={'col-12 mb-3'}
                            defvalue={value?.toDate && moment(new Date(value?.toDate)).format("DD - MM - YYYY")}
                            setTogglePopup={setShowToDateCalendar}
                            togglePopup={showToDateCalendar}
                            popupContent={toDateCalendar}
                            popupPlacement={"bottom"}
                        />
                    </div>

                    <div className="col-1 d-flex align-items-start justify-content-center submit-btn">
                        <button type="submit" className="btn custom-btn mt-4">
                            {/* {editID?'Edit':'ADD'} */}
                            {experienceIndex !== null ? 'Edit' : 'Add'}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default ExperienceForm