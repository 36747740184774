import React, { useState } from "react";
import InputTextBox from "../../../../shared/form-components/form-text/InputTextBox";
import InputBtnGroup from "../../../../shared/form-components/form-text/InputBtnGroup";
import calender_icon from '../../../../../assets/pictures/icons/calender-icon.png'
import DropDownBox from "../../../../shared/form-components/dropdown/CustomDropDown";
import GenderForm from "../../../reception/patient/registration/components/TabPanes/sub_components/GenderForm";
import TextAreaBox from "../../../../shared/form-components/form-textarea/TextAreaBox";
import { Form,Popover } from "react-bootstrap";
import InputUploadBox from "../../../../shared/form-components/form-upload/InputUploadBox";
import Calendar from 'react-calendar'
import moment from 'moment'
const StaffDetails = ({handleBack,handleNext,value,setValue,functions}) => {
    const [showDOBCalendar, setShowDOBCalendar] = useState(false)
    const [showJoinDateCalendar, setShowJoinDateCalendar] = useState(false)
    const handleDOBCalendar = (e) => {
        setValue?.setDob(e)
        setShowDOBCalendar(false)
    }
    const handleJoinDateCalendar = (e) => {
        setValue?.setJoinDate(e)
        setShowJoinDateCalendar(false)
    }
    const dOBCalendar = (
        <Popover
            className='calendar-popup-container'
        >
            <Calendar onChange={handleDOBCalendar} value={value?.dOB} />
        </Popover>
    )

    const joinDateCalendar = (
        <Popover
            className='calendar-popup-container'
        >
            <Calendar onChange={handleJoinDateCalendar} />
        </Popover>
    )
    // console.log("DistricList",value?.districtList)
    return (
        <div>
            <div className="row">
                <div className="col-9 page-title-label">
                    Staff Details
                </div>
            </div>
           
            <div className="row">
                <div className="col-6 left-split-container " >
                    <div className="col-12 d-flex justify-content-between">
                        <div className="col-6 p-1">
                            <InputTextBox
                                label={'Staff ID.'}
                                containerClass={'col-12 mb-3'}
                                onChange={setValue?.setStaffId}
                                defvalue={value?.staffId}
                                // disabled={true}
                                // required={true}
                            />
                        </div>
                        <div className="col-6 p-1">
                            <InputBtnGroup
                                label={'Joined Date'}
                                required={true}
                                icon={
                                    <img
                                        src={calender_icon}
                                        alt=""
                                        className='search-icon-img'
                                    />
                                }
                                containerClass={'col-12 mb-3'}
                                defvalue={value?.joinDate && moment(new Date(value?.joinDate)).format("DD-MM-YYYY")}
                                setTogglePopup={setShowJoinDateCalendar}
                                togglePopup={showJoinDateCalendar}
                                popupContent={joinDateCalendar}
                                popupPlacement={"bottom"}
                            />
                        </div>
                    </div>
                    <div className="col-12 p-1">
                        <InputTextBox
                            label={'Staff Name'}
                            required={true}
                            containerClass={'col-12 mb-3'}
                            defvalue={value?.staffName}
                            onChange={setValue?.setStaffName}
                            pattern="^[A-Za-z ]+$"
                            title="only letters"
                        />
                    </div>
                    <div className="col-12 d-flex justify-content-between">
                    <InputTextBox
                      label={'Email ID'}
                      type={'email'}
                      containerClass={'col-6 p-1 mb-3'}
                      onChange={setValue?.setEmailId}
                      defvalue={value?.emailId}
                      pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
                      title="Enter a valid email address"
                    />
                     <InputTextBox
                      label={'Mobile Number'}
                      containerClass={'col-6 p-1 mb-3'}
                      onChange={setValue?.setMobile}
                      defvalue={value?.mobile}
                      pattern="^[0-9]{10,12}$"
                      title="Enter valid mobile number"
                    />
                    </div>
                    <div className="col-12 d-flex justify-content-between">
                    <InputBtnGroup
                    label={'Date Of Birth'}
                    icon={
                        <img
                        src={calender_icon}
                        alt=""
                        className='search-icon-img'
                        />
                    }
                    containerClass={'col-6 p-1 mb-3'}
                    defvalue={value?.dob && moment(new Date(value?.dob)).format("DD-MM-YYYY")}
                    setTogglePopup={setShowDOBCalendar}
                    togglePopup={showDOBCalendar}
                    popupContent={dOBCalendar}
                    popupPlacement={"bottom"}
                    // disabled={true}
                    />
                     <GenderForm 
                    {...{value,setValue}} 
                     />
                    </div>
                    <div className="col-12 d-flex justify-content-between">
                    <DropDownBox 
                    label={'District'}
                    containerClass={'col-4 p-1 mb-3'}
                    addNew={true}
                    fluid={true}
                    search={true}
                    selection={true}
                    state={'district'}
                    setNew={functions?.addPatientProperty}
                    options={value?.districtList}
                    setDataValue={setValue?.setDistrict}
                    selectedValue={value?.district}
                    />
                    <DropDownBox 
                    label={'State'}
                    containerClass={'col-4 p-1 mb-3'}
                    addNew={true}
                    fluid={true}
                    search={true}
                    selection={true}
                    state={'state'}
                    setNew={functions?.addPatientProperty}
                    options={value?.stateList}
                    setDataValue={setValue?.setState}
                    selectedValue={value?.state}
                    />
                    <DropDownBox 
                    label={'Country'}
                    required={true}
                    containerClass={'col-4 p-1 mb-3'}
                    addNew={true}
                    fluid={true}
                    search={true}
                    selection={true}
                    state={'country'}
                    setNew={functions?.addPatientProperty}
                    options={value?.countryList}
                    setDataValue={setValue?.setCountry}
                    selectedValue={value?.country}
                    />
                    </div>
                    <div className="col-12 d-flex justify-content-between">
                        <DropDownBox
                        label={'City/Location'}
                        containerClass={'col-6 p-1 mb-3'}
                        addNew={true}
                        fluid={true}
                        search={true}
                        selection={true}
                        state={'location'}
                        setNew={functions?.addPatientProperty}
                        options={value?.cityList}
                        setDataValue={setValue?.setCity}
                        selectedValue={value?.city}
                        />
                        <DropDownBox 
                        label={'Pincode'}
                        containerClass={'col-6 p-1 mb-3'}
                        addNew={true}
                        fluid={true}
                        search={true}
                        selection={true}
                        state={'pin'}
                        setNew={functions?.addPatientProperty}
                        options={value?.pincodeList}
                        setDataValue={setValue?.setPincode}
                        selectedValue={value?.pincode}
                        />
                    </div>
                </div>
                <div className="col-6">
                    <div>
                    <TextAreaBox
                        label={'Address Line 1'}
                        rows={'2'}
                        required={true}
                        containerClass={'col-12 mb-3'}
                        onChange={setValue?.setAddress1}
                        defvalue={value?.address1}
                    />
                    <TextAreaBox
                        label={'Address Line 2'}
                        rows={'2'}
                        containerClass={'col-12 mb-3'}
                        onChange={setValue?.setAddress2}
                        defvalue={value?.address2}
                    />
                    </div>
                    <div>
                    <Form.Check
                        inline
                        type='checkbox'
                        className='input-check-control mb-3'
                        label='Set Username & Password'
                        onChange={() => setValue?.setUpass(!value?.upass)}
                        checked={value?.upass}
                    />
                    </div>
                    {value?.upass && <div className="col-12 d-flex justify-content-between">
                        <InputTextBox
                        label={'Username'}
                        type={'email'}
                        containerClass={'col-6 p-1 mb-3'}
                        onChange={setValue?.setUsername}
                        defvalue={value?.username}
                        //   pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
                        //   title="Enter a valid email address"
                        />
                        <InputTextBox
                        label={'Password'}
                        containerClass={'col-6 p-1 mb-3'}
                        onChange={setValue?.setPassword}
                        defvalue={value?.password}
                        //   pattern="^[0-9]{10,12}$"
                        //   title="Enter valid mobile number"
                        />
                    </div>}
                    <div className='col-12 row mx-0 px-3'>
                    <Form.Label className='label-text-content col-12 px-0'>
                        Upload Picture
                    </Form.Label>
                    <div className='physician-photo-upload col-9 py-2'>
                        <InputUploadBox
                            containerClass={''}
                            height={'11.25rem'}
                            bgColor={'#F1F1F1'}
                            handleFile={setValue?.setImage}
                            fileValue={value?.image}
                            crop={true}
                            id={'physician-profile-picture'}
                        />
                    </div>
                    <div className='d-flex flex-column align-items-center justify-content-center col-3'>
                        <div className="row">
                            <label htmlFor='physician-profile-picture'>
                                <div className='upload-browse-btn btn my-1'>
                                    Browse
                                </div>
                            </label>
                        </div>
                        <div className="row">
                            <div 
                            className='upload-browse-btn btn my-1' 
                            // onClick={()=>setValue?.setImage('')}
                            > 
                            Clear
                            </div>
                        </div>
                    </div>

                </div>
                </div>
            </div>
            <div className='container-fluid mt-4 p-4 ps-3'>
                <div className='co1-12 d-flex justify-content-end p-2'>
                    <div className='buttonviewdown' onClick={handleBack}>Back</div>
                    <div className='buttonviewdown'  onClick={handleNext}>Next</div>
                </div>
            </div>
        </div>
    );
};

export default StaffDetails;
