import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import './MultiInput.css'

const MultiInputBox = (props) => {
    const {
        containerClass,
        placeholder,
        label,
        required,
        icon,
        data,
        onAdd,
        onRemove,
        sizePad,
        disabled
    } = props
    const [focus, setFocus] = useState(false)
    const [content, setContent] = useState('')

    function handleKeyDown(e) {
        if (e.key !== 'Enter') return
        const value = e.target.value
        if (!value.trim()) return
        onAdd(content)
        e.target.value = ''
        setContent('')
    }

    const handleAddInput = (e) => {
        const value = content
        if (!value.trim()) return
        onAdd(content)
        setContent('')
    }

    function removeTag(item) {
        onRemove(item)
    }
    return (
        <Form.Group className={containerClass}>
            <Form.Label
                className='label-text-content'
            >
                {label && label}
                {required && ' *'}
            </Form.Label>
            <div className={`multi-input-container row mx-0 ${focus && 'highlight'}`}>
                <div className="multi-input-content col-11">
                    {data && data?.map((item, index) => (
                        <div className="multi-input-item" key={index}>
                            <div className="text col px-0">{item.value}</div>
                            <div className="close-btn px-0 ">
                                <div className="close" onClick={() => removeTag(item.id)}>+</div>
                            </div>
                        </div>
                    ))}
                    <input
                        onKeyDown={handleKeyDown}
                        onFocus={() => setFocus(true)}
                        onBlur={() => setFocus(false)}
                        onChange={(e) => setContent(e.target.value)}
                        value={content}
                        type="text"
                        className={`multi-input-box ${sizePad?'p-3':''}`}
                        placeholder={placeholder || 'Type Here ...'}
                        required={data.length>0?false:true}
                        disabled={disabled}
                    />
                </div>

                {icon &&
                    <div
                        className='col-1 d-flex justify-content-end px-0'
                    >
                        <div
                            onClick={handleAddInput}
                            className='icon-input-btn btn'
                        >
                            {icon}
                        </div>
                    </div>
                }
            </div>

        </Form.Group>
    )
}

export default MultiInputBox
