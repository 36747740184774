import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import useAxios from '../../hooks/useAxios'

const useStaffServices = () => {
    const axiosPrivate = useAxiosPrivate()
    const axios = useAxios()

    const postStaffAttendance = async(data)=>{
        const response =await axiosPrivate.post(`staff/`,data)
        return response.data
    }

    const postBulkUploadAttendance = async(data)=>{
        const response =await axiosPrivate.post(`staff/`,data)
        return response.data
    }

    const getAllStaffAttendance = async()=>{
        const response =await axiosPrivate.post(`staff/`)
        return response.data
    }

    return {
        getAllStaffAttendance,
        //POST REQUESTS
        postStaffAttendance,
        postBulkUploadAttendance
    }
}
export default useStaffServices