import React, { useEffect, useState } from "react";
import MultiInputBox from "../MultiInputBox";
import useAccountServices from "../../../../services/api-services/accountServices";
import useAccommodationServices from "../../../../services/api-services/accommodationServices";
import Swal from "sweetalert2";
import AccommodationDetails from "../AccommodationDetails";

const AddSuitRoom = ({ handleClosePrivateSuit }) => {
  const [suiteId, setSuiteId] = useState(0);
  const [fkSuiteId,setFkSuiteId] = useState(0);
  const [suiteSuiteData, setSuiteSuiteData] = useState({
      suite_name:"",
      suite_no:"",
      floor_no:"",
      rate_per_day:"",
      eligible_for_insurance:false
      });
      const {postSuteAddition} = useAccommodationServices()

//   api/v1/accommodation/suite/create/
// {
// "suite_name":"suite2",
// "suite_no":"2
// floor_no:2
// rate_per_day:3000
// eligible_for_insurance:true}



  const { getNewID } = useAccountServices()

  useEffect(() => {
		getSuiteId()
	}, [])

  useEffect(() => {
    getPrivateSuteRoomdData();
  }, []);

  const [allSuiteDetails,setAllSuiteDetails] = useState([])
  const getPrivateSuteRoomdData = async () => {
    try {
      const response = await getPrivateSuteRoom();
      if (response?.success) {
        setAllSuiteDetails(response?.data);
        console.log("##################PrivateSuteRoomdData",response?.data[0]);
      }
    } catch {}
  };
  const { 
    getPrivateSuteRoom,
    postAccommodationDetails,
    deleteAccommodationDetails 
  } = useAccommodationServices()

  const addAmentiesAvailable =async (value, accommodation_type, content_type,fk_private_suite) => {
    try {
      const data = JSON.stringify({
          content: value,
          fk_private_suite: fk_private_suite,
          accomodation_type: accommodation_type,
          content_type: content_type
      })

      console.log('accommodation details----sending_data------', data);
      const response = await postAccommodationDetails(data)
      if (response?.success) {
        getPrivateSuteRoomdData()
      }
      else {
  
      }
  } catch { }
  }
  const deleteAmenties = async (id, accommodation_type, content_type, fk_ward, fk_private_room) => {
    try {
      const response = await deleteAccommodationDetails(id)
    
      if (response?.success) {
        getPrivateSuteRoomdData()
      }
    } catch { }
    }
  const addInstructions = (e) => {

  }
  const deleteInstructions = (e) => {

  }

  const addOthers = (e) => {

  }
  const deleteOthers = (e) => {

  }


  const handleChangeSuteAddition = (e) => {
		const { name, value } = e.target;
		if (name === "eligible_for_insurance") {
			setSuiteSuiteData((prev) => ({
				...prev,
				[name]: !prev.eligible_for_insurance,
			}));
		} else {    
			setSuiteSuiteData((prev) => ({
				...prev,
				[name]: value,
			}));
		}
	};

  const getSuiteId = async () => {
		try {
			const response = await getNewID("suite_id")      
			if (response?.success) {
				setSuiteId(response?.data?.complete_id)
        setFkSuiteId(response?.data?.id)
			}
		} catch { }
	}

  console.log(suiteSuiteData,"======suiteSuiteData=========");

  const handleSubmitSuteAddition = async () => {

		try {
			const data = JSON.stringify(suiteSuiteData)
			const response = await postSuteAddition(data)
			if (response?.success) {
        setSuiteSuiteData({
          suite_name:"",
          suite_no:"",
          floor_no:"",
          rate_per_day:"",
          eligible_for_insurance:false
          })
          handleClosePrivateSuit()
				Swal.fire('', response?.message, 'success')
			}
			else {
				Swal.fire('', response?.message, 'error')
			}
		} catch (error) {
			console.log(error, "error=========");

		}
	};
  

  return (
    <div className="px-2">
      <div className="private-suit-container mb-4 row rounded-3  bg-light">
        <div className="col-8 h-100 ">
          <p className="private-suit-head d-flex align-items-center">
            Private Suite Addtion
          </p>
        </div>

        <div className="col-4 details-ward  d-flex align-items-center justify-content-end ">
          <button className="px-3" onClick={handleClosePrivateSuit}>
            {/* <HiOutlineExclamationCircle
              size={15}
              style={{ marginRight: "5PX" }}
            /> */}
            Cancel
          </button>
          <button className="px-4 ms-3" onClick={handleSubmitSuteAddition}>
            {/* <GoPlus size={18} style={{ marginRight: "5PX" }} /> */}
            Save
          </button>
        </div>
      </div>

      <div
        className="w-100 h-100 private-suit-container pb-3 mb-5"
        role="document"
      >
        <div className="modal-content  px-3">
          <div className="row gx-5 mt-4">
            <div className="col-md-6">
              <label htmlFor="" className="modal-label">
                Private suite ID
              </label>
              <div className="p-2 border bg-light rounded">
                <input type="text" name="suite_id" value={suiteId} className="w-100 h-100" readOnly />
              </div>
            </div>
            <div className="col-md-6">
              <label htmlFor="" className="modal-label">
                Private suite Name
              </label>
              <div className="p-2 border bg-light rounded ">
                <input type="text" name="suite_name" onChange={handleChangeSuteAddition} className="w-100 h-100" />
              </div>
            </div>
          </div>

          <div className="row gx-5 mt-3">
            <div className="col-md-6">
              <div className="row ">
                <div className="col-12">
                  <label htmlFor="" className="modal-label">
                  Suite No
                  </label>
                  <div className="p-2 border bg-light rounded">
                    <input type="text" value={suiteSuiteData.suite_no} name="suite_no" onChange={handleChangeSuteAddition} className="w-100 h-100" />
                  </div>
                </div>
                <div className="col-12 mt-3">
                  <label htmlFor="" className="modal-label">
                    Rate per day
                  </label>
                  <div className="p-2 border bg-light rounded">
                    <input type="text" value={suiteSuiteData.rate_per_day} name="rate_per_day" onChange={handleChangeSuteAddition} className="w-100 h-100" />
                  </div>
                </div>
                <div className="col-12 mt-3">
                  {/* <label htmlFor=""></label> */}
                  <div className="p-2  d-flex align-items-center ">
                    <input
                      type="checkbox"
                      //   onChange={handleChangeWardAddition}
                      //   checked={!addWard && wardDetails.eligible_for_insurance}
                      name="eligible_for_insurance"
                      className="me-2"
                      onChange={handleChangeSuteAddition}
                      checked={suiteSuiteData.eligible_for_insurance}
                    />
                    <label htmlFor="" className="modal-label">
                      Eligible for insurance
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
            {/* <div className="col-md-6"> */}
              <label htmlFor="" className="modal-label">
                Set Floor
              </label>
              <div className="p-2 border bg-light rounded">
                <input type="text" onChange={handleChangeSuteAddition} name="floor_no" className="w-100 h-100" />
              </div>
            {/* </div> */}
              <label htmlFor="" className="modal-label mt-3">
                Room Image
              </label>
              <div className="p-2  d-flex align-items-center">
                <div className="p-2 d-flex align-items-center">
                  <input
                    type="file"
                    id="file-upload"
                    style={{
                      display: "none",
                    }}
                  />
                  <label
                    htmlFor="file-upload"
                    className="d-flex align-items-center justify-content-center img-upload-suit"
                  >
                    Choose file
                  </label>
                </div>
                <div className="p-2 d-flex align-items-center">
                  <input
                    type="file"
                    id="file-upload"
                    style={{
                      display: "none",
                    }}
                  />
                  <label
                    htmlFor="file-upload"
                    className="d-flex align-items-center justify-content-center img-upload-suit"
                  >
                    Choose file
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className="row gx-5 mt-3 mb-4">
            <div className="col-md-6">
              <div className="row ">
                <div className="col-12">
                  {/* <label htmlFor="" className="modal-label">
                    Amenties Available
                  </label> */}
                  <div className="p-2 border bg-light rounded">
                    <MultiInputBox
                    label={"Available Amenities"}
                    data={[]}
                    onAdd={(value) =>
                      addAmentiesAvailable(
                        value,
                        "suite",
                        "amenity",
                        fkSuiteId,
                        // privateRoomDetailsID
                      )
                    }
                    onRemove={(id) =>
                      deleteAmenties(
                        id,
                        "suite",
                        "amenity",
                        fkSuiteId,
                        // privateRoomDetailsID
                      )
                    }
                    containerClass={"col-12 mb-3"}
                    icon={"+"}
                    // disabled={!isActive}
                  />
                  </div>
                </div>
                <div className="col-12 mt-2">
                  <label htmlFor="" className="modal-label">
                    Other Details
                  </label>
                  <div className="" style={{ height: "90%" }}>
                  <AccommodationDetails
                    data={[]}
                    onAdd={(value) =>
                      addInstructions(
                        value,
                        "suite",
                        "other",
                        fkSuiteId,
                        // privateRoomDetailsID
                      )
                    }
                    onRemove={(id) =>
                      deleteInstructions(
                        id,
                        "suite",
                        "other",
                        fkSuiteId,
                        // privateRoomDetailsID
                      )
                    }
                    iconCol={true}
                    // disabled={!isActive}
                  />
                </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <label htmlFor="" className="modal-label">
                Instructions
              </label>
              <div className="" style={{ height: "90%" }}>
                  <AccommodationDetails
                    data={[]}
                    onAdd={(value) =>
                      addInstructions(
                        value,
                        "suite",
                        "instruction",
                        fkSuiteId,
                        // privateRoomDetailsID
                      )
                    }
                    onRemove={(id) =>
                      deleteInstructions(
                        id,
                        "suite",
                        "instruction",
                        fkSuiteId,
                        // privateRoomDetailsID
                      )
                    }
                    iconCol={true}
                    // disabled={!isActive}
                  />
                </div>
            </div>
          </div>
         
        </div>
      </div>
    </div>
  );
};

export default AddSuitRoom;
