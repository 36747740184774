import React from 'react'

const DischargePopupDetails = () => {
  return (
    <div className='adm-popup-height'>
      DischargePopupDetails
    </div>
  )
}

export default DischargePopupDetails