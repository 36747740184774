import "./StaffLists.css"
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Nav, Tab, TabPane } from "react-bootstrap";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import FilterSortPagination from "../reception/patient/patient-list/components/FilterSortPagination";
import HashLoader from "react-spinners/ClockLoader";
import Pagination from "../reception/patient/patient-list/components/Pagination";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import StaffPopup from "./components/StaffPopup";
import patient_details from "../../../assets/pictures/icons/patient-details-icon.png";
import physician_add from "../../../assets/pictures/icons/physician-add-icon.png";
import { Modal } from "antd";
import useAccountServices from "../../services/api-services/accountServices";
// import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
// import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { GrCheckmark } from "react-icons/gr";
import { FaXmark } from "react-icons/fa6";

const StaffLists = () => {
    // const [patientList, setPatientList] = useState([]);
    // const [singlePatient, setSinglePatient] = useState({});
    // const [selectedPatientId, setSelectedPatientId] = useState(null);
    // const [pagination, setPagination] = useState({
    //   page: 1,
    //   item: 10,
    //   totalPages: 1,
    // });
    // const [sortBy, setSortBy] = useState("created_at_desc");
    // const [search, setSearch] = useState("");
    // const [filtered, setFiltered] = useState("");
    // const [selectedDate, setSelectedDate] = useState("");
    // const [fromDate, setFromDate] = useState(""); // New state for range start date
    // const [toDate, setToDate] = useState(""); // New state for range end date
    // const[response,setResponse] = useState(false)
    const [loading,setLoading] = useState(false)
  
    const navigate = useNavigate();
  
    // const {
    //   getPatientList,
    //   getSinglePatient,
    //   getSortFilterPagination,
    //   deletePatientDetail,
    // } = usePatientServices();
  
    const [open, setOpen] = useState(false);

    const handleOpen = async (staffId) => {
    //   setSelectedPatientId(staffId);
    //   await getSinglePatientDetail(patientId);
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    //   setSelectedPatientId(null); // Reset ID when the modal is closed
    };
  
    // useEffect(() => {
    //   getPatientListData();
    // }, []);
  
    // useEffect(() => {
    //   getPatientListData();
    // }, [
    //   pagination.page,
    //   sortBy,
    //   search,
    //   filtered,
    //   selectedDate,
    //   fromDate,
    //   toDate,
    // ]); // Trigger API call whenever the page changes
  
    // console.log(filtered);
  
    // const handleSortChange = (newSortBy) => {
    //   setSortBy(newSortBy); // Update the sort_by state
    // };
    
    // const getPatientListData = async () => {
    //   setLoading(true); // Start loading
    //   try {
    //     const response = await getSortFilterPagination({
    //       item: pagination.item,
    //       page: pagination.page,
    //       sort_by: sortBy,
    //       search: search,
    //       type: filtered,
    //       date: selectedDate,
    //       from_date: fromDate,
    //       to_date: toDate,
    //     });
  
    //     if (response?.success) {
    //       setPatientList(response?.data?.patients);
    //       setPagination((prev) => ({
    //         ...prev,
    //         totalPages: response?.data?.total_page,
    //       }));
    //     } else {
    //       Swal.fire("", response?.message, "error");
    //     }
    //   } catch (error) {
    //     console.log(error);
    //   } finally {
    //     setLoading(false); // Stop loading
    //   }
    // };
    // console.log("API Call with Date:", selectedDate);
    // const handlePageChange = (newPage) => {
    //   setPagination((prev) => ({
    //     ...prev,
    //     page: newPage,
    //   }));
    // };
  
    // const getSinglePatientDetail = async (ID) => {
    //   if (!ID) return;
    //   try {
    //     const response = await getSinglePatient(ID);
    //     console.log("single patient", response);
    //     if (response?.success) {
    //       const data = response?.data;
  
    //       // Mapping the data to your state names
    //       const mappedData = {
    //         bloodGroupList: data?.blood_group || "",
    //         maritalStatusList: data?.marital_status || "",
    //         mobileNumber: data?.mobile || "",
    //         firstName: data?.first_name || "",
    //         lastName: data?.last_name || "",
    //         dOB: data?.dob || "",
    //         ageDate: data?.age_day || "",
    //         ageMonth: data?.age_month || "",
    //         ageYear: data?.age_year || "",
    //         gender: data?.gender || "",
    //         email: data?.email || "",
    //         uIN: data?.UIN || "",
    //         address1: data?.address_line_1 || "",
    //         address2: data?.address_line_2 || "",
    //         city: data?.city || "",
    //         pincode: data?.pincode || "",
    //         state: data?.state || "",
    //         district: data?.district || "",
    //         country: data?.country || "",
    //         admDate: data?.medical_record?.[0]?.admission_date || "",
    //         admTime: data?.medical_record?.[0]?.admission_tme || "",
    //         fileNum: data?.medical_record?.[0]?.file_no || "",
    //         casualty: data?.medical_record?.[0]?.casualty || false,
    //         patientType: data?.patient_type || "",
    //         refferedType: data?.medical_record?.[0]?.referred_type || "",
    //         emgContName: data?.emergency_person_name || "",
    //         emgContNumber: data?.emergency_contact || "",
    //         emgContRelation: data?.relation_with_patient || "",
    //         notes: data?.medical_record?.[0]?.remark || "",
    //         medicalIssue: data?.medical_issue || [],
    //         // Document data
    //         docFile: data?.identification_record?.passport_file || "",
    //         validityPeriod: data?.identification_record?.validity_period || "",
    //         documentValidity: data?.patient_doc_record?.[0]?.validity_upto || "",
    //         documentList: data?.patient_doc_record?.[0]?.documents || [],
    //         passportNo: data?.identification_record?.passport_number || "",
    //         issuedDate: data?.patient_doc_record[0]?.issued_date,
    //         passExpiry: data?.identification_record?.date_of_expiry || "",
    //         visaType: data?.identification_record?.visa_type || "",
    //         visitPurpose: data?.identification_record?.visit_purpose || "",
    //         Remarks: data?.identification_record?.remarks || "",
    //         visaDoc: data?.identification_record?.visa_file || "",
    //         // Health record data
    //         patientHeight: data?.health_record?.height || "",
    //         patientWeight: data?.health_record?.weight || "",
    //         bloodPressure: data?.health_record?.blood_pressure || "",
    //         pulse: data?.health_record?.pulse || "",
    //         respiration: data?.health_record?.respiration || "",
    //         temperature: data?.health_record?.temperature || "",
    //         // Additional data
    //         treatmentHistory: data?.treatment_history || [],
    //         allergyList: data?.allergy_issues || [],
    //       };  
    //       // Updating the state
    //       setSinglePatient(mappedData);
    //     } else {
    //       Swal.fire("", response?.message, "error");
    //     }
    //   } catch (error) {
    //     console.log(error);
    //   }
    // };
  
    // console.log("after", singlePatient);
  
    // useEffect(() => {
    //   if (selectedPatientId) {
    //     getSinglePatientDetail(selectedPatientId);
    //   }
    // }, [selectedPatientId]);
  
    // const filterOptions = [
    //   { label: "Native", value: "native" },
    //   { label: "Foriegner", value: "foriegner" },
    //   // { label: "Register Date", value: "register date" },
    // ];
  
    // const sortOptions = [
    //   {
    //     label: (
    //       <>
    //         Patient Id
    //         <span className="ps-2">
    //           <ArrowUpwardIcon />
    //         </span>
    //       </>
    //     ),
    //     value: "patient_id_asc",
    //   },
    //   {
    //     label: (
    //       <>
    //         Patient Id
    //         <span className="ps-2">
    //           <ArrowDownwardIcon />
    //         </span>
    //       </>
    //     ),
    //     value: "patient_id_desc",
    //   },
    //   {
    //     label: <>Name : A-Z</>,
    //     value: "first_name_asc",
    //   },
    //   {
    //     label: <>Name : Z-A</>,
    //     value: "first_name_desc",
    //   },
    //   {
    //     label: (
    //       <>
    //         Reg Date
    //         <span className="ps-2">
    //           <ArrowUpwardIcon />
    //         </span>
    //       </>
    //     ),
    //     value: "created_at_asc",
    //   },
    //   {
    //     label: (
    //       <>
    //         Reg Date
    //         <span className="ps-2">
    //           <ArrowDownwardIcon />
    //         </span>
    //       </>
    //     ),
    //     value: "created_at_desc",
    //   },
    // ];
  
    // const handleFilterChange = (filter) => {
    //   console.log("Filter selected:", filter);
    //   setFiltered(filter); // Update the filtered state with the selected filter
    //   setFromDate("");
    //   setToDate("");
    //   setSelectedDate("");
    // };
  
    // const handleSearchChange = (event) => {
    //   const query = event.target.value;
    //   console.log("Search query:", query);
    //   setSearch(query);
    //   // Implement search logic here
    // };
  
    // const handleDateChange = (date) => {
    //   if (date) {
    //     const formattedDate = moment(date).format("YYYY-MM-DD");
    //     console.log("Formatted Date:", formattedDate);
    //     setSelectedDate(formattedDate);
    //     setFiltered("");
    //     setFromDate("");
    //     setToDate("");
    //   } else {
    //     setSelectedDate(""); // Clear the selected date if no date is selected
    //   }
    // };
  
    // const handleRangeDateChange = (dates) => {
    //   if (dates && dates.length === 2) {
    //     const [start, end] = dates;
    //     setFromDate(moment(start).format("YYYY-MM-DD")); // Update the fromDate state
    //     setToDate(moment(end).format("YYYY-MM-DD")); // Update the toDate state
    //     setSelectedDate(""); // Clear the selected date
    //     setFiltered(""); // Clear any selected filter
    //   } else {
    //     setFromDate("");
    //     setToDate("");
    //   }
    // };
  
    // const handleDeleteWithConfirmation = (data) => {
    //   Swal.fire({
    //     title: "Are you sure?",
    //     text: "You won't be able to revert this!",
    //     icon: "warning",
    //     showCancelButton: true,
    //     confirmButtonColor: "#3085d6",
    //     cancelButtonColor: "#d33",
    //     confirmButtonText: "Yes, delete it!",
    //   }).then(async (result) => {
    //     if (result.isConfirmed) {
    //       const { value: password } = await Swal.fire({
    //         title: "Enter your password",
    //         input: "password",
    //         inputPlaceholder: "Enter your password ...",
    //         inputAttributes: {
    //           autocapitalize: "off",
    //           autocorrect: "off",
    //         },
    //         showCancelButton: true,
    //       });
  
    //       if (password) {
    //         try {
    //           const response = await deletePatientDetail(data.id, password);
    //           if (response?.success) {
    //             Swal.fire("Deleted!", "Patient has been deleted.", "success");
    //             getPatientListData();
    //           } else {
    //             Swal.fire(
    //               "Invalid Password!",
    //               "The password you entered is incorrect.",
    //               "error"
    //             );
    //           }
    //         } catch (e) {
    //           Swal.fire(
    //             "Error!",
    //             "An error occurred while trying to delete the patient.",
    //             "error"
    //           );
    //           console.error(e);
    //         }
    //       }
    //     }
    //   });
    // };
  
    // const handlePatientEdit = (data) => {
    //   if (data?.id) {
    //     navigate("/patient-register", {
    //       state: {
    //         PatientEditData: data,
    //       },
    //     });
    //   }
    // };
  
    // const onhandleReset = async () => {
    //   // Reset all states to their initial values
    //   setPagination({
    //     page: 1,
    //     item: 10,
    //     totalPages: 1,
    //   });
    //   setSortBy("");
    //   setSearch("");
    //   setFiltered("");
    //   setSelectedDate("");
    //   setFromDate("");
    //   setToDate("");
    //   setResponse(true)
    //   // Fetch the initial patient list data
    //   getPatientListData();
    // };
    const [staffList,setStaffList]=useState([])
    const  {getStaffList,deleteStaffDetail} = useAccountServices()
    const handleStaffList = async()=>{
     try{
      const response = await getStaffList()
      if (response.success){
        setStaffList(response.data.staffs)
      }
     }catch(e){
      console.log(e)
     }
    }

    useEffect(()=>{
      handleStaffList()
    },[])

    // const handleStaffEdit = (item) =>{

    // }
    const handleStaffEdit = (data) => {
      if (data?.id) {
        navigate("/staff-register", {
          state: {
            StaffEditData: data,
          },
        });
      }
    };

    const handleDeleteWithConfirmation = (data) => {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const { value: password } = await Swal.fire({
            title: "Enter your password",
            input: "password",
            inputPlaceholder: "Enter your password ...",
            inputAttributes: {
              autocapitalize: "off",
              autocorrect: "off",
            },
            showCancelButton: true,
          });
  
          if (password) {
            try {
              const response = await deleteStaffDetail(
                data.fk_user,
                password
              );
              if (response?.success) {
                Swal.fire("Deleted!", "Staff has been deleted.", "success");
                handleStaffList();
              } else {
                Swal.fire(
                  "Invalid Password!",
                  "The password you entered is incorrect.",
                  "error"
                );
              }
            } catch (e) {
              Swal.fire(
                "Error!",
                "An error occurred while trying to delete the staff.",
                "error"
              );
              console.error(e);
            }
          }
        }
      });
    };

    return <>
      <div className="patientlist">
        <Tab.Container activeKey={"tab1"}>
          <div className="col-12">
            <Nav className="tabpane-container">
              <Nav.Item className="content">
                <Nav.Link className="tabpane-item" eventKey="tab1">
                  <img src={patient_details} className="details-icon" alt="" />
                  &nbsp; Staff List
                </Nav.Link>
              </Nav.Item>
              <div className="d-flex justify-content-end col pe-3">
                <div
                  className="tabpane-extra-btns"
                onClick={() => navigate("/staff-register")}
                >
                  <img
                    src={physician_add}
                    className="physician-add-icon"
                    alt=""
                  />
                  &nbsp;&nbsp; Add Staff
                </div>
              </div>
            </Nav>
          </div>
          {/* <FilterSortPagination
            // filterOptions={filterOptions}
            // sortOptions={sortOptions}
            // onFilterSelect={handleFilterChange}
            // onSortSelect={handleSortChange}
            // onSearchChange={handleSearchChange}
            // onPageChange={handlePageChange}
            // pagination={pagination}
            // handleDateChange={handleDateChange} // Ensure this is passed down
            // handleRangeDateChange={handleRangeDateChange} // Date range
            // handleReset={onhandleReset}
            // response={response}
            // setResponse={setResponse}
            // search={search}
          /> */}
          <div className="patient-list-container">
            <Tab.Content className="patient-table-container container-white">
              <TabPane eventKey="tab1" className="">
                <table className="table patient-list-table d-none d-sm-table">
                  <thead className="patient-list-table-head">
                    <tr>
                      <th>SL No</th>
                      <th>Staff ID</th>
                      <th>Staff Name</th>
                      <th>Mobile Number</th>
                      <th>Designation</th>
                      <th>Has Login</th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  {loading ? (
                <tbody>
                <tr>
                  <td colSpan="7">
                    <div className="d-flex justify-content-center align-items-center loader-container">
                      <HashLoader color="#e3b075" size={100} />
                    </div>
                  </td>
                </tr>
              </tbody>
              ) :<>
              <tbody className="patient-list-table-body" style={{objectFit:"contain"}} >
                    {staffList &&
                      staffList.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{index+1}</td>
                            <td>{item?.user_id}</td>
                            <td>
                              <button
                                onClick={() => handleOpen(item?.id)}
                                className="text-uppercase text-wrap patienttextdata"
                              >
                                {item?.user_data?.first_name}
                              </button>
                            </td>
                            <td>{item?.user_data?.mobile}</td>
                            <td>
                              {item?.payscale?.designation}
                            </td>
                            <td style={{paddingLeft:"35px"}}>
                              {item?.user_data?.is_active?<GrCheckmark />:<FaXmark />}
                            </td>
                         
                            <td>
                              <span
                                className="edit-patient"
                                onClick={() => handleStaffEdit(item)}
                              >
                                <EditOutlinedIcon size={18} />
                              </span>
                            </td>
                            <td>
                              <span
                                className="delete-patient"
                                onClick={() => handleDeleteWithConfirmation(item)}
                              >
                                <DeleteOutlineIcon size={18} />
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody></> }
                  
                </table>
                {/* 576px phone responsive */}
                {/* <div className="d-sm-none">
                  {patientList &&
                    patientList.map((item, index) => (
                      <div
                        key={index}
                        className="card mb-1 pb-0 mt-0 overflow-auto h-auto"
                      >
                        <div className="card-body">
                          <h5 className="card-title text-warning">
                            {item?.patient_id}
                          </h5>
                          <p className="card-text">
                            <strong>Name:</strong>{" "}
                            <span className="font-weight-bold">
                              {item?.full_name}
                            </span>
                          </p>
                          <p className="card-text">
                            <strong>Phone Number:</strong> {item?.mobile}
                          </p>
                          <p className="card-text">
                            <strong>Registered Date:</strong>
                            {item?.medical_record?.admission_date &&
                              moment(
                                new Date(item?.medical_record?.admission_date)
                              ).format("DD/MM/YYYY")}
                          </p>
                          <div className="d-flex justify-content-between">
                            <p className="card-text mb-0">
                              <strong>Last Visit:</strong>{" "}
                              {item?.updated_at &&
                                moment(new Date(item?.updated_at)).format(
                                  "DD/MM/YYYY"
                                )}
                            </p>
                            <p className="card-text mb-0">
                              <strong>File Number:</strong>{" "}
                              {item?.medical_record?.file_no}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                </div> */}
              </TabPane>
            </Tab.Content>
          </div>
        </Tab.Container>
        <Modal
          open={open}
          onCancel={handleClose}
          footer={null} // Remove the default footer (OK and Cancel buttons)
          className="custom-modal" // Add a custom class for further styling
          width={1000}
        >
          <StaffPopup/>
        </Modal>
      </div>
      
      <div className="d-block d-md-none pb-2 d-flex justify-content-end">
      {/* <Pagination
            // filterOptions={filterOptions}
            // sortOptions={sortOptions}
            // onFilterSelect={handleFilterChange}
            // onSortSelect={handleSortChange}
            // onSearchChange={handleSearchChange}
            // onPageChange={handlePageChange}
            // pagination={pagination}
            // handleDateChange={handleDateChange} // Ensure this is passed down
            // handleRangeDateChange={handleRangeDateChange} // Date range
            // handleReset={onhandleReset}
            // response={response}
            // setResponse={setResponse}
            // search={search}
          /> */}
      </div>
    </>
  };
  
export default StaffLists