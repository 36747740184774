import React, { useEffect, useState } from 'react'
import ExperienceForm from './subcomponents/ExperienceForm'
import ExperienceList from './subcomponents/ExperienceList'

const ExperienceDetails = ({handleBack,handleNext,value,setValue,functions,handleSubmit}) => {
    const [experienceIndex, setExperienceIndex] = useState(null);

    // Load data from local storage on initial render
    useEffect(() => {
        const savedExperiences = JSON.parse(localStorage.getItem('experiences'));
        if (savedExperiences) {
            setValue?.setExperiences(savedExperiences);
        }
      }, []);
    
    // Save data to local storage whenever `experiences` changes
    useEffect(() => {
    localStorage.setItem('experiences', JSON.stringify(value?.experiences));
    }, [value?.experiences]);


    const handleExperienceSubmit = ()=>{
        const newExperience={
            id:value.expId,
            fk_profession: value.profession,
            experience: value.experience,
            fk_location: value.location,
            from_date: value.fromDate,
            to_date: value.toDate,
          };
        if (experienceIndex !== null) {
        // Update existing experience
        const updatedExperiences = value?.experiences.map((experience, index) =>
            index === experienceIndex ?newExperience : experience
        );
       
        setValue?.setExperiences(updatedExperiences);
        setExperienceIndex(null);
        } else {
        // Add new experience
        setValue?.setExperiences([...value?.experiences, newExperience]);
        }
         setValue?.setExpId('');
         setValue?.setProfession('');
         setValue?.setExperience('');
         setValue?.setLocation('');
         setValue?.setFromDate('');
         setValue?.setToDate('');
    }

    const handleEdit = (index) => {
        setExperienceIndex(index);
        value?.experiences[index].fk_profession ? setValue?.setExpId(value?.experiences[index].id) : setValue?.setExpId('');
        setValue?.setProfession(value?.experiences[index].fk_profession);
        setValue?.setExperience(value?.experiences[index].experience);
        setValue?.setLocation(value?.experiences[index].fk_location);
        setValue?.setFromDate(value?.experiences[index].from_date);
        setValue?.setToDate(value?.experiences[index].to_date);
      };
    
      const handleDelete = (index) => {
        const updatedExperiences = value?.experiences.filter((_, i) => i !== index);
        setValue?.setExperiences(updatedExperiences);
      };

  return (
    <div>
        <div className="row">
                <div className="col-9 page-title-label">
                    Experience Details
                </div>
                <div>
                    <ExperienceForm  {...{value,setValue,functions,experienceIndex}} experienceSubmit={handleExperienceSubmit}/>
                </div>
                <div>
                    <ExperienceList {...{value,setValue,functions,handleEdit,handleDelete}}  allExperienceList={value?.experiences}/>
                </div>
        </div>
        <div className='container-fluid mt-4 p-4 ps-3'>
            <div className='co1-12 d-flex justify-content-end p-2'>
                <div className='buttonviewdown' onClick={handleBack}>Back</div>
                <div className='buttonviewdown'  onClick={handleSubmit}>Save</div>
            </div>
        </div>
    </div>
  )
}

export default ExperienceDetails