import React, { useEffect, useState } from "react";
import {
  Button,
} from "react-bootstrap";

const Pagination = ({
  onPageChange,
  pagination
}) => {

  return (
    <div className="d-flex flex-wrap align-items-center justify-content-between p-0 px-0 mt-2 mb-2">
      {/* Pagination */}
      <div className="pagination-container d-flex justify-content-center align-items-center pe-5">

        <Button
          variant="light"
          className="mx-1"
          onClick={() => onPageChange(pagination.page - 1)}
          disabled={pagination.page === 1}
        >
          &laquo; Previous
        </Button>
        <span className="pagination-info mx-2">
          {pagination.page.toString().padStart(2, "0")} of{" "}
          {pagination.totalPages.toString().padStart(2, "0")}
        </span>
        <Button
          variant="warning"
          className="mx-1"
          onClick={() => onPageChange(pagination.page + 1)}
          disabled={pagination.page === pagination.totalPages}
        >
          Next &raquo;
        </Button>
      </div>
    </div>
  );
};

export default Pagination;

