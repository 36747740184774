import React, { useState } from 'react'
import './AdmPopups.css'
import PatientDetails from '../../physician/consultation/components/tabpanes/PatientDetails'
import DiagnosisDetailsTransfer from '../../physician/consultation/components/transferPopup/components/DiagnosisDetailsTransfer'
import DischargePopupDetails from './components/DischargePopupDetails'
import AccomodationPopupDetails from './components/AccomodationPopupDetails'

const AdmPopups = ({admPopPage,patientDataDetails,ashtaChoice,vitalTypes}) => {
  console.log("###################################",patientDataDetails)
  const[activeBtn,setActiveBtn] = useState("patient")
  const handleActiveBtn = (name)=>{
    setActiveBtn(name)
  }
  return (
      <div>
        <div className='col-12 p-1 d-flex header-popup'>
          <div className={`col popup-btn rounded-3 ${activeBtn==="patient"?'activebtn':''}`} onClick={()=>handleActiveBtn("patient")}>Patient Information</div>
          <div className={`col popup-btn rounded-3 ${activeBtn==="diagnosis"?'activebtn':''}`} onClick={()=>handleActiveBtn("diagnosis")}>Primary Diagnosis</div>
          {admPopPage != "admission_request" && <div className={`col popup-btn rounded-3 ${activeBtn==="accomodation"?'activebtn':''}`} onClick={()=>handleActiveBtn("accomodation")}>Accomodation Details</div>}
          {admPopPage == "discharge" && <div className={`col popup-btn rounded-3 ${activeBtn==="discharge"?'activebtn':''}`} onClick={()=>handleActiveBtn("discharge")}>Discharge Details</div>}
        </div>
        <div className=''>
          {activeBtn==="patient" && <PatientDetails patientDetails={patientDataDetails?.patient_basic_info} lookChange={true} borderChange={true}/>}
          {activeBtn==="diagnosis" && <DiagnosisDetailsTransfer diagnosisInputDisable={true} patientData={patientDataDetails} lookChange={true} ashtaChoice={ashtaChoice} vitalTypes={vitalTypes}/>}
          {activeBtn==="accomodation" && admPopPage != "admission_request" && <div><AccomodationPopupDetails patientData={patientDataDetails?.IPPatient_accommodation}/></div>}
          {activeBtn==="discharge" && admPopPage == "discharge" && <DischargePopupDetails/>}
        </div>
      </div>
  )
}

export default AdmPopups