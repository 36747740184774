import React, { useEffect, useState } from 'react'
import StaffAttendanceDetails from './components/StaffAttendanceDetails';
import useStaffServices from '../../services/api-services/staffServices';
import useAccountServices from '../../services/api-services/accountServices';
import "./Attendance.css"
const Attendence = () => {
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth());
  const [startDate, setStartDate] = useState(new Date(year, month+1, 1).toDateString().slice(8, 10));
  const [endDate, setEndDate] = useState(new Date(year, month, 0).toDateString().slice(8, 10));


  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const [allStaff, setAllStaff] = useState([]);

  const { getAllAttendenceList } = useAccountServices();

  const getData = async () => {
    try {
      const paramsToReport = {
        from_date:  year+'-'+((parseInt(month)+1)<10?'0'+(parseInt(month)+1).toString(): (parseInt(month)+1).toString() )+'-'+startDate,
        to_date: year+'-'+((parseInt(month)+1)<10?'0'+(parseInt(month)+1).toString(): (parseInt(month)+1).toString() )+'-'+endDate,
      };
      console.log("#######",paramsToReport)
      const response = await getAllAttendenceList(paramsToReport);
      if (response?.success) {
        console.log("get all",response.data);
        setAllStaff(response.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getData();
  }, [startDate,endDate,month,year]);
  return (
    <div className="item_add zoom-responsive-view-attendance">
      <StaffAttendanceDetails
        {...{
          startDate,
          endDate,
          months,
          month,
          setMonth,
          year,
          setYear,
          allStaff,
          getData,
          setAllStaff,
          setStartDate,
          setEndDate,
        }}
      />
    </div>
  )
}

export default Attendence