import React, { useEffect, useState } from 'react'
import { Form, Tab, TabPane,Nav } from 'react-bootstrap';
import "./Ipadmissions.css";
import useInpatientServices from "../../../../src/app/services/api-services/inpatientServices";
import moment from "moment";
import Swal from "sweetalert2";

import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import { MdOutlineModeEditOutline } from "react-icons/md";
import HashLoader from "react-spinners/ClockLoader";
import { BsThreeDotsVertical } from "react-icons/bs";
import Admission from './Admission';
import AdmissionRequest from './AdmissionRequest';
import AdmPopups from './AdmPopupTabs/AdmPopups';
import { Modal } from "react-bootstrap";
import DischargeList from './DischargeList';
import useConsultationServices from '../../services/api-services/consultationServices';
// import AdmissionPagination from '../reception/patient/patient-list/components/AdmissionPagination';


const Ipadmissions = () => {
  const [IPAdmissionList, setIPAdmissionList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isMd, setIsMd] = useState(window.innerWidth >= 768);
  const [activeDE, setActiveDE] = useState(false);
  const [filterType, setFilterType] = useState("");
  const [status, setStatus] = useState("admitted");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const today = moment();
  const [sortBy, setSortBy] = useState("");
  const [search, setSearch] = useState("");
  const [filtered, setFiltered] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [fromDate, setFromDate] = useState(""); // New state for range start date
  const [toDate, setToDate] = useState(""); // New state for range end date
  const [appointmentList, setAppointmentList] = useState();
  const [activeRequest, setActiveRequest] = useState("tab1");
  const [admPopup, setAdmPopup] = useState(false);
  const [admPopPage,setPopPage] = useState('');
  const [pagination, setPagination] = useState({
    page: 1,
    item: 100,
    totalPages: 1,
  });


  const {
    getIPAdmissionList
  } = useInpatientServices();

  const getInpatientListData = async (status) => {
    setLoading(true); // Start loading
    try {
      const response = await getIPAdmissionList({
        item: pagination.item,
        page: pagination.page,
        sort_by: sortBy,
        search: search,
        type: filtered,
        from_date: fromDate, // Send fromDate to API
        to_date: toDate, // Send toDate to API
        // status: filterType,
        status: status,
        date: selectedDate,
        // accessToken: localStorage.getItem("accessToken"),
      });

      if (response?.success) {
        setIPAdmissionList(response?.data?.patients);
        setPagination((prev) => ({
          ...prev,
          totalPages: response?.data?.total_page,
        }));
      } else {
        Swal.fire("", response?.message, "error");
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  useEffect(() => {
      const handleResize = () => {
        if (window.innerWidth >= 768) {
          setIsMd(true);
        } else {
          setIsMd(false);
        }
      };

      // Add the event listener
      window.addEventListener('resize', handleResize)
  
      // Clean up the event listener on component unmount
      return () => window.removeEventListener('resize', handleResize);
    },[])

    useEffect(() => {
        const handleResize = () => {
          if (window.innerWidth >= 768) {
            setIsMd(true);
          } else {
            setIsMd(false);
          }
        };
    
        // Add the event listener
        window.addEventListener('resize', handleResize);
    
        // Clean up the event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
      }, []);


  // State to track the active tab
  const [activeTab, setActiveTab] = useState("tab1");

  // Function to change the active tab
  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setActiveRequest(tab)
  };
  const {getIpPatientDetails} = useInpatientServices();
  const [patientDataDetails,setPatientDataDetails]=useState();
  const handleGetIpDetails = async(id) =>{
    try{
      getAshtaChoices()
      getVitalTypeDetails()
      const response = await getIpPatientDetails(id)
      if (response?.success){
        setPatientDataDetails(response?.data)
        Swal.fire({
          icon: 'success',          
          title: response.message,  
          toast: true,
          position: 'top-end',
          showConfirmButton: false, 
          timer: 2000,
          timerProgressBar: true, 
          customClass: {
            popup: 'colored-toast'
          },
          background: '#f0f9eb', 
          showClass: { 
            popup: 'slide-in-right'
          },
          hideClass: { 
            popup: 'slide-out-left'
          },
        });
      }else{
        Swal.fire("Error",response.message,"error")
      }

    }catch(e){

    }
     
  };
  const [ashtaChoice,setAshtaChoice] = useState([])
  const [vitalTypes,setVitalTypes] = useState([])
  const{getAshtaChoice,getVitalTypes} = useConsultationServices()
  const getAshtaChoices = async() => {
      try{
          const response = await getAshtaChoice()
          if (response?.success){
              // console.log(response?.data?.types_choices)
              setAshtaChoice(response?.data?.types_choices)
          }
      }catch(err){
          console.log(err?.response)
      }
  }
  const getVitalTypeDetails = async ()=>{
      try{
          const response= await getVitalTypes()
          if(response?.success){
              setVitalTypes(response?.data?.filter((item)=>item.active===true))
          }
      }catch(err){
          console.log(err.response)
      }
  }
    useEffect(() => {
    getInpatientListData(status);
  }, [
    status,
    pagination.page,
    sortBy,
    search,
    filtered,
    selectedDate,
    fromDate,
    toDate,
    filterType,
  ]);

  return (
    <>
    <div className="tabs-container">
      {/* Tab headers */}
      <div className="tabs p-2">
        <button
          className={`${activeTab === "tab1" ? "active" : ""} ${activeRequest=="tab1" && "active-request" }`}
          onClick={() =>handleTabClick("tab1")}
        >
          Admission
        </button>
        <button
          className={`${activeTab === "tab2" ? "active" : ""} ${activeRequest=="tab2" && "active-request"}`}
          onClick={() =>handleTabClick("tab2")}
        >
          Admission Request
        </button>
        <button
          className={`${activeTab === "tab3" ? "active" : ""} ${activeRequest=="tab3" && "active-request"}`}
          onClick={() =>handleTabClick("tab3")}
        >
          Discharge
        </button>
      </div>
      {/* Tab content */}
      <div className="tab-content">
        <div className="appointmentList-sub-tabpane row">
        <div className="appointmentList-filter-container row mx-0">
            {/* <AdmissionPagination
              // filterOptions={filterOptions}
              // sortOptions={sortOptions}
              // onFilterSelect={handleFilterChange2}
              // onSortSelect={handleSortChange}
              // onSearchChange={handleSearchChange}
              // onPageChange={handlePageChange}
              // pagination={pagination}
              //handleDateChange={handleDateChange} // Ensure this is passed down
              //handleRangeDateChange={handleRangeDateChange} // Date range
              // filterAppointment={filterAppointment}
              // onFilterAppointment={handleFilterChange}
              // filterType={filterType}
              // handleReset={onhandleReset}
              // response={response}
              // setResponse={setResponse}
              // search={search}
            /> */}
        </div>
        <span className="col-3 col-4"></span>
      </div>

        {
          activeTab === "tab1" && 
          <Admission 
            status={'admitted'}
            setStatus={setStatus}
            getInpatientListData={getInpatientListData}
            isMd={isMd}
            IPAdmissionList={IPAdmissionList}
            loading={loading}
            {...{admPopup,setAdmPopup,setPopPage,handleGetIpDetails}}
          />
        }
        {
          activeTab === "tab2" && 
          <AdmissionRequest 
            status={'pending'}
            setStatus={setStatus}
            getInpatientListData={getInpatientListData}
            isMd={isMd}
            IPAdmissionList={IPAdmissionList}
            loading={loading}
            {...{admPopup,setAdmPopup,setPopPage,handleGetIpDetails}}
          /> 
        }
         {
          activeTab === "tab3" && 
          <DischargeList 
            status={'pending'}
            setStatus={setStatus}
            getInpatientListData={getInpatientListData}
            isMd={isMd}
            IPAdmissionList={IPAdmissionList}
            loading={loading}
            {...{admPopup,setAdmPopup,setPopPage,handleGetIpDetails}}
          /> 
        }
      </div>
      <Modal show={admPopup} onHide={()=>setAdmPopup(!admPopup)} backdrop={true} keyboard={false} size="xl"  centered>
        <Modal.Body>
          <AdmPopups {...{admPopPage,patientDataDetails,ashtaChoice,vitalTypes}}/>
        </Modal.Body>
      </Modal>
    </div>
    
    </>
  )
}

export default Ipadmissions
