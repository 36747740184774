import React, { useState } from "react";
import TextAreaBox from "../../../../../../shared/form-components/form-textarea/TextAreaBox";
import InputTextBox from "../../../../../../shared/form-components/form-text/InputTextBox";
import { Form, Popover } from "react-bootstrap";
import Calendar from "react-calendar";
import InputBtnGroup from "../../../../../../shared/form-components/form-text/InputBtnGroup";
import calender_icon from "../../../../../../../assets/pictures/icons/calender-icon.png";
import PatientSymptoms from "../../PatientSymptoms";
import Accordion from "../../../../../../shared/form-components/accordian/Accordion";
import moment from "moment";
import { TimePicker } from 'react-ios-time-picker'
const DiagnosisDetailsTransfer = ({ lookChange, value, setValue ,patientData , ashtaChoice,vitalTypes,diagnosisInputDisable}) => {
  const [showAdmCalendar, setShowAdmCalendar] = useState(false);
  const symIssue= []
  const today = new Date();
  const handleAdmCalendar = (e) => {
    setValue?.setAdmissionDate(e)
    setShowAdmCalendar(false);
  };
  const admCalendar = (
    <Popover className="calendar-popup-container">
      <Calendar onChange={handleAdmCalendar} value={value?.admissionDate}/>
    </Popover>
  );
  const [tempTime,setTempTime] = useState()
  const handleAdmissionTime = (e)=>{
    var adm;
    if (e.slice(-2) === "PM") {
        var num = Number(e.slice(0, 2))
        if (num != 12) {
            num = num + 12
        }
        adm = num + e.slice(2, 5)
    } else {
        var num = Number(e.slice(0, 2))
        if (num == 12) {
            num = '00'
        }
        adm = num + e.slice(2, 5)
    }
    console.log(adm,e)
    setTempTime(e)
    setValue?.setAdmissionTime(adm)
  }
  return (
    <div
      className={`card card-container ${
        lookChange ? "lookChange m-0" : ""
      } bg-light`}
      style={{border:'0',backgroundColor:'fafafa'}}
    >
      <div className="row row-container p-3 py-5 ">
        <div className="col-6">
          {!diagnosisInputDisable?(<div>
            <div className="col-12 p-1">
              <TextAreaBox
                label={"Reason for Admission"}
                rows={3}
                  defvalue={value?.admissionReason}
                  onChange={setValue?.setAdmissionReason}
              />
            </div>
            <div className="col-12  d-flex justify-content-between">
              <div className="col-4 p-1">
                {/* <InputTextBox
                  label={"Duration"}
                  defvalue={value?.admissionDuration}
                  onChange={setValue?.setAdmissionDuration}
                  containerclassName={"col-3 mb-4"}
                /> */}
                <div className="col-3 mb-4">
                <label className="label-text-content pb-2"> Duration</label>
                <div className="d-flex justify-content-start align-items-center">
                  <input 
                    className="input-text-control ps-2"
                    placeholder="Enter no.of.days"
                    type="number" 
                    min="0" 
                    step="1" 
                    value={value?.admissionDuration}
                    onChange={(e)=>setValue?.setAdmissionDuration(e.target.value)} 
                  />
                </div>
                </div>
              </div>
              <div className="col-4 p-1">
                <InputBtnGroup
                  label={" Admission Date"}
                  icon={ <img src={calender_icon} alt="" className="search-icon-img" />}
                  containerClass={"col mb-3"}
                  defvalue={value?.admissionDate && moment(new Date(value?.admissionDate)).format("DD - MM - YYYY")}
                  setTogglePopup={setShowAdmCalendar}
                  togglePopup={showAdmCalendar}
                  popupContent={admCalendar}
                  popupPlacement={"bottom"}
                />
              </div>
              <div className="col-4 p-1">
              <label className="label-text-content pb-2"> Admission Time</label>
                <TimePicker
                  inputClassName='adm-input-timepicker'
                  placeHolder='Adm Time'
                  use12Hours
                  onSave={handleAdmissionTime}
                  value={tempTime}
                />
            </div>
            </div>
          </div>):(
            <div> 
              <div className="col-12 p-1">
                <TextAreaBox
                  label={"Reason for Admission"}
                  rows={3}
                    defvalue={patientData?.reason_for_admission}
                />
              </div>
              <div className="col-12  d-flex justify-content-between">
                <div className="col-6 p-1">
                  {/* <InputTextBox
                    label={"Duration"}
                    defvalue={value?.admissionDuration}
                    onChange={setValue?.setAdmissionDuration}
                    containerclassName={"col-3 mb-4"}
                  /> */}
                  <div className="col-3 mb-4">
                  <label className="label-text-content pb-2">Duration</label>
                  <div className="d-flex justify-content-start align-items-center">
                  <input 
                    className="input-text-control ps-2"
                    placeholder="Enter no.of.days"
                    type="number" 
                    min="0" 
                    step="1" 
                    value={patientData?.duration}
                  />
                  <span className="label-text-content ps-2">Days</span>
                  </div>
                  </div>
                </div>
                <div className="col-6 p-1">
                  <InputBtnGroup
                    label={"Date of Admission"}
                    icon={ <img src={calender_icon} alt="" className="search-icon-img" />}
                    containerClass={"col mb-3"}
                    defvalue={patientData?.admission_date && moment(new Date(patientData?.admission_date)).format("DD - MM - YYYY")}
                    // setTogglePopup={setShowAdmCalendar}
                    // togglePopup={showAdmCalendar}
                    // popupContent={admCalendar}
                    popupPlacement={"bottom"}
                  />
                </div>
              </div>
            </div>
          )}
          
          <div className="col-12 p-1">
            {/* <InputTextBox
              label={"Present Issue"}
              // defvalue={value?.prIssue}
              // onChange={setValue?.setPrIssue}
              containerclassName={"col-12 mb-4"}
              sizePad={true}
            /> */}
            <label className="pb-2">Present Issue</label>
            <input type="text" className="form-control p-2" style={{backgroundColor:"#f4f4f4" ,border:"0"}} placeholder="Present Issue" value={patientData?.assessment_details?.present_issue} disabled /> 
          </div>
          {/* <div className="col-12 p-1">
            <Form.Label className="label-text-content mt-3 mb-0">
              Symptoms
            </Form.Label>
            <PatientSymptoms
              // onAdd={handleSymAdd}
              data={symIssue}
              // onRemove={handleSymDel}
              plusBtn={true}
            />
          </div> */}
          <label className='pb-2 ps-1' >Symptoms</label>
          <div className="col-12 px-1">
            <div className="col-12 rounded-3" style={{height:"10.3rem",overflowY:"scroll",backgroundColor:"#f4f4f4",border:"0"}}>
            { patientData?.symptoms.map((data)=>{
                return(<input type="text" className="form-control" placeholder="Fever" disabled value={data?.description}/>)
            })}
            </div>
          </div>
         
          <div className="col-12 p-1 mt-3">
            <div
              className="p-3 w-100 rounded-3 text-start text-light"
              role="none"
              style={{ backgroundColor: "#545D6E" }}
            >
              Ashtavidha Pariksha
            </div>
            <Accordion
              title={"Ashtavidha Pariksha"}
              data={ashtaChoice}
              data2={patientData?.ashtavidha_pariraksha}
              // setAttribute={handleAttribute}
              // setContent={handleContent}
              // attribute={attribute}
              // content={content}
              // type={ashtaType}
              // handleAdd={handleAshtaAdd}
              // handleDelete={deleteAshtaVidha}
              alwaysOn={true}
              disabledAccordion={true}
            />
          </div>
        </div>
        <div className="col-6">
            {/* <p className='label-text-content pt-1 ps-1'>Prakrithi Assessment</p>
            <div className="col-12 d-flex justify-content-between">
                <div className="col p-1">
                <InputTextBox
                label={'Vatta'}
                // defvalue={value?.prIssue}
                // onChange={setValue?.setPrIssue}
                containerclassName={'col-3 mb-4 '}
                sizePad={true}
                />
                </div>
                <div className="col p-1">
                <InputTextBox
                label={'Pitta'}
                // defvalue={value?.prIssue}
                // onChange={setValue?.setPrIssue}
                containerclassName={'col-3 mb-4'}
                sizePad={true}
                />
                </div>
                <div className="col p-1">
                <InputTextBox
                label={'Kapha'}
                // defvalue={value?.prIssue}
                // onChange={setValue?.setPrIssue}
                containerclassName={'col-3 mb-4'}
                sizePad={true}
                />
                </div>
            </div> */}
            <div className='col-12 p-1'>
                <label className="pb-2">Prakriti Assessment</label>
                <div className="row">
                    <div className="col">
                    <input type="text" className="form-control  p-2" style={{backgroundColor:"#f4f4f4" ,border:"0"}} placeholder="Vatta" value={patientData?.assessment_details?.vatta} disabled />
                    </div>
                    <div className="col">
                    <input type="text" className="form-control  p-2" style={{backgroundColor:"#f4f4f4" ,border:"0"}} placeholder="Pitta" value={patientData?.assessment_details?.pita} disabled />
                    </div>
                    <div className="col">
                    <input type="text" className="form-control  p-2" style={{backgroundColor:"#f4f4f4" ,border:"0"}} placeholder="Kapha" value={patientData?.assessment_details?.kapha} disabled />
                    </div>
                </div>
            </div>
            <div className="col-12 p-1">
                {/* <TextAreaBox
                label={"Remark"}
                rows={4}
                //   defvalue={value?.diagnosis}
                //   onChange={setValue?.setDiagnosis}
                /> */}
                <label className="pb-2">Remark</label>
                <textarea className="form-control" style={{ resize: 'none',backgroundColor:"#f4f4f4" ,border:"0" }}  rows="5" placeholder="Remark" disabled>{patientData?.assessment_details?.remark}</textarea>
                    
            </div>
            <div className="col-12 p-1">
                {/* <InputTextBox
                label={'ICD-10 Code'}
                // defvalue={value?.prIssue}
                // onChange={setValue?.setPrIssue}
                containerclassName={'col-3 mb-4'}
                sizePad={true}
                /> */}
              <label className="pb-2">ICD-10 Code</label>
              <input type="text" className="form-control  p-2" style={{backgroundColor:"#f4f4f4" ,border:"0"}}  placeholder="Remark" value={patientData?.assessment_details?.icd_10_code} disabled />
                    
            </div>
            <div className="col-12 p-1 mb-1">
                {/* <TextAreaBox
                label={"Diagnosis Summary"}
                rows={6}
                //   defvalue={value?.diagnosis}
                //   onChange={setValue?.setDiagnosis}
                /> */}
              <label className="pb-2">Diagnosis Summary</label>
              <textarea className="form-control"  style={{ resize: 'none',backgroundColor:"#f4f4f4" ,border:"0" }} rows="7" placeholder="Summary content here..." disabled>{patientData?.assessment_details?.diagnosis}</textarea>      
            </div>
            <div className="col-12 p-1">
            <div
              className="p-3 w-100 rounded-3 text-start text-light"
              role="none"
              style={{ backgroundColor: "#545D6E" }}
            >
              Health Assessment
            </div>
            <Accordion
                title={"Health Assessment"}
                data={vitalTypes}
                data2={patientData?.health_assessment}
                // type={healthType}
                // attribute={attribute}
                // content={content}
                // setAttribute={handleVitalAttribute}
                // setContent={handleVitalContent}
                // handleVitalAdd={handleVitalsAdd}
                // handleDelete={deleteHealthAssessment}
                alwaysOn={true}
                disabledAccordion={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DiagnosisDetailsTransfer;
