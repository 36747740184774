import React from "react";
import DropDownBox from "../../../../../../shared/form-components/dropdown/CustomDropDown";
import TextAreaBox from "../../../../../../shared/form-components/form-textarea/TextAreaBox";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {FaRegSquarePlus} from "react-icons/fa6";
import { MdOutlineModeEdit } from "react-icons/md";
import "./TreatmentList.css";
const TreatmentList = ({ value, setValue, functions }) => {

  const handleSelectTreatment = (item, i) => {
    setValue?.setSelectedTreatment(i);
    setValue?.setTreatTherapyName(item.fk_name_of_therapy)
    setValue?.setTreatDuration(item.fk_t_duration)
    setValue?.setTreatFrequency(item.fk_t_frequency)
    setValue?.setTreatSession(item.fk_total_session)
    setValue?.setTreatSpecificInstruction(item.specific_instruction)
    setValue?.setSlnoTreat(i+1)
    setValue?.setTreatId(item.id)
  };

  return (
    <div  className="container treatment mx-0 w-100">
      <table style={{ "text-align": "center" }}>
        <tr>
          <th>
            <div>Sl.No</div>
          </th>
          <th>
            <div>Name of Therapy</div>
          </th>
          <th>
            <div>Duration</div>
          </th>
          <th>
            <div>Frequency</div>
          </th>
          <th>
            <div>Specific Instruction</div>
          </th>
          <th>
            <div>Total Session</div>
          </th>
          <th>
            <div></div>
          </th>
        </tr>
        <tr>
          <td>
            <div className="custom_input1 d-flex align-items-center justify-content-center">
              {value?.slnoTreat}
            </div>
          </td>
          <td>
            
              <DropDownBox
                containerClass={"w-100 d-flex align-items-center"}
                addNew={true}
                fluid={true}
                search={true}
                selection={true}
                state={"name_of_therapy"}
                setNew={functions?.addPatientProperty}
                options={value?.therapyNameList}
                setDataValue={setValue?.setTreatTherapyName}
                selectedValue={value?.treatTherapyName}
              />
            
          </td>
          <td>
          
            
              <DropDownBox
                containerClass={"d-flex align-items-center"}
                addNew={true}
                fluid={true}
                search={true}
                selection={true}
                state={"t_duration"}
                setNew={functions?.addPatientProperty}
                options={value?.durationList}
                setDataValue={setValue?.setTreatDuration}
                selectedValue={value?.treatDuration}
              />
         
          </td>
          <td>
            
              <DropDownBox
                containerClass={"d-flex align-items-center"}
                addNew={true}
                fluid={true}
                search={true}
                selection={true}
                state={"t_frequency"}
                setNew={functions?.addPatientProperty}
                options={value?.frequencyList}
                setDataValue={setValue?.setTreatFrequency}
                selectedValue={value?.treatFrequency}
              />
          
          </td>
          <td>
            
              <TextAreaBox
                rows={"1"}
                containerClass={"d-flex align-items-center mb-0"}
                onChange={setValue?.setTreatSpecificInstruction}
                defvalue={value?.treatSpecificInstruction}
                removeMb={true}
              />
            
          </td>

          <td>
            
              <DropDownBox
                containerClass={"d-flex align-items-center"}
                addNew={true}
                fluid={true}
                search={true}
                selection={true}
                state={"total_session"}
                setNew={functions?.addPatientProperty}
                options={value?.sessionList}
                setDataValue={setValue?.setTreatSession}
                selectedValue={value?.treatSession}
              />
            
          </td>
          <td>
            <button
              className="btn text-light px-2"
              style={{ backgroundColor: "#E3B075" }}
              onClick={(e)=>functions?.handleSubmitTreat(e)}
            >
              {value?.slnoTreat ? <MdOutlineModeEdit  size={18}/> : <FaRegSquarePlus size={18}/>} 
            </button>
          </td>
        </tr>
        {value?.treatmentListData?.length > 0 &&
          value?.treatmentListData?.map((item, i) => (
            <tr>
              <td>
                <div className="custom_input d-flex align-items-center justify-content-center">
                  {i + 1}
                </div>
              </td>
              <td>
                <div
                  className="custom_input d-flex align-items-center justify-content-start mouse-pointer"
                  style={
                    value?.selectedTreatment === i
                      ? { backgroundColor: "#e3b075" }
                      : {}
                  }
                  onClick={(e) => handleSelectTreatment(item, i)}
                >
                  {item?.name_of_therapy?.property_value}
                </div>
              </td>
              <td>
                <div className="custom_input d-flex align-items-center justify-content-start">
                  {item?.therapy_duration?.property_value}
                </div>
              </td>
              <td>
                <div className="custom_input d-flex align-items-center justify-content-start">
                  {item?.therapy_frequency?.property_value}
                </div>
              </td>
              <td>
                <div className="custom_input d-flex align-items-center justify-content-start">
                  {item?.specific_instruction}
                </div>
              </td>
              <td>
                <div className="custom_input d-flex align-items-center justify-content-start">
                  {item?.therapy_total_session?.property_value}
                </div>
              </td>
              <td>
                <button
                  className="btn text-light px-2"
                  onClick={() => functions?.handleTreatmentDelete(item)}
                  style={{ backgroundColor: "#E3B075" }}
                >
                  <DeleteOutlineIcon size={18} />
                </button>
              </td>
            </tr>
          ))}
      </table>
    </div>
  );
};

export default TreatmentList;
