import React, { useEffect, useState } from "react";
import "./StaffAttendance.css";
import calendar from"../../../assets/svgs/calendar.svg"
import staff_attendance from "../../../assets/svgs/staff-attendance.svg";
// import staff_attendance from "../../../assets/pictures/icons/patient-details-icon.png";
import { IoPersonCircleSharp } from "react-icons/io5";


import { Tab, TabPane } from "react-bootstrap";
import { Modal } from "antd";
import { HashLoader } from "react-spinners";
// import useAccommodationServices from "../../services/api-services/accommodationServices";
import useAccountServices from "../../services/api-services/accountServices";

const StaffAttendance = () => {
  const [loading, setLoading] = useState(false);
  const [staffAttendanceReport, setStaffAttendanceReport] = useState([]);
  const [allStaffAttendance, setAllStaffAttendance] = useState([]);
  // const [staffDetails, setStaffDetails] = useState(true);
  const [staffDetails, setStaffDetails] = useState(false);
  const [singleStaffDetails, setSingleStaffDetails] = useState({});
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const {getStaffAttendanceReport,getStaffDetail,getStaffAttendanceReportByDate} = useAccountServices();
  // const {getStaffAttendanceReport,getStaffDetail} = useAccommodationServices();

  useEffect(() => {
    getAllStaffAttendance();
  }, []);

  useEffect(() => {
    handleSubmitFilterDate();
  }, [fromDate, toDate]);

  const getAllStaffAttendance = async () => {
    try {
      const response = await getStaffAttendanceReport();
      if (response?.success) {
        setStaffAttendanceReport(response?.data);      
      }
    } catch {}
  };

  const getSingleStaffDetails = async (id) => {
    try {
      const response = await getStaffDetail(id);
      if (response?.success) {
        // console.log(response?.data,"========getStaffDetail---------------");
        setSingleStaffDetails(response?.data)
        setStaffDetails(true)
      }
    } catch {}
  };
        console.log(singleStaffDetails,"========singleStaffDetails---------------");


      
        const handleSubmitFilterDate = async () => {
          console.log("handleSubmitFilterDate");
          console.log("From:", fromDate);
          console.log("To:", toDate);
          
          if (fromDate && toDate) {
            try {
              const response = await getStaffAttendanceReportByDate(fromDate,toDate);
              if (response?.success) {
                // setStaffAttendanceReport(response?.data);     
                console.log(response,"===handleSubmitFilterDate handleSubmitFilterDate");
                 
              }
            } catch {}
            // Your logic here (e.g., API call or validation)
          } else {
            console.log("Both dates must be selected!");
          }
        };


  return (
    <div>
      <Modal
        title={null}
        centered
        open={staffDetails}
        width={900}
        closable={false}
        footer={null}
        onCancel={() => setStaffDetails(false)}
        className="modal-content"
      >
        <>
        <div className=" rounded p-0 bg-light ">
          <div className="row rounded-3 p-2 bg-light">
            <div
              className="row  w-100 m-0 d-flex align-items-center"
              style={{ height: "80px" }}
            >
              <div className="p-0 m-0 col-md-6  profile-name">
                <div className="w-100 h-100 d-flex ">
                  <div className="d-flex justify-content-center align-items-center rounded-circle" style={{height:"60px",width:"60px"}}>
                  <IoPersonCircleSharp size={50} />
                  </div>
                  <div className="name-div w-75 h-100 ps-3  d-flex flex-lg-column justify-content-center">
                    <p className="p-0 m-0">ID : <span className="profile-id">1234</span></p>
                    <p className="p-0 m-0 fw-bold profile-inside-name">Test Name 1</p>
                    <p className="p-0 m-0">Nurse</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-3 rounded-3 bg-white p-0 m-0 pt-3">
              <div className="col-6 ">
                <p className="fw-bold">Staff Details</p>
                <div className="row ">
                  <div className="col-6 ">
                    <label htmlFor="" className="modal-label">
                      Staff ID
                    </label>
                    <input type="text" value={singleStaffDetails?.id} className="form-control mt-1" readOnly />
                  </div>
                  <div className="col-6">
                    <label htmlFor="" className="modal-label">
                      Joined Date
                    </label>
                    <input type="text" value={singleStaffDetails?.profile?.date_of_joined} className="form-control mt-1" readOnly />
                  </div>
                  <div className="col-12">
                    <label htmlFor="" className="modal-label">
                      Name
                    </label>
                    <input type="text" value={singleStaffDetails?.first_name} className="form-control mt-1" readOnly />
                  </div>
                  <div className="col-6 ">
                    <label htmlFor="" className="modal-label">
                      Desingnation
                    </label>
                    <input type="text" value={singleStaffDetails?.payscale?.designation} className="form-control mt-1" readOnly />
                  </div>
                  <div className="col-6">
                    <label htmlFor="" className="modal-label">
                      User Name
                    </label>
                    <input type="text" value={singleStaffDetails?.first_name} className="form-control mt-1" readOnly />
                  </div>
                  <div className="col-12">
                    <label htmlFor="" className="modal-label">
                    Desingnation
                    </label>
                    <input type="text" value={singleStaffDetails?.payscale?.designation} className="form-control mt-1" readOnly />
                  </div>
                  <div className="col-12">
                    <label htmlFor="" className="modal-label">
                      Staff Grade
                    </label>
                    <input type="text" value={singleStaffDetails?.payscale?.staff_grade} className="form-control mt-1" readOnly />
                  </div>
                  <div className="col-12">
                    <label htmlFor="" className="modal-label">
                      Salary
                    </label>
                    <input type="text" value={singleStaffDetails?.payscale?.salary} className="form-control mt-1" readOnly />
                  </div>

                </div>
              </div>
              <div className="col-6 ">
                <p className="fw-bold">Personal Information</p>
                <div className="row ">
                  <div className="col-6 ">
                    <label htmlFor="" className="modal-label">
                      Email
                    </label>
                    <input type="text" value={singleStaffDetails?.email} className="form-control mt-1" readOnly />
                  </div>
                  <div className="col-6">
                    <label htmlFor="" className="modal-label">
                      Mobile
                    </label>
                    <input type="text" value={singleStaffDetails?.mobile} className="form-control mt-1" readOnly />
                  </div>
                  <div className="col-6 ">
                    <label htmlFor="" className="modal-label">
                      Date of Birth
                    </label>
                    <input type="text" value={singleStaffDetails ? singleStaffDetails?.profile?.dob.split("T")[0] : ""} className="form-control mt-1" readOnly />
                  </div>
                  <div className="col-6 ">
                    <label htmlFor="" className="modal-label  mb-1">
                      Gender
                    </label>
                    <div className="row gap-2 gender-main-div pe-2">
                    {/*singleStaffDetails.profile.gende */}
                      <div className={`gender col h-100 d-flex align-items-center justify-content-center ${singleStaffDetails?.profile?.gender =="male" && "select-gender"}`}>Mele</div>
                      <div className={`gender col h-100 d-flex align-items-center justify-content-center ${singleStaffDetails?.profile?.gender =="female" && "select-gender"}`}>Female</div>
                      <div className={`gender col h-100 d-flex align-items-center justify-content-center ${singleStaffDetails?.profile?.gender =="other" && "select-gender"}`}>Other</div>
                    </div>
                  </div>
                  
                  <div className="col-4 ">
                    <label htmlFor="" className="modal-label">
                      Country
                    </label>
                    <input type="text" value={singleStaffDetails?.profile?.country} className="form-control mt-1" readOnly />
                  </div>
                  <div className="col-4">
                    <label htmlFor="" className="modal-label">
                      State
                    </label>
                    <input type="text" value={singleStaffDetails?.profile?.state} className="form-control mt-1" readOnly />
                  </div>
                  <div className="col-4">
                    <label htmlFor="" className="modal-label">
                      District
                    </label>
                    <input type="text" value={singleStaffDetails?.profile?.district} className="form-control mt-1" readOnly />
                  </div>

                  <div className="col-6 ">
                    <label htmlFor="" className="modal-label">
                      City
                    </label>
                    <input type="text" value={singleStaffDetails?.profile?.city} className="form-control mt-1" readOnly />
                  </div>
                  <div className="col-6">
                    <label htmlFor="" className="modal-label">
                      PIN Code
                    </label>
                    <input type="text" value={singleStaffDetails?.profile?.pincode} className="form-control mt-1" readOnly />
                  </div>

                  <div className="col-12 ">
                    <label htmlFor="" className="modal-label">
                      Address Line 1
                    </label>
                    <input type="text" value={singleStaffDetails?.profile?.address_line_1} className="form-control mt-1" readOnly />
                  </div>
                  <div className="col-12">
                    <label htmlFor="" className="modal-label">
                    Address Line 2
                    </label>
                    <input type="text" value={singleStaffDetails?.profile?.address_line_2} className="form-control mt-1" readOnly />
                  </div>

                  
                </div>
              </div>
            </div>
          </div>
        </div>  {/* end */}
        <p className="mt-4 fw-bold">Education Details</p>
          <div className="row p-0  ">  
          <div className="row rounded-3 p-2 m-0 bg-light ">
          <Tab.Container activeKey={"tab1"} className=" p-0  m-0">
          <Tab.Content className="bg-light p-0">
              <TabPane eventKey="tab1" className="">
                <table className="d-none d-sm-table  custom-table-ducation w-100">
                  <thead className=" bg-light custom-table-ducation-head">
                    <tr className="bg-light ">
                      <th>Noo</th>
                      <th>Course</th>
                      <th>Date</th>
                      <th>Institution</th>
                      <th>Mark/Grade</th>
                    </tr>
                  </thead>
                  <tbody
                        className="custom-table-ducation-body"
                        style={{ objectFit: "contain" }}
                      >
                        {singleStaffDetails?.education?.map((item, index) =>(
                          <>
                          <tr className=" bg-white m-4 rounded-3" key={index}>
                          <td>1</td>
                          <td>2</td>
                          <td>3</td>
                          <td>{item.institution}</td>
                          <td>{item.score}</td>
                        </tr>
                        <tr style={{height: "10px"}}></tr>
                        </> 
                        ))}
                      </tbody>
                </table>
              </TabPane>
            </Tab.Content>
            </Tab.Container>
          </div>

          </div>

        <p className="mt-4 fw-bold">Expeience Details</p>
          <div className="row p-0  ">  
          <div className="row rounded-3 p-2 m-0 bg-light ">
          <Tab.Container activeKey={"tab1"} className=" p-0  m-0">
          <Tab.Content className="bg-light p-0">
              <TabPane eventKey="tab1" className="">
                <table className="d-none d-sm-table  custom-table w-100">
                  <thead className=" bg-light custom-table-head">
                    <tr className="bg-light ">
                      <th>No</th>
                      <th>Experience</th>
                      <th>Location</th>
                      <th>From Date</th>
                      <th>To Date</th>
                    </tr>
                  </thead>
                  <tbody
                        className="custom-table-body"
                        style={{ objectFit: "contain" }}
                      >
                        {singleStaffDetails?.profile?.profession?.map((item, index) =>(
                          <>
                          <tr className=" bg-white mb-4" key={index}>
                                <td>1</td>
                                <td>{item.experience}</td>
                                <td>{item.fk_location}</td>
                                <td>{item.from_date}</td>
                                <td>{item.to_date}</td>
                              </tr>
                              <tr style={{height: "10px"}}></tr>
                          </>
                        ))} 
                      </tbody>
                </table>
              </TabPane>
            </Tab.Content>
            </Tab.Container>
          </div>

          </div>
        
        </>
      </Modal>
      <Tab.Container activeKey={"tab1"}>
        <div className="px-3 w-100 h-100">
          <div className="row staff-attendance-head-row mt-2 py-2">
            <div className="col-3 ">
              <div className="p-2 border rounded staff-attendance-head-col d-flex align-items-center ">
                <img src={staff_attendance} alt="" />
                <p className="ms-3">Staff Attendance Report</p>
              </div>
            </div>
          </div>
        </div>
        <div className="staff-attendance-main ps-3 mt-4 pt-3">
          <p className="h5">Staff Attendance Report</p>
          <div className="row px-3 mt-4 gap-5">
            <div className="col-3 p-0 d-flex align-items-center justify-content-between">
              <p className="p-0 m-0 ">from</p>
              <div className="p-1 border bg-light rounded w-75  position-relative">
                <input type="date" id="datePicker" className="date-input w-100 h-100 pe-4" onChange={(e) => setFromDate(e.target.value)} />
                <label htmlFor="datePicker" className='calendar-img' ><img src={calendar} alt=""  /></label>
                
              </div>
              
            </div>

            <div className="col-3 p-0 d-flex align-items-center justify-content-between">
              <p className="p-0 m-0">upto</p>
              {/* <div className="p-1 border bg-light rounded  position-relative">
                <input type="text" className="w-100 h-100 pe-4 " />
                <img src={calendar} alt="" className='calendar-img ' />
              </div> */}
              <div className="p-1 border bg-light rounded w-75  position-relative">
                <input type="date" id="datePicker" className="date-input w-100 h-100 pe-4" onChange={(e) => setToDate(e.target.value)} />
                <label htmlFor="datePicker" className='calendar-img' ><img src={calendar} alt=""  /></label>
                
              </div>
            </div>
          </div>
          <div className="patient-list-container">
            <Tab.Content className="patient-table-container container-white">
              <TabPane eventKey="tab1" className="">
                <table className="table patient-list-table d-none d-sm-table">
                  <thead className="patient-list-table-head">
                    <tr>
                      <th>Sl.No</th>
                      <th>Staff ID</th>
                      <th>Staff Name</th>
                      <th>Present</th>
                      <th>Half Day</th>
                      <th>Full Day</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  {loading ? (
                    <tbody>
                      <tr>
                        <td colSpan="7">
                          <div className="d-flex justify-content-center align-items-center loader-container">
                            <HashLoader color="#e3b075" size={100} />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    <>
                      <tbody
                        className="patient-list-table-body"
                        style={{ objectFit: "contain" }}
                      >
                        {staffAttendanceReport &&
                          staffAttendanceReport?.map((item, index) => {
                            return (
                              <tr key={index} onClick={()=>getSingleStaffDetails(item.id)}>
                                <td>{index}</td>
                                <td>{item.user_id}</td>
                                <td>{item.username}</td>
                                <td>{item.present_count}</td>
                                <td>{item.half_day_count}</td>
                                <td>{item.full_day_count}</td>
                                <td></td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </>
                  )}
                </table>
              </TabPane>
            </Tab.Content>
          </div>
        </div>
      </Tab.Container>
    </div>
  );
};

export default StaffAttendance;
