import React, { useEffect, useState } from "react";
import Admission from "../IP Admissions/Admission";
import AdmissionRequest from "../IP Admissions/AdmissionRequest";
import Ward from "./Ward";
import PrivateRoom from "./PrivateRoom";
import "./Accommodation.css";
import WardForm from "./components/ward/WardForm";
import PrivateSuite from "./components/privatesuite/PrivateSuite";
import AddSuitRoom from "./components/privatesuite/AddSuitRoom";
import EditSuitRoom from "./components/privatesuite/EditSuitRoom";
import useAccommodationServices from "../../services/api-services/accommodationServices";
import AyurvedicRoom from "./components/ayurvedicroom/AyurvedicRoom";
import AddAyurvedicRoom from "./components/ayurvedicroom/AddAyurvedicRoom";

const Accommodation = () => {
  const [activeRequest, setActiveRequest] = useState("tab1");
  // const [showModalPrivateSuite, setShowModalPrivateSuite] = useState("");
  const [showSelectModal, setShowSelectModal] = useState("");
  // const [showModalPrivateSuite, setShowModalPrivateSuite] = useState("EditSuitRoom");
  const [wardId, setWardId] = useState(null);
  const [wardDetails, setWardDetails] = useState({
    ward_name: "",
    ward_type: "",
    rate_per_day: "",
    total_no_of_bed: "",
    eligible_for_insurance: false,
  });
  const [editSuitRoomData, setEditSuitRoomData] = useState({
    suite_name: "",
    suite_no: "",
    floor_no: "",
    rate_per_day: "",
    eligible_for_insurance: false,
  });

  const { getSuteEdit } = useAccommodationServices();

  const handleClosePrivateSuit = () => setShowSelectModal(false);
  const handleShowAddPrivateSuit = () => setShowSelectModal("addPrivateSuite");
  const handleShowAddAyurvedicRoom = () => setShowSelectModal("AddAyurvedicRoom");
  const handleCloseAddAyurvedicRoom = () => setShowSelectModal(false);

  const getEditSuitRoom = async (id) => {
    try {
      const response = await getSuteEdit(id);
      if (response?.success) {
        setEditSuitRoomData({
          id: response?.data.id,
          suite_id: response?.data.suite_id,
          suite_name: response?.data.suite_name,
          suite_no: response?.data.suite_no,
          floor_no: response?.data.floor_no,
          rate_per_day: response?.data.rate_per_day,
          eligible_for_insurance: response?.data.eligible_for_insurance,
        });
        setShowSelectModal("EditSuitRoom");
      } else {
        console.log("else =====getEditSuitRoom");
      }
    } catch (error) {
      console.log(error, "error=========");
    }
  };

  // State to track the active tab
  const [activeTab, setActiveTab] = useState("tab1");

  // Function to change the active tab
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <>
      {showSelectModal == "addPrivateSuite" ? (
        <AddSuitRoom handleClosePrivateSuit={handleClosePrivateSuit} />
      ) : showSelectModal == "EditSuitRoom" ? (
        <EditSuitRoom
          handleClosePrivateSuit={handleClosePrivateSuit}
          editSuitRoomData={editSuitRoomData}
          setEditSuitRoomData={setEditSuitRoomData}
        />
      ) : showSelectModal == "AddAyurvedicRoom" ? (
        <AddAyurvedicRoom
          handleCloseAddAyurvedicRoom={handleCloseAddAyurvedicRoom}
        />
      ) : (
        <>
          {
            <div className="tabs-container mt-0">
              {/* Tab headers */}
              <div className="tabs tab-btn my-4">
                <button
                  className={`px-5 ${activeTab === "tab1" ? "active" : ""} ${
                    activeRequest === "tab1" && "active-request"
                  }`}
                  onClick={() => {
                    handleTabClick("tab1");
                    setActiveRequest("tab1");
                  }}
                >
                  Ward
                </button>
                <button
                  className={`px-4 ${activeTab === "tab2" ? "active" : ""} ${
                    activeRequest === "tab2" && "active-request"
                  }`}
                  onClick={() => {
                    handleTabClick("tab2");
                    setActiveRequest("tab2");
                  }}
                >
                  Private Room
                </button>
                <button
                  className={`px-4 ${activeTab === "tab3" ? "active" : ""} ${
                    activeRequest === "tab3" && "active-request"
                  }`}
                  onClick={() => {
                    handleTabClick("tab3");
                    setActiveRequest("tab3");
                  }}
                >
                  Private Suite
                </button>
                <button
                  className={`px-4 ${activeTab === "tab4" ? "active" : ""} ${
                    activeRequest === "tab4" && "active-request"
                  }`}
                  onClick={() => {
                    handleTabClick("tab4");
                    setActiveRequest("tab4");
                  }}
                >
                  Ayurvedic Specific Room
                </button>
              </div>

              {/* Tab content */}
              <div className="tab-content">
                {activeTab === "tab1" && (
                  <Ward
                    wardId={wardId}
                    setWardId={setWardId}
                    wardDetails={wardDetails}
                    setWardDetails={setWardDetails}
                  />
                )}
                {activeTab === "tab2" && <PrivateRoom />}
                {activeTab === "tab3" && (
                  <PrivateSuite
                    handleShowPrivateSuit={handleShowAddPrivateSuit}
                    getEditSuitRoom={getEditSuitRoom}
                  />
                )}
                {activeTab === "tab4" && (
                  <AyurvedicRoom
                    handleShowAyurvedicRoom={handleShowAddAyurvedicRoom}
                  />
                )}
              </div>
            </div>
          }
        </>
      )}
    </>
  );
};

export default Accommodation;
