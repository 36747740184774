import React, { useEffect, useState } from 'react'
import StaffDetails from './Tabpanes/StaffDetails'
import SalaryDetails from './Tabpanes/SalaryDetails'
import EducationDetails from './Tabpanes/EducationDetails'
import ExperienceDetails from './Tabpanes/ExperienceDetails'
import {Nav, Tab, TabPane} from 'react-bootstrap'
import physician_details from '../../../../assets/pictures/icons/physician_details-icon1.png'
import { useLocation, useNavigate } from 'react-router-dom'
import useAccountServices from '../../../services/api-services/accountServices'
import usePatientServices from '../../../services/api-services/patientServices'
import Swal from 'sweetalert2'
import "./StaffRegistration.css"
import moment from 'moment'
import HashLoader from "react-spinners/ClockLoader";
import jsPDF from 'jspdf'
import { PiEyedropperSampleDuotone } from 'react-icons/pi'

const StaffRegistration = () => {

    const location1 = useLocation()
    const { StaffEditData } = location1.state || {}; // Destructure the state
    useEffect(() => {
        if (StaffEditData) {
            handleStaffEdit(StaffEditData);
        }
    }, [StaffEditData]);


    const [key, setKey] = useState("tab1")
    const [staffEditId,setStaffEditId] = useState('')
    //Staff Details
    const [staffId,setStaffId] = useState('')
    const [joinDate,setJoinDate] = useState('')
    const [staffName,setStaffName] = useState('')
    const [emailId,setEmailId] = useState('')
    const [mobile,setMobile] = useState('')
    const [dob,setDob] = useState('')
    const [gender,setGender] = useState('')
    const [district,setDistrict] = useState('')
    const [state,setState] = useState('')
    const [country,setCountry] = useState('')
    const [city,setCity] = useState('')
    const [pincode,setPincode] = useState('')
    const [address1,setAddress1] = useState('')
    const [address2,setAddress2] = useState('')
    const [username,setUsername] = useState('')
    const [password,setPassword] = useState('')
    const [image,setImage] = useState('')
    const [upass,setUpass] = useState(false)
    
    //Salary Details
    const [openingBalance,setOpeningBalance] = useState('')
    const [staffGrade,setStaffGrade]=useState('')
    const [designation,setDesignation]=useState('')
    const [allowedLeave,setAllowedLeave]=useState('')
    const [pfCut,setPfCut]=useState('')
    const [leaveCut,setLeaveCut]=useState('')
    const [licCut,setLicCut]=useState('')
    const [salary,setSalary]=useState('')
    const [esi,setEsi]=useState('')
    const [salRemark,setSalRemark] =useState('')
    
    //Education Details
    const [course,setCourse]=useState('')
    const [passDate,setPassDate]=useState('')
    const [institution,setInstitution]=useState('')
    const [mark,setMark]=useState('')

    //Experience Details
    const [profession,setProfession]=useState('')
    const [experience,setExperience]=useState('')
    const [location,setLocation]=useState('')
    const [fromDate,setFromDate]=useState('')
    const [toDate,setToDate]=useState('')


    const [experiences, setExperiences] = useState([]);
    const [educations, setEducations] = useState([]);
    const [loading, setLoading] =useState(false)

    const [expId, setExpId] = useState('')
    const [edcId, setEdcId] = useState('')
    const navigate = useNavigate()
    const handleTabChange = () => {
        switch (key) {
            case "tab1":
                setKey('tab2')
                break
            case "tab2":
                setKey('tab3')
                break
            case "tab3":
                setKey('tab4')
                break
            case "tab4":
                setKey('tab1')
                break
        }}
    const handleTabBackChange = () => {
        switch (key) {
            case "tab1":
                navigate('/hrms')
            case "tab2":
                setKey('tab1')
                break
            case "tab3":
                setKey('tab2')
                break
            case "tab4":
                setKey('tab3')
                break
        }}
    
    const {
        getNewID
    } = useAccountServices()

    const getStaffId = async () => {
        try {
            const response = await getNewID('staff_id')
            if (response.success) {
                setStaffId(response?.data?.complete_id)
            }
        } catch { }
    }
    useEffect(()=>{
        if (!StaffEditData) {
            getStaffId()
        }
        getProperties()
    },[])

    const {
        getProperty,
        postProperty
    } = usePatientServices()

    const {postStaffRegister} = useAccountServices()

    const [cityList,setCityList] =useState([])
    const [pincodeList,setPincodeList] = useState([])
    const [districtList,setDistrictList]=useState([])
    const [stateList,setStateList] = useState([])
    const [countryList,setCountryList]=useState([])
    const [professionList,setProfessionList] = useState([])
    const [courseList,setCourseList]=useState([])
    const [designationList,setDesignationList]=useState([])
    const [staffGradeList,setStaffGradeList]=useState([])

    const types = ["location", "pin", "district", "state", "country","profession","course","designation","staff_grade"];
    const setupData = {
        location: setCityList,
        pin: setPincodeList,
        district: setDistrictList,
        state: setStateList,
        country: setCountryList,
        profession: setProfessionList,
        course: setCourseList,
        designation: setDesignationList,
        staff_grade:setStaffGradeList,
    };
    const getProperties = async () => {
        try {
          for (const type of types) {
            const data = { type };
            try {
              const response = await getProperty(data); 
              if (response?.success) {
                const list = response?.data.map((d) => ({
                  text: d.property_value,
                  value: d.id,
                  type: d.property_type,
                }));
                if (setupData[type]) {
                  setupData[type](list);
                }
              } else {
                throw new Error(`Error fetching data for type: ${type}. ${response?.message}`);
              }
            } catch (innerError) {
              console.error(`Error fetching data for type: ${type}`, innerError);
            }
          }
        } catch (error) {
          Swal.fire("Fetch Error", error.message, "error");
        }
      };

    const addPatientProperty = async (value,state) => {
        try {
            let submitData = { property_value: value, property_type: state };
            const response = await postProperty(submitData)
            if (response?.success) {
                getProperties()
                if (state === "location"){
                    setCity(response?.data?.id)
                    setLocation(response?.data?.id)
                }
                if (state === "pin"){
                    setPincode(response?.data?.id)
                }
                if (state === "district"){
                    setDistrict(response?.data?.id)
                }
                if (state === "state"){
                    setState(response?.data?.id)
                }
                if (state === "country"){
                    setCountry(response?.data?.id)
                }
                if (state === "profession"){
                    setProfession(response?.data?.id)
                }
                if (state === "course"){
                    setCourse(response?.data?.id)
                }
                if (state === "designation"){
                    setProfession(response?.data?.id)
                }
                if (state === "staff_grade"){
                    setCourse(response?.data?.id)
                }
            }
            else {
                Swal.fire("", response?.message, "error")
            }
        } catch(e) { console.log(e) }
    }

    // const handleStaffSubmission = async(e) =>{
    //     e.preventDefault()
    //     const sample={
    //         // user_id: staffEditId
    //         username:username,  
    //         mobile:mobile,    
    //         email: emailId,
    //         first_name:staffName, 
           
    //         profile: {
    //             user_id:staffId,                  
    //             dob: dob && moment(new Date(dob)).format('YYYY-MM-DD'),
    //             gender: gender,
    //             date_of_joined:  joinDate && moment(new Date(joinDate)).format('YYYY-MM-DD')
    //         },
    //         education: educations,
    //         payscale: {
    //             fk_designation: designation,            
    //             fk_staff_grade: staffGrade,             
    //             salary: salary,
    //             // salary_date: ,
    //             allowed_leave: allowedLeave,
    //             leave_cut: leaveCut,
    //             pf_cut: pfCut,
    //             esi_cut: esi,
    //             lic_cut: licCut,
    //             // other: ,
    //             opening_balance:openingBalance,
    //             remark:salRemark,
    //         },
    //         profession: experiences,
    //     }
    //     if (staffEditId){
    //         sample['user_id']=staffEditId
    //     }
    //     console.log("%$%%$%%$$%$%%$%%$",staffEditId)
    // }

    const handleStaffSubmission = async (e) => {
        e.preventDefault();
    
        if (!mobile) {
            Swal.fire('', 'Please Fill out this field: Mobile', 'info');
            console.log("Mobile is required:", mobile);
            return;
        }
        if (!emailId) {
            Swal.fire('', 'Please Fill out this field: Email', 'info');
            console.log("Email is required:", emailId);
            return;
        }
        if (!staffName) {
            Swal.fire('', 'Please Fill out this field: First Name', 'info');
            console.log("First Name is required:", staffName);
            return;
        }
        const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5 MB limit (adjust as needed)

        if (image && image.size > MAX_FILE_SIZE) {
            Swal.fire('Error', 'File size exceeds the 5MB limit', 'error');
            return;
        }
        const experienceData = experiences.map((experience) => {
            return {
                ...experience,
                from_date: experience.from_date && moment(new Date(experience.from_date)).format('YYYY-MM-DD'),
                to_date: experience.to_date && moment(new Date(experience.to_date)).format('YYYY-MM-DD')
            };
        });

        const educationData = educations.map((education) => {
            return {
                ...education,
                score: parseFloat(education.score),
                year_of_pass: education.year_of_pass && moment(new Date(education.year_of_pass)).format('YYYY-MM-DD')
              };
        });
       // Set up data structure for staff submission
        const staffDetails = {
            username:username,  
            password:password,
            has_login:upass,
            mobile:mobile,
            email: emailId,
            first_name: staffName,
            // image:image,
            profile: {
                user_id: staffId,
                dob: dob && moment(new Date(dob)).format('YYYY-MM-DD'),
                gender: gender,
                date_of_joined: joinDate && moment(new Date(joinDate)).format('YYYY-MM-DD'),
                address_line_1:address1,
                address_line_2:address2,
                country:country,
                state:state,
                district:district,
                city:city,
                pincode:pincode,
            },
            education: educationData,
            payscale: {
                fk_designation: designation,
                fk_staff_grade: staffGrade,
                salary: parseFloat(salary),
                allowed_leave: parseInt(allowedLeave),
                leave_cut:  parseFloat(leaveCut),
                pf_cut:  parseFloat(pfCut),
                esi_cut:  parseFloat(esi),
                lic_cut:  parseFloat(licCut),
                opening_balance:  parseFloat(openingBalance),
                remark: salRemark
            },
            profession: experienceData,
        };
        if (staffEditId) {
            staffDetails.user_id = staffEditId;
        }
        const sample = JSON.stringify(staffDetails);
      //  Initialize FormData
    //     const  profile={
    //                 user_id: staffId,
    //                 dob: dob && moment(new Date(dob)).format('YYYY-MM-DD'),
    //                 gender: gender,
    //                 date_of_joined: joinDate && moment(new Date(joinDate)).format('YYYY-MM-DD'),
    //                 address_line_1:address1,
    //                 address_line_2:address2,
    //                 country:country,
    //                 state:state,
    //                 district:district,
    //                 city:city,
    //                 pincode:pincode,
    //             }
    //     const payscale= {
    //                         fk_designation: designation,
    //                         fk_staff_grade: staffGrade,
    //                         salary: parseFloat(salary),
    //                         allowed_leave: parseInt(allowedLeave),
    //                         leave_cut:  parseFloat(leaveCut),
    //                         pf_cut:  parseFloat(pfCut),
    //                         esi_cut:  parseFloat(esi),
    //                         lic_cut:  parseFloat(licCut),
    //                         opening_balance:  parseFloat(openingBalance),
    //                         remark: salRemark
    //                     }
    //     const staffDetails = new FormData();

    //     // Basic staff details
    //     staffDetails.append("username", username);
    //     staffDetails.append("password", password);
    //     staffDetails.append("has_login", upass);
    //     staffDetails.append("mobile", mobile);
    //     staffDetails.append("email", emailId);
    //     staffDetails.append("first_name", staffName);
    
    //     // Append image if available
    //     if (image) {
    //         staffDetails.append("image", image);  // Assuming `image` is a File object from <input type="file" />
    //     }
    
    //     // // Profile details
    //    staffDetails.append("profile",JSON.stringify(profile)) 
    //    staffDetails.append("payscale",JSON.stringify(payscale))
    //    staffDetails.append("education",JSON.stringify(educationData))
    //    staffDetails.append("profession",JSON.stringify(experienceData))

        // // Payscale details
        // staffDetails.append("payscale[fk_designation]", designation);
        // staffDetails.append("payscale[fk_staff_grade]", staffGrade);
        // staffDetails.append("payscale[salary]", parseFloat(salary));
        // staffDetails.append("payscale[allowed_leave]", parseInt(allowedLeave));
        // staffDetails.append("payscale[leave_cut]", parseFloat(leaveCut));
        // staffDetails.append("payscale[pf_cut]", parseFloat(pfCut));
        // staffDetails.append("payscale[esi_cut]", parseFloat(esi));
        // staffDetails.append("payscale[lic_cut]", parseFloat(licCut));
        // staffDetails.append("payscale[opening_balance]", parseFloat(openingBalance));
        // staffDetails.append("payscale[remark]", salRemark);
    
        // // Append education details
        // educationData.forEach((education, index) => {
        //     staffDetails.append(`education[${index}][institution]`, education.institution);
        //     staffDetails.append(`education[${index}][score]`, education.score);
        //     staffDetails.append(`education[${index}][year_of_pass]`, education.year_of_pass);
        //     staffDetails.append(`education[${index}][fk_course]`, education.fk_course);
        //     // Add more fields if needed
        // });
    
        // // Append profession (experience) details
        // experienceData.forEach((experience, index) => {
        //     staffDetails.append(`profession[${index}][from_date]`, experience.from_date);
        //     staffDetails.append(`profession[${index}][to_date]`, experience.to_date);
        //     staffDetails.append(`profession[${index}][experience]`, experience.experience);
        //     staffDetails.append(`profession[${index}][fk_profession]`, experience.fk_profession);
        //     staffDetails.append(`profession[${index}][fk_location]`, experience.fk_location);
        //     // Add more fields if needed
        // });
    
        // // Conditionally add staffEditId if it exists
        // if (staffEditId) {
        //     staffDetails.append("user_id", staffEditId);
        // }
        console.log("Submitting Staff Data:", sample);
    
        try {
            setLoading(true);
            const response = await postStaffRegister(sample);
            if (response?.success) {
                console.log("Staff ID:", response?.data);
                Swal.fire('Success', 'Staff details submitted successfully', 'success');
                navigate('/hrms')
            } else {
                Swal.fire("", response?.message, "error");
            }
        } catch (err) {
            console.log(err)
        } finally {
            setLoading(false);
        }
    };

    const handleStaffEdit = (item) =>{
        console.log("handleStaffEdit : @@@@@@@@@@@@@@@@@@@@@",item)
        if (item?.user_data?.username){setUpass(true)}
        else{setUpass(false)}
        setStaffEditId(item?.fk_user)
        setStaffId(item?.user_id)
        setJoinDate(item?.date_of_joined)
        setStaffName(item?.user_data?.first_name)
        setEmailId(item?.user_data?.email)
        setMobile(item?.user_data?.mobile)
        setDob(item?.dob)
        setGender(item?.gender)
        setDistrict(parseInt(item?.district))
        setState(parseInt(item?.state))
        setCountry(parseInt(item?.country))
        setCity(parseInt(item?.city))
        setPincode(parseInt(item?.pincode))
        setAddress1(item?.address_line_1)
        setAddress2(item?.address_line_2)
        setUsername(item?.user_data?.username)
        // setPassword(item?.user_data?.)
        setImage(item?.user_data?.image)
        setOpeningBalance(item?.payscale?.opening_balance)
        setStaffGrade(item?.payscale?.fk_staff_grade)
        setDesignation(item?.payscale?.fk_designation)
        setAllowedLeave(item?.payscale?.allowed_leave)
        setPfCut(item?.payscale?.pf_cut)
        setLeaveCut(item?.payscale?.leave_cut)
        setLicCut(item?.payscale?.lic_cut)
        setSalary(item?.payscale?.salary)
        setEsi(item?.payscale?.esi_cut)
        setSalRemark(item?.payscale?.remark)
        // if (item?.profession) {
        //     const temp = item.profession.map(d => 
        //         d.id ? { ...d, id: "" } : d
        //     );
        //     // console.log("Updated profession list:", temp);
        //     setExperiences(temp)
        // }
        // if (item?.education){
        //     const temp = item.education.map(d => 
        //         d.id ? { ...d, id: "" } : d
        //     );
        //     // console.log("Updated education list:", temp);
        //     
        // } 
        setExperiences(item?.profession)
        setEducations(item?.education)
        getProperties()
    }


return (
    <div>
       <div className=''>
            <Tab.Container activeKey={key} onSelect={(k) => setKey(k)}>
                <div className='col-12'>
                    <Nav className='tabpane-container'>
                        <Nav.Item className='content'>
                            <Nav.Link
                                className='tabpane-item'
                                eventKey='tab1'
                            >
                                <img
                                    src={physician_details}
                                    className='physician-details-icon'
                                    alt=''
                                />&nbsp;
                                Staff Details
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className='content'>
                            <Nav.Link
                                className='tabpane-item'
                                eventKey='tab2'
                            >
                                <img
                                    src={physician_details}
                                    className='physician-details-icon'
                                    alt=''
                                />&nbsp;
                                Salary Details
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className='content'>
                            <Nav.Link
                                className='tabpane-item'
                                eventKey='tab3'
                            >
                                <img
                                    src={physician_details}
                                    className='physician-details-icon'
                                    alt=''
                                />&nbsp;
                                Education Details
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className='content'>
                            <Nav.Link
                                className='tabpane-item'
                                eventKey='tab4'
                            >
                                <img
                                    src={physician_details}
                                    className='physician-details-icon'
                                    alt=''
                                />&nbsp;
                                Experience Details
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </div>
                <div className='pagebody-container'>
                    <Tab.Content className='tabcontent-container'>
                        <TabPane
                            eventKey='tab1'
                            className=''
                        >
                        <StaffDetails
                          handleNext={handleTabChange}
                          handleBack={handleTabBackChange}
                          value={{...{ 
                            staffId,
                            joinDate,
                            staffName,
                            emailId,
                            mobile,
                            dob,
                            gender,
                            district,
                            state,
                            country,
                            city,
                            pincode,
                            address1,
                            address2,
                            username,
                            password,
                            image,
                            cityList,
                            pincodeList,
                            districtList,
                            stateList,
                            countryList,
                            upass,
                          }}}
                          setValue={{...{
                            setStaffId,
                            setJoinDate,
                            setStaffName,
                            setEmailId,
                            setMobile,
                            setDob,
                            setGender,
                            setDistrict,
                            setState,
                            setCountry,
                            setCity,
                            setPincode,
                            setAddress1,
                            setAddress2,
                            setUsername,
                            setPassword,
                            setImage,
                            setUpass,
                          }}}
                          functions={{...{
                            addPatientProperty
                          }}}
                          />
                        </TabPane>
                        <TabPane
                            eventKey='tab2'
                            className=''
                        >
                           <SalaryDetails
                           handleNext={handleTabChange}
                           handleBack={handleTabBackChange}
                            value={{...{
                            openingBalance,
                            staffGrade,
                            designation,
                            allowedLeave,
                            pfCut,
                            leaveCut,
                            licCut,
                            salary,
                            esi,
                            salRemark,
                            designationList,
                            staffGradeList,
                            }}}
                            setValue={{...{
                            setOpeningBalance,
                            setStaffGrade,
                            setDesignation,
                            setAllowedLeave,
                            setPfCut,
                            setLeaveCut,
                            setLicCut,
                            setSalary,
                            setEsi,
                            setSalRemark,
                            }}}
                            functions={{...{
                            addPatientProperty
                            }}}
                           />
                        </TabPane>
                        <TabPane
                            eventKey='tab3'
                            className=''
                        >
                           <EducationDetails
                           handleNext={handleTabChange}
                           handleBack={handleTabBackChange}
                            value={{...{
                            course,
                            passDate,
                            institution,
                            mark,
                            courseList,
                            educations, 
                            edcId,
                            }}}
                            setValue={{...{
                            setCourse,
                            setPassDate,
                            setInstitution,
                            setMark,
                            setEducations,
                            setEdcId,
                            }}}
                            functions={{...{
                            addPatientProperty
                            }}}
                           />
                        </TabPane>
                        <TabPane
                            eventKey='tab4'
                            className=''
                        >
                            {loading ? (
                            <div className="d-flex justify-content-center align-items-center loader-container">
                                <HashLoader color="#e3b075" size={100} />
                            </div>
                            ):
                           <ExperienceDetails
                           handleNext={handleTabChange}
                           handleBack={handleTabBackChange}
                            value={{...{
                            profession,
                            experience,
                            location,
                            fromDate,
                            toDate,
                            professionList,
                            cityList,
                            experiences, 
                            expId,
                            }}}
                            setValue={{...{
                            setProfession,
                            setExperience,
                            setLocation,
                            setFromDate,
                            setToDate,
                            setExperiences,
                            setExpId,
                            }}}
                            functions={{...{
                            addPatientProperty
                            }}}
                            handleSubmit={handleStaffSubmission}
                           /> }
                        </TabPane>
                    </Tab.Content>
                </div>
            </Tab.Container >
        </div>
      
    </div>
  )
}

export default StaffRegistration


