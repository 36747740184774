import React, { useRef, useState, useEffect } from 'react';
import WaveSurfer from 'wavesurfer.js';
import { HiOutlineDownload } from "react-icons/hi";
import { IoPauseOutline } from "react-icons/io5";
import { IoPlayOutline } from "react-icons/io5";
import { FaMicrophoneLines } from "react-icons/fa6";
import { FaRegStopCircle } from "react-icons/fa";
import './AudioRecorder.css';

const AudioRecorder = ({patientDetails}) => {
  const mediaRecorderRef = useRef(null);
  const audioChunks = useRef([]);
  const [recordedAudio, setRecordedAudio] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [waveformVisible, setWaveformVisible] = useState(false);
  const wavesurferRef = useRef(null);
  const [recordingTime, setRecordingTime] = useState(0);
  const timerRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false); 

  // useEffect(() => {
  //   if (recordedAudio && waveformVisible) {
  //     wavesurferRef.current = WaveSurfer.create({
  //       container: '#waveform',
  //       waveColor: 'grey',
  //       progressColor: '#E3B075',
  //       height: 34, 
  //       cursorColor: 'grey', 
  //       cursorWidth: 2.5,
  //       cursorHeight: 1.5,
  //     });
  //     wavesurferRef.current.load(recordedAudio);
  //     wavesurferRef.current.on('finish', () => {
  //       setIsPlaying(false); 
  //     });
  //     return () => {
  //       wavesurferRef.current.destroy();
  //     };
  //   }
  // }, [recordedAudio, waveformVisible]);

  const handleStartRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mediaRecorder = new MediaRecorder(stream);
      mediaRecorderRef.current = mediaRecorder;

      mediaRecorder.ondataavailable = (event) => audioChunks.current.push(event.data);
      mediaRecorder.onstop = () => {
        const audioBlob = new Blob(audioChunks.current, { type: "audio/wav" });
        const audioURL = URL.createObjectURL(audioBlob);
        setRecordedAudio(audioURL);
        audioChunks.current = [];
        setWaveformVisible(true);
        clearInterval(timerRef.current);
        setRecordingTime(0);
      };
      mediaRecorder.start();
      setIsRecording(true);
      startTimer();
    } catch (error) {
      console.error("Microphone access denied:", error);
    }
  };
  const handleStopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    }
  };

  const startTimer = () => {
    setRecordingTime(0);
    timerRef.current = setInterval(() => {
      setRecordingTime((prev) => prev + 1);
    }, 1000);
  };
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
  };
  const handlePlayAudio = () => {
    if (recordedAudio && wavesurferRef.current) {
      wavesurferRef.current.play(); 
      setIsPlaying(true);
    }
  };
  const handlePauseAudio = () => {
    if (wavesurferRef.current) {
      wavesurferRef.current.pause();
      setIsPlaying(false);
    }
  };
  const handleDownloadAudio = () => {
    if (recordedAudio) {
      const link = document.createElement('a');
      link.href = recordedAudio;
      link.download = `${patientDetails?.first_name?.toLowerCase()}${patientDetails?.last_name?.toLowerCase()}.wav`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };
  return (
    // <div className="audio-recorder">
    <div className='d-flex justify-content-between'>
     <div className="recorder-container py-3 w-100">
        <div className='col-2 d-flex justify-content-center align-items-center' >
          <button className='btn text-light rounded-circle p-2 d-flex justify-content-center' onClick={isRecording ? handleStopRecording : handleStartRecording} style={{ backgroundColor: "#E3B075" }} >{isRecording ? <FaRegStopCircle size={20} /> : <FaMicrophoneLines size={20} />}</button>
        </div>
        <div className='col-8'>
            <div className="progress-bar mx-0">
              <div
                className="progress"
                style={{ width: `${(recordingTime / 60) * 100}%`, transition: 'width 1s ease' }} 
              />
            </div>
        </div>
       
        <div className='col-2 d-flex justify-content-center align-items-center'>
        <button className="timer text-light">{formatTime(recordingTime)}</button>
        </div>
      </div>
      <div className='col-2 d-flex justify-content-center align-items-center'>
      <button className="btn text-light rounded-circle p-3 d-flex justify-content-center" onClick={handleDownloadAudio} style={{ backgroundColor: "#986949" }} disabled={recordedAudio?false:true}><HiOutlineDownload size={25}/></button>
      </div>
    </div>
     
    )
      {/* {recordedAudio && (
        <div className="recorder-container py-3 mt-2">
          <div className='col-2 d-flex justify-content-center align-items-center '>
          <button className='btn text-light' onClick={isPlaying ? handlePauseAudio : handlePlayAudio} style={{ backgroundColor: "#E3B075" }}>{isPlaying ? <IoPauseOutline  size={20}  /> :  <IoPlayOutline size={20} />}</button>
          </div>
          <div className="col-8">
          <div id="waveform"></div> 
          </div>
          <div className='col-2 d-flex justify-content-center align-items-center'>
          <button className="btn text-light" onClick={handleDownloadAudio} style={{ backgroundColor: "#E3B075" }}><HiOutlineDownload size={18}/></button>
          </div>
        </div>
      )} */}

    // </div>
};

export default AudioRecorder;
