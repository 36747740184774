import React, { useEffect, useState } from "react";
import "./Ward.css";
import available_bed from "../../../assets/svgs/available-bed.svg";
import total_bed from "../../../assets/svgs/total-bed.svg";
import occupied_bed from "../../../assets/svgs/occupied-bed.svg";
import reserved_bed from "../../../assets/svgs/reserved-bed.svg";

import { Tab, TabPane } from "react-bootstrap";
import { GoPlus } from "react-icons/go";
import { HiOutlineExclamationCircle } from "react-icons/hi2";
import useAccommodationServices from "../../services/api-services/accommodationServices";
import { BsThreeDotsVertical } from "react-icons/bs";
import WardForm from "./components/ward/WardForm";
import Swal from "sweetalert2";
import { Modal } from "react-bootstrap";

const Ward = (props) => {
  const { wardId, setWardId, wardDetails, setWardDetails } = props;
  const { putWardUpdate, postAddBed, getAllWard,getFilteredWard } = useAccommodationServices();

  const [showModal, setShowModal] = useState(false);
  const [allWard, setAllWard] = useState([]);
  const [selectedWardCount, setSelectedWardCount] = useState(0);
  const [bedList, setBedList] = useState([]);
  // const [tempBedList, setTempBedList] = useState([]);
  const [singleWard, setSingleWard] = useState({});
  const [addWard, setAddWard] = useState(false);
  // const [wardDetailsData, setWardDetailsData] = useState({})
  const [privateRoomDetails, setPrivateRoomDetails] = useState({});
  const [privateRoomDetailsID, setPrivateRoomDetailsID] = useState(null);

  //   const [selectedFilter, setSelectedFilter] = useState("all");
  const [selectedFilter, setSelectedFilter] = useState("all");

  // const filteredWard = (value) => {
  //   // if (value === "all") {
  //   //   setBedList(tempBedList);
  //   // } else {
  //   //   const filtereResult = tempBedList.filter(
  //   //     (bed) => bed.bed_status === value
  //   //   );
  //   //   setBedList(filtereResult);
  //   // }

  //   if (value === "all") {
  //     setBedList(tempBedList);
  //   } else {
  //     const filtereResult = tempBedList.filter(
  //       (bed) => bed.bed_status === value
  //     );
  //     setBedList(filtereResult);
  //   }

  //   // console.log(room.status,"=======room.status");
  //   // console.log(selectedFilter,"=======selectedFilter");

  //   // if (selectedFilter === "all") return true;
  //   // return room.status === selectedFilter;
  // };

  const filteredWard = async (value) => {
    try {
          if (value === "all") {
            getAllWardData();
    } else {
      const response = await getFilteredWard(value);
      if (response?.success) {
        setBedList(response?.data[selectedWardCount]?.beds);
        console.log(response?.data[selectedWardCount]?.beds,"===========response filteredWard");
        
      } 
    }
    } catch (error) {
      console.log(error, "error=========");
    }
  };

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);
  const [selectedWard, setSelectedWard] = useState(null);

  const handleSelectWard = (id,index) => {
    const result = allWard.filter((data) => data.id == id)[0];
    setSelectedWardCount(index)
    setSingleWard(result);
    setSelectedWard(id);
    setWardDetails(result);
    setBedList(result?.beds);
    // setTempBedList(result?.beds)
  };

  const handleAddWard = (tab) => {
    setShowModal(true);
    setAddWard(true);
  };


  // const {
  // 	setAddWard,
  // 	handleAddWard,
  // 	setWardId,
  // 	wardId,
  // 	wardDetails,
  // 	setWardDetails,
  // 	handleClose,
  // 	handleShow,
  // 	showModal,
  // 	setShowModal,
  // 	addWard
  // } = props;

  const handleSubmitWardDetails = async () => {
    try {
      const data = JSON.stringify(wardDetails);
      const response = await putWardUpdate(wardDetails.id, data);
      if (response?.success) {
        Swal.fire("", response?.message, "success");
      } else {
        Swal.fire("", response?.message, "error");
      }
    } catch (error) {
      console.log(error, "error=========");
    }
  };

  const handleChangeWardAddition = (e) => {
    const { name, value } = e.target;
    if (name === "eligible_for_insurance") {
      setWardDetails((prev) => ({
        ...prev,
        [name]: !prev.eligible_for_insurance,
      }));
    } else {
      setWardDetails((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  useEffect(() => {
    getAllWardData();
  }, []);

  const getAllWardData = async () => {
    try {
      const response = await getAllWard();
      if (response?.success) {
        setAllWard(response?.data);
        if (selectedWard) {
          const wardData = response?.data.filter(
            (data) => data.id == selectedWard
          )[0];
          setSelectedWard(wardData?.id);
          setSingleWard(wardData);
          setBedList(wardData?.beds);
          // setTempBedList(wardData?.beds);
          setWardDetails(wardData);
        } else {
          setSelectedWard(response?.data[0]?.id);
          setSingleWard(response?.data[0]);
          setBedList(response?.data[0]?.beds);
          // setTempBedList(response?.data[0]?.beds);
          setWardDetails(response?.data[0]);
        }
      }
    } catch {}
  };

  const handleAddBed = async () => {
    try {
      Swal.fire({
        title: "Do you want to add bed ?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "Confirmed",
        denyButtonText: `Denied`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          const data = JSON.stringify({ fk_ward_details: singleWard.id });
          const response = await postAddBed(data);
          if (response?.success) {
            Swal.fire(response?.message, "", "success");
          } else {
            console.log(response, "====no success");
          }
        } else if (result.isDenied) {
          Swal.fire("Not added", "", "info");
        }
      });
    } catch (error) {
      console.log(error, "error=========");
    }
  };

  // console.log(bedList,"======bedList");
  console.log(singleWard, "======singleWard");
  console.log(allWard, "======allWard");

  return (
    <div className="px-2" style={{ boxSizing: "border-box" }}>
      {/* change 1 */}
      {/* <div> */}
      <div className="second-container mb-4 row bg-light rounded">
        <div className="col-8 d-flex  gap-5 ">
          {allWard?.map((data, index) => (
            <p
              className={`d-flex align-items-center all-ward-name ${
                selectedWard === data.id ? "selected-ward" : ""
              }`}
              key={data.id}
              onClick={() => handleSelectWard(data.id,index)}
            >
              {data.ward_name}
            </p>
          ))}
        </div>

        <div className="col-4 details-ward  d-flex align-items-center justify-content-end ">
          <button className="px-4" onClick={handleShow}>
            <HiOutlineExclamationCircle
              size={15}
              style={{ marginRight: "5PX" }}
            />
            Details
          </button>
          <button className="px-4 ms-3" onClick={handleAddWard}>
            <GoPlus size={18} style={{ marginRight: "5PX" }} />
            Add Ward
          </button>
        </div>
      </div>
      <div className="px-2">
        <div className="row gap-2 d-flex mb-5 px-1">
          <div className="col total-bed ">
            <div className="d-flex ">
              <div className="me-3">
                <div className="">
                  <img src={total_bed} alt="" />
                </div>
                <p className="h3 mt-2">{singleWard.total_bed_count}</p>
              </div>
              <div className="">
                <p className="head-bed m-0 mb-1">Total Bed</p>
                <p className=" m-0 p-0 total-no">Total No of beds</p>
              </div>
            </div>
          </div>
          <div className="col total-bed">
            {/* <div className="d-flex border-white shadow w-100 h-100 p-3 rounded"> */}
            <div className="d-flex ">
              <div className="me-3">
                <div className="">
                  <img src={available_bed} alt="" />
                </div>
                <p className="h3 mt-2">{singleWard.available_bed_count}</p>
              </div>
              <div className="">
                <p className="head-bed m-0 mb-1">Available Bed</p>
                <p className=" m-0 p-0 total-no">Total No of Available beds</p>
              </div>
            </div>
          </div>
          <div className="col total-bed">
            <div className="d-flex">
              <div className="me-3">
                <div className="">
                  <img src={occupied_bed} alt="" />
                </div>
                <p className="h3 mt-2">{singleWard.occupied_bed_count}</p>
              </div>
              <div className="">
                <p className="head-bed m-0 mb-1">Occupied Bed</p>
                <p className=" m-0 p-0 total-no">Total No of Occupied beds</p>
              </div>
            </div>
          </div>
          <div className="col total-bed">
            <div className="d-flex">
              <div className="me-3">
                <div className="">
                  <img src={reserved_bed} alt="" />
                </div>
                <p className="h3 mt-2">{singleWard.reserved_bed_count}</p>
              </div>
              <div className="">
                <p className="head-bed m-0 mb-1">Reserved Bed</p>
                <p className=" m-0 p-0 total-no">Total No of Reserved beds</p>
              </div>
            </div>
          </div>
        </div>
        {/* change 2 */}
        {/* </div> */}

        <div className="">
          <div className="row mt-3 mx-2 ">
            <div className="col-4 d-flex align-items-center ">
              <h3>Bed List</h3>
            </div>
            
            <div className="bed-list-sub-out col-8 d-flex justify-content-between  ">
              <button
                className={`px-5 ${
                  selectedFilter === "all" ? "bed-list-sub " : ""
                }`}
                onClick={() => {
                  setSelectedFilter("all");
                  filteredWard("all");
                }}
              >
                All
              </button>
              <button
                className={`px-5 ${
                  selectedFilter === "available" ? "bed-list-sub" : ""
                }`}
                onClick={() => {
                  setSelectedFilter("available");
                  filteredWard("available");
                }}
              >
                Available
              </button>
              <button
                className={`px-5 ${
                  selectedFilter === "occupied" ? "bed-list-sub" : ""
                }`}
                onClick={() => {
                  setSelectedFilter("occupied");
                  filteredWard("occupied");
                }}
              >
                Occupied
              </button>
              <button
                className={`px-5 ${
                  selectedFilter === "reserved" ? "bed-list-sub" : ""
                }`}
                onClick={() => {
                  setSelectedFilter("reserved");
                  filteredWard("reserved");
                }}
              >
                Reserved
              </button>
              <button className="px-4 ward-add-bed" onClick={handleAddBed}>
                <GoPlus size={18} style={{ marginRight: "5px" }} />
                Add Bed
              </button>
              {/* <button className="px-4 bed-list-sub-last"> <GoPlus size={18} style={{ marginRight: "5PX" }} /> Add Bed</button> */}
            </div>
            <div className="col-1"></div>
          </div>
          <Tab.Container activeKey={"tab1"}>
            <div className="patient-list-container">
              <Tab.Content className="patient-table-container container-white">
                <TabPane eventKey="tab1" className="">
                  <div className="table-responsive">
                    <table className="table patient-list-table ">
                      <thead className="patient-list-table-head">
                        <tr>
                          <th style={{ width: 0 }}>Sl.no</th>
                          <th className="text-center" style={{ width: 0 }}>
                            Bed.no
                          </th>
                          <th style={{ width: 0 }}>Patient Name</th>
                          <th style={{ width: 0 }}>Patient ID</th>
                          <th style={{ width: 0 }}>IP Number</th>
                          <th style={{ width: 0 }}>Status</th>
                          <th style={{ width: 0 }}>Actions</th>
                        </tr>
                      </thead>
                      <tbody className="patient-list-table-body">
                        {bedList &&
                          bedList.map((item, index) => {
                            return (
                              <tr key={index} className="position-relative">
                                <td>
                                  <label className="appointment-list-text">
                                    {index + 1}
                                  </label>
                                </td>

                                {/* <td onClick={() => handleShowAppointment(item)}> */}
                                <td className="text-center">
                                  <label
                                    className="appointment-list-text patient-id-color text-uppercase text-wrap "
                                    role="button"
                                  >
                                    {item?.bed_no}
                                  </label>
                                </td>
                                {/* <td>
													<label className="appointment-list-text">
														{item?.patient_details?.mobile}
													</label>
													</td> */}
                                <td>
                                  <label className="appointment-list-text">
                                    {
                                      item?.ip_patient_accommodation_details
                                        ?.patient_info?.full_name
                                    }
                                  </label>
                                </td>
                                <td>
                                  <label className="appointment-list-text">
                                    {
                                      item?.ip_patient_accommodation_details
                                        ?.patient_info?.patient_id
                                    }
                                  </label>
                                </td>
                                <td>
                                  <label className="appointment-list-text">
                                    {
                                      item?.ip_patient_accommodation_details
                                        ?.ip_number
                                    }
                                  </label>
                                </td>
                                <td>
                                  <label
                                    className={`appointment-list-text text-uppercase ${
                                      item?.bed_status == "available"
                                        ? "text-success"
                                        : item?.bed_status == "occupied"
                                        ? "text-danger"
                                        : "text-warning"
                                    }`}
                                  >
                                    {item?.bed_status}
                                  </label>
                                </td>
                                {/* <td >
													<label className="appointment-list-text">
														{item?.admission_date &&
															moment(new Date(item?.admission_date)).format(
															"DD/MM/YYYY"
															)}
													</label>
													
													</td> */}
                                {/* <td className="">
													{
														item?.admission_status == 'pending' ?
														<button>+ Admit</button> :
														<span>Admitted</span>
													}
													</td> */}
                                {/* {isMd ? <td className="">
													<span
														className="appointment-edit-btn"
														// onClick={() => handleAppointmentEdit(item)}
													>
														<MdOutlineModeEditOutline size={18} />
													</span>
													</td>:<td><BsThreeDotsVertical onClick={()=>setActiveDE(prev=>!prev)} /></td>} */}

                                <td>
                                  <div className="d-flex text-center align-items-center">
                                    {/* {
																item?.admission_status == 'pending' ?
																<button className='admit-btn '><GoPlusCircle className='fs-4'/> Admit</button> :
																<span className='admitted-text'>Admitted</span>
															}
															<div className='btn'><BsInfoCircle className='fs-4'/> </div> */}
                                    <div className="btn">
                                      <BsThreeDotsVertical className="fs-3" />
                                    </div>
                                  </div>
                                </td>
                                {/* {isMd && <td className="">
													<span
														className="appointment-del-btn"
														// onClick={() =>
														//   handleDeleteWithConfirmation(item)
														// }
													>
														<DeleteOutlineIcon size={18} />
													</span>
													</td>} */}
                                {/* {activeDE && (
													<div className="d-flex gap-3 position-absolute" style={{right:"60px"}}><span
													className="appointment-edit-btn"
													// onClick={() => handleAppointmentEdit(item)}
													>
													<MdOutlineModeEditOutline size={18} />
													</span>
													<span
														className="appointment-del-btn"
														// onClick={() =>
														//   handleDeleteWithConfirmation(item)
														// }
													>
														<DeleteOutlineIcon size={18} />
													</span>
													</div>
													)} */}
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </TabPane>
              </Tab.Content>
            </div>
          </Tab.Container>
        </div>
        {/* <CenteredModal
				showModal={showModal}
				handleAddWard={handleAddWard}
				setWardId={setWardId}
				wardDetails={wardDetails}
				setWardDetails={setWardDetails} 
				wardId={wardId} 
				singleWard={singleWard} 
				setShowModal={setShowModal} 
				handleClose={handleClose} 
				handleShow={handleShow} 
				addWard={addWard}
				setAddWard={setAddWard}
			/> */}
        {showModal && (
          <div className="">
            <Modal
              show={true}
              centered={true}
              onHide={() => setShowModal(false)}
              size="xl"
              contentClassName="ward-details"
            >
              <Modal.Body>
                <WardForm
                  setAddWard={setAddWard}
                  handleAddWard={handleAddWard}
                  setWardId={setWardId}
                  wardId={wardId}
                  wardDetails={wardDetails}
                  setWardDetails={setWardDetails}
                  setShowModal={setShowModal}
                  addWard={addWard}
                  getAllWardData={getAllWardData}
                />
              </Modal.Body>
            </Modal>
          </div>
        )}
      </div>
    </div>
  );
};

export default Ward;
