import patient_details from '../../../../../assets/svgs/add-icon.svg'
import hourGlassIcon from '../../../../../assets/svgs/appointment/healthicons_patient-band.svg'
import blueCheckIcon from '../../../../../assets/svgs/appointment/carbon_task-complete.svg'
import userListIcon from '../../../../../assets/svgs/appointment/healthicons_inpatient-outline.svg'
import helpIcon from '../../../../../assets/svgs/appointment/carbon_help.svg'
import { FaChevronRight } from "react-icons/fa";

import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { Nav, Tab, TabPane } from 'react-bootstrap'
import './PhysicianAppointmentList.css'
import PatientCountCard from './components/PatientCountCard'
import useConsultationServices from '../../../../services/api-services/consultationServices'

const PhysicianAppointmentList = () => {
    const [appointmentList, setAppointmentList] = useState([])
    const [filterType, setFilterType] = useState('waiting')
    const [total, setTotal] = useState(0)
    const [confirmed, setConfirmed] = useState(0)
    const [completed, setCompleted] = useState(0)

    const navigate = useNavigate()

    const {
        getPhysicianAppointment
    } = useConsultationServices()

    const{
        putPatientAppointment
    }= useConsultationServices()

    useEffect(() => {
        getAppointments()
    }, [filterType])

    const getAppointments = async () => {
        try {
            var data = {
                status: filterType==='waiting' ? 'confirmed' : 'completed'
            }
            const response = await getPhysicianAppointment(data)
            if (response?.success) {
                setAppointmentList(response?.data?.appointments)
                setTotal(response?.data?.total)
                setConfirmed(response?.data?.confirmed)
                setCompleted(response?.data?.completed)
            }
            else {
                Swal.fire("", response?.message, "error")
            }
        } catch { }
    }

    return (
        <div className="">
            <Tab.Container activeKey={"tab1"}>
            <div className="col-12 d-flex justify-content-between align-items-center">
                <div className='col-6 p-2 ps-2 ps-0'>
                    <Nav className='tabpane-container'>
                        <Nav.Item className='content'>
                            <Nav.Link
                                className='tabpane-item'
                                eventKey='tab1'
                            >
                                <img
                                    src={patient_details}
                                    className='details-icon'
                                    alt=''
                                />&nbsp;
                                Appointment List
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </div>
                
                <div className="appointmentList-sub-tabpane col-6 p-4 pe-3 py-0 d-flex justify-content-between">
                    <span className='col-8 p-1'></span>
                    <div className="col-4 p-2 rounded-3 appointmentList-filter-container " style={{backgroundColor:"#eeeeee"}}>
                        <div
                            className={`appointmentList-filter-btn rounded-3 col ${filterType === 'waiting' ? 'active' : ''}`}
                            onClick={() => setFilterType('waiting')}
                        >
                            Waiting
                        </div>
                        <span className='mx-1'></span>
                        <div
                            className={`appointmentList-filter-btn rounded-3 col ${filterType === 'completed' ? 'active' : ''}`}
                            onClick={() => setFilterType('completed')}
                        >
                            Completed
                        </div>
                    </div>
                </div>
                </div>
              
                <div className="patient-list-container col-12 p-4 ps-1  d-flex justify-content-evenly">
                    <div className="col-3 col-4 me-1 p-1 rounded-3" style={{boxShadow:" 0px 0px 4px #FFDBC0"}}><PatientCountCard img={userListIcon} helpIcon={helpIcon} title="Total Patients" subtitle="Total Patients Visited to your Desk" count={total} /></div>
                    <div className="col-3 col-4 me-1 p-1 rounded-3" style={{boxShadow:" 0px 0px 4px #FFDBC0"}}><PatientCountCard img={hourGlassIcon} helpIcon={helpIcon}title="Waiting Patients" subtitle="Total Patients waiting outside" count={confirmed}  /></div>
                    <div className="col-3 col-4 p-1 rounded-3" style={{boxShadow:" 0px 0px 4px #FFDBC0"}}><PatientCountCard img={blueCheckIcon} helpIcon={helpIcon} title="Completed Patients" subtitle="Total Patients Completed to your Desk" count={completed}  /></div>
                </div>
                <div className='patient-list-container ps-1'>
                    <Tab.Content className='patient-table-container container-white'>
                        <TabPane
                            eventKey='tab1'
                            className=''
                        >
                            <table className='table patient-list-table'>
                                <thead className='patient-list-table-head'>
                                    <tr>
                                        <th>Appointment No</th>
                                        <th>Patient Name</th>
                                        <th>Phone Number</th>
                                        <th>Token</th>
                                        <th className='text-center'>Actions</th>
                                        {/* <th style={{ width: 0 }}></th> */}
                                    </tr>
                                </thead>
                                <tbody className='patient-list-table-body'>
                                    {appointmentList&&appointmentList?.map((item, index) => {
                                        const handleConsultation = async()=>{
                                            try{
                                                const data = {
                                                    status:'consulting',
                                                    is_registered_patient: true,
                                                }
                                                const response = await putPatientAppointment(item.id, data)
                                                if(response?.success){
                                                    navigate(`/appointment-consultation/${item.id}/`)
                                                }
                                                else{
                                                    console.log(response)
                                                }
                                            }catch(err){
                                                console.log(err?.response)
                                                navigate(`/appointment-consultation/${item.id}/`)
                                            }
                                        }
                                        return (
                                            <tr key={index} className=''>
                                                <td>
                                                    <label className='appointment-id-text'>
                                                        {item?.appointment_id}
                                                    </label>
                                                </td>
                                                <td>
                                                    <label className='appointment-list-text'>
                                                        {item?.patient_details?.name}
                                                    </label>
                                                </td>
                                                <td>
                                                    <label className='appointment-list-text'>
                                                        {item?.patient_details?.mobile}
                                                    </label>
                                                </td>
                                                <td>
                                                    <label className='appointment-token-text'>
                                                        {item?.token}
                                                    </label>
                                                </td>
                                                <td>
                                                    <div className='d-flex justify-content-evenly'>
                                                        <span className='col-3'></span>
                                                        <div className='btn col-2 col-3 overflow-hidden' style={{backgroundColor:"#BADCF0",color:"#232323"}}>{item?.status}</div>
                                                        <div 
                                                        className='btn col-2 col-3 text-nowrap overflow-hidden'
                                                        style={{backgroundColor:"#1F5EA7",color:"#fafafa"}} 
                                                        onClick={handleConsultation}
                                                        >Consult <FaChevronRight size={18} /></div>
                                                        <span className='col-3'></span>
                                                    </div>
                                                        
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </TabPane>
                    </Tab.Content>
                </div>
            </Tab.Container>
        </div>
    )
}

export default PhysicianAppointmentList
