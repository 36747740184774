import React, { useEffect, useState } from "react";
import {
  DropdownButton,
  Dropdown,
  InputGroup,
  Form,
  Button,
} from "react-bootstrap";
import SearchIcon from "@mui/icons-material/Search";
import { DatePicker } from "antd";
import moment from "moment";
import { useLocation } from "react-router-dom";
import filterIcon from "../../../../../../assets/svgs/filter-icon.svg"
import sortIcon from "../../../../../../assets/svgs/sorting-icon.svg"
import appoIcon from "../../../../../../assets/svgs/appointment.svg"

const FilterSortPagination = ({
  filterOptions,
  sortOptions,
  onFilterSelect,
  onSortSelect,
  onSearchChange,
  onPageChange,
  pagination,
  handleDateChange,
  handleRangeDateChange,
  filterAppointment,
  onFilterAppointment,
  filterType,
  handleReset,
  response,
  setResponse,
  search
}) => {
  const { RangePicker } = DatePicker;
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [selectedSort, setSelectedSort] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDateRange, setSelectedDateRange] = useState([]);
  const [selectedFilterAppointment, setSelectedFilterAppointment] =
    useState(null); // New state for filter by status

  const location = useLocation(); // Get current location

  const isAppointmentListPage = location.pathname === "/appointment-list";
  const isPatientListPage = location.pathname === "/patient-list";
  const isPhysicianListPage = location.pathname === "/physician-list";

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  const handleDatePickerChange = (date, dateString) => {
    handleDateChange(dateString);
    setDropdownOpen(false);
    setSelectedDate(dateString);
    setSelectedFilter(null);
    setSelectedDateRange([]);
  };

  const handleRangePickerChange = (dates) => {
    if (dates && dates.length === 2) {
      const formattedDates = dates.map((date) =>
        moment(date.$d).format("YYYY-MM-DD")
      );
      setSelectedDateRange(formattedDates);
      handleRangeDateChange(formattedDates);
    } else {
      setSelectedDateRange([]);
      handleRangeDateChange([]);
    }
    setDropdownOpen(false);
    setSelectedDate(null); // Clear date when range is selected
    setSelectedFilter(null); // Reset filter when date range is selected
  };

  const formatDate = (dateString) => {
    return dateString ? moment(dateString).format("YYYY-MM-DD") : "Select Date";
  };

  // ===================
  const formatRange = (range) => {
    return range.length === 2
      ? `${moment(range[0]).format("YYYY-MM-DD")} - ${moment(range[1]).format(
          "YYYY-MM-DD"
        )}`
      : "Select Date Range";
  };

  console.log(response,"response from ")

  useEffect(() => {
    if (response) {
      console.log('Resetting states because response is true');
      setSelectedFilter("");
      setSelectedSort("");
      setSelectedDate("");
      setSelectedDateRange([]);
      setSelectedFilterAppointment("");
      setResponse(false)
    }
  }, [response]);

  return (
    <div className="d-flex flex-wrap align-items-center justify-content-between p-0 px-0 mt-2 mb-2">
      {/* Filter and Sorting */}
      <div className="d-flex align-items-center p-0">
        {/* Filter Dropdown */}
        {isAppointmentListPage && (
          <>
            <Dropdown className="me-2">
              <Dropdown.Toggle
                id="filter-dropdown"
                variant="light"
                className="col-2 w-100"
              >
                            <>
            <img
              src={appoIcon}
              alt="Sort Icon"
              className="me-2"
            />
            {selectedFilterAppointment ? selectedFilterAppointment : "All"}
            </>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {filterAppointment.map((option, index) => (
                  <Dropdown.Item
                    key={index}
                    className={`appointmentList-filter-btn mb-1 mx-1 ${
                      option.value === filterType ? "active" : ""
                    }`}
                    onClick={() => {
                      onFilterAppointment(option.value);
                      setSelectedFilterAppointment(option.label); // Update selected filter
                      setSelectedDate(null); // Clear date when filter is selected
                      setSelectedDateRange([]); // Clear date range when filter is selected
                      setSelectedFilter(null); // Clear other filters
                    }}
                  >
                    {option.label}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </>
        )}

        <DropdownButton
          id="filter-dropdown"
          variant="light"
          className="me-2"
          show={dropdownOpen} onToggle={() => setDropdownOpen(!dropdownOpen)}
          autoClose="outside"
          title={
            <>
            <img
              src={filterIcon}
              alt="Sort Icon"
              className="me-2"
            />
            {selectedDateRange.length
              ? `Dates: ${formatRange(selectedDateRange)}`
              : selectedDate
              ? `Date: ${formatDate(selectedDate)}`
              : selectedFilter || "Filter"
            }
          </>
          }
        >
          {/* Date Picker for Single Date Filter */}
          <Dropdown.Item
            as="div"
            className="p-2 appointmentList-filter-btn mb-1 mx-1"
            onClick={stopPropagation}
          >
            <div className="d-flex align-items-center">
              <span className="me-2">Filter by Date:</span>
              <DatePicker
                onChange={handleDatePickerChange}
                className="ms-1"
                needConfirm
              />
            </div>
          </Dropdown.Item>

          {/* Range Picker for Date Range Filter */}
          <Dropdown.Item
            as="div"
            className="p-2 appointmentList-filter-btn mb-1 mx-1"
            onClick={stopPropagation}
          >
            <div className="d-flex align-items-center">
              <span className="me-2">Filter by Date Range:</span>
              <RangePicker
                className="ms-1"
                onChange={handleRangePickerChange}
                needConfirm
              />
            </div>
          </Dropdown.Item>

          {/* Additional Filter Options */}
          {filterOptions.map((option, index) => (
            <Dropdown.Item
              key={index}
              eventKey={option.value}
              onClick={() => {
                onFilterSelect(option.value);
                setDropdownOpen(false);
                setSelectedFilter(option.label);
                setSelectedDate(null); // Clear date when filter is selected
                setSelectedDateRange([]); // Clear date range when filter is selected
              }}
              className="appointmentList-filter-btn mb-1 mx-1"
            >
              {option.label}
            </Dropdown.Item>
          ))}
        </DropdownButton>

        {/* Sorting Dropdown */}
        <DropdownButton
          id="sorting-dropdown"
          // title={selectedSort || "Sorting"}
          title={ 
            <>
              <img
                src={sortIcon}
                alt="Sort Icon"
                className="me-2"
              />
              {selectedSort || "Sorting"}
            </>
            }
          variant="light"
          onSelect={(value) => {
            onSortSelect(value);
            const selectedOption = sortOptions.find(
              (option) => option.value === value
            );
            setSelectedSort(selectedOption ? selectedOption.label : null);
          }}
          autoClose="outside"
        >
          {sortOptions.map((option, index) => (
            <Dropdown.Item
              key={index}
              eventKey={option.value}
              className="appointmentList-filter-btn mb-1 mx-1"
            >
              {option.label}
            </Dropdown.Item>
          ))}
        </DropdownButton>
        {/* Reset Button */}
        {(selectedFilter || selectedSort || selectedDate || selectedDateRange.length || pagination.page>1 || search || selectedFilterAppointment) && (
        <Button variant="danger" className="ms-2" onClick={handleReset}>
        Reset
        </Button>
)}
      </div>


      {/* Pagination */}
      <div className="pagination-container d-flex justify-content-center align-items-center pe-5 pt-2 pt-sm-0">
        {/* Search */}
        <InputGroup className="w-auto flex-grow-1 px-2">
          <InputGroup.Text id="search-icon">
            <SearchIcon />
          </InputGroup.Text>
          <Form.Control
            placeholder={
              isPatientListPage
                ? "Search Patients Here ..."
                : isPhysicianListPage
                ? "Search Physicians Here ..."
                : isAppointmentListPage
                ? "Search Appointments Here ..."
                : "Search Here ..."
            }
            aria-label="Search Patients"
            aria-describedby="search-icon"
            onChange={onSearchChange}
            className="mb-0"
            value={search}
          />
        </InputGroup>
        <div className="d-none d-md-block">
        <Button
          variant="light"
          className="mx-1"
          onClick={() => onPageChange(pagination.page - 1)}
          disabled={pagination.page === 1}
        >
          &laquo; Previous
        </Button>
        <span className="pagination-info mx-2">
          {pagination.page.toString().padStart(2, "0")} of{" "}
          {pagination.totalPages.toString().padStart(2, "0")}
        </span>
        <Button
          variant="warning"
          className="mx-1"
          onClick={() => onPageChange(pagination.page + 1)}
          disabled={pagination.page === pagination.totalPages}
        >
          Next &raquo;
        </Button>
        </div>
      </div>
    </div>
  );
};

export default FilterSortPagination;
