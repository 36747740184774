import React from 'react'
import { Tab, TabPane } from 'react-bootstrap';
import { MdOutlineModeEditOutline } from "react-icons/md";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { BsThreeDotsVertical } from "react-icons/bs";

const ExperienceList = ({value,allExperienceList,handleEdit,handleDelete}) => {
  // const findProfessionName = (id) => {
  //   console.log("id0",id)
  //   return value?.professionList.find(profession => profession.value === id).text;
  // };
  const getProfessionText = (value, id) => {
    const profession = (value?.professionList || [])
      .filter(profession => profession.value === id)
      .map(profession => profession.text);
      
    return profession.length ? profession[0] : undefined;
  };

  const getLocationText = (value, id) => {
    const location = (value?.cityList || [])
      .filter(location => location.value === id)
      .map(location => location.text);
      
    return location.length ? location[0] : undefined;
  };
  // const findLocationName = (id) => {
  //   console.log("id1",id)
  //   return value?.cityList.find(location => location.value === id).text;
  // };
  return (
    <div>
      <Tab.Container activeKey={"tab1"}>
        <div className="patient-list-container p-0">
          <Tab.Content className="patient-table-container container-white">
            <TabPane eventKey="tab1" className="">
              <div className="table-responsive">
                <table className="table patient-list-table">
                  <thead className="patient-list-table-head">
                    <tr>
                      <th>No</th>
                      <th>Profession</th>
                      <th>Experience</th>
                      <th>Location</th>
                      <th>From Date</th>
                      <th>To Date</th>
                      <th style={{ width: 0 }}></th>
                      <th style={{ width: 0 }}></th>
                    </tr>
                  </thead>
                  <tbody className="patient-list-table-body">
                    {allExperienceList !== null &&
                      allExperienceList &&
                      allExperienceList.map((item, index) => {
                        return (
                          <tr key={index} className="position-relative">
                            <td>
                              <label className="appointment-id-text">
                                {index+1}
                              </label>
                            </td>
                            <td>
                              <label className="appointment-list-text text-uppercase text-wrap appointmenttextdata">
                                {getProfessionText(value,item?.fk_profession)}
                              </label>
                            </td>
                            <td>
                              <label className="appointment-list-text">
                                {item?.experience}
                              </label>
                            </td>
                            <td>
                              <label className="appointment-list-text text-center">
                                {getLocationText(value,item?.fk_location)}
                              </label>
                            </td>
                            <td>
                              <label className="appointment-list-text">
                                {item?.from_date? new Date(item?.from_date).toLocaleDateString() : ''}
                              </label>
                            </td>
                            <td>
                              <label className="appointment-list-text">
                                {item?.to_date? new Date(item?.to_date).toLocaleDateString() : ''}
                              </label>
                            </td>
                            <td className="">
                                <span
                                  className="appointment-edit-btn"
                                  onClick={() => handleEdit(index)}
                                >
                                  <MdOutlineModeEditOutline size={18} />
                                </span>
                            </td>
                            <td className="">
                              <span
                                className="appointment-del-btn"
                                onClick={() =>handleDelete(index)}
                              >
                                <DeleteOutlineIcon size={18} />
                              </span>
                            </td>

                            {/* {isMd ? (
                              <td className="">
                                <span
                                  className="appointment-edit-btn"
                                  onClick={() => handleLeaveEdit(item)}
                                >
                                  <MdOutlineModeEditOutline size={18} />
                                </span>
                              </td>
                            ) : (
                              <td>
                                <BsThreeDotsVertical
                                  onClick={() => setActiveDE((prev) => !prev)}
                                />
                              </td>
                            )}
                            {isMd && (
                              <td className="">
                                <span
                                  className="appointment-del-btn"
                                  onClick={() =>
                                    handleDeleteWithConfirmation(item)
                                  }
                                >
                                  <DeleteOutlineIcon size={18} />
                                </span>
                              </td>
                            )}

                            {activeDE && (
                              <div
                                className="d-flex gap-3 position-absolute"
                                style={{ right: "60px" }}
                              >
                                <span
                                  className="appointment-edit-btn"
                                  onClick={() => handleLeaveEdit(item)}
                                >
                                  <MdOutlineModeEditOutline size={18} />
                                </span>
                                <span
                                  className="appointment-del-btn"
                                  onClick={() =>
                                    handleDeleteWithConfirmation(item)
                                  }
                                >
                                  <DeleteOutlineIcon size={18} />
                                </span>
                              </div>
                            )} */}
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </TabPane>
          </Tab.Content>
        </div>
      </Tab.Container>
    </div>
  )
}

export default ExperienceList