import React, { useEffect, useState } from "react";
import "./Ward.css";

import total_bed from "../../../assets/svgs/total-bed.svg";
import available_room from "../../../assets/svgs/available-bed.svg";
import occupied_room from "../../../assets/svgs/occupied-bed.svg";
import reserved_room from "../../../assets/svgs/reserved-bed.svg";

import { LuBedDouble } from "react-icons/lu";
import { Tab, TabPane } from "react-bootstrap";
import { GoPlus } from "react-icons/go";
import { HiOutlineExclamationCircle } from "react-icons/hi2";
import AddRoom from "./AddRoom";
import PrivateDetailModal from "./PrivateDetailModal";
import useAccommodationServices from "../../services/api-services/accommodationServices";
import { BsThreeDotsVertical } from "react-icons/bs";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { Link } from "react-router-dom";

const PrivateRoom = () => {
  const [showModalPrivateDetail, setShowModalPrivateDetail] = useState(false);
  const [showModalPrivateAdd, setShowModalPrivateAdd] = useState(false);
  const [privateRoomDetails, setPrivateRoomDetails] = useState({});

  const [privateRoomDetailsID, setPrivateRoomDetailsID] = useState(null);
  const [roomList, setRoomList] = useState([]);
  const [tempRoomList, setTempRRoomList] = useState([]);
  const [amenities, setAmenities] = useState([]);
  const [instructions, setInstructions] = useState([]);
  const [otherDetails, setOtherDetails] = useState([]);
  const [addIconChange, setAddIconChange] = useState(false);
  const [selectedRoomCount, setSelectedRoomCount] = useState(0);
  const [selectedFilter, setSelectedFilter] = useState("all");


  const [privateRoomData, setPrivateRoomData] = useState({
    room_name: "",
    floor: "",
    room_number: "",
    fk_private_room_details: null,
  });
  const [tempRoom, setTempRoom] = useState(null);

  const [privateRoomDetailsData, setPrivateRoomDetailsData] = useState({
    room_type_name: "Private Room",
    air_conditioned: false,
    rate_per_day: 0,
    private_type: "private",
  });

const { getPrivateRoomDetails,getFilteredPrivateRoom } = useAccommodationServices();

  const handleClosePrivateDetail = () => setShowModalPrivateDetail(false);
  const handleShowPrivateDetail = () => setShowModalPrivateDetail(true);

  const handleClosePrivateAdd = () => {

    console.log("handleClosePrivateAdd");

    setShowModalPrivateAdd(false);
    setTempRoom(null)
 
    setPrivateRoomData((prev) => ({
      ...prev,
      room_name: "",
      floor: "",
      room_number: "",
    }));
    setPrivateRoomDetailsData({
      room_type_name: "Private Room",
      air_conditioned: false,
      rate_per_day: 0,
      private_type: "private",
    });

  };

  // const filteredPrivateRoom = (value) => {
  //   if (value === "all") {
  //     setRoomList(tempRoomList);
  //   } else {
  //     const filtereResult = tempRoomList.filter(
  //       (bed) => bed.bed_status === value
  //     );
  //     setRoomList(filtereResult);
  //   }
  // };

  const filteredPrivateRoom = async (value) => {
    try {
          if (value === "all") {
            getPrivateRoomDetailsData()
    } else {
      const response = await getFilteredPrivateRoom(value);
      if (response?.success) {
        setRoomList(response?.data[selectedRoomCount]?.private_rooms);        
      } 
    }
    } catch (error) {
      console.log(error, "error=========");
    }
  };

  
  const handleShowPrivateAdd = () => {

    setPrivateRoomData((prev) => ({
      ...prev,
      room_name: "",
      floor: "",
      room_number: "",
    }));
    setShowModalPrivateAdd(true);

  }


  useEffect(() => {
    getPrivateRoomDetailsData();
  }, []);

  useEffect(() => {
    if (Object.keys(privateRoomDetails).length === 0) {
      setAddIconChange(false);
    } else {
      setAddIconChange(true);
    }
  }, [privateRoomDetails]);

  const getPrivateRoomDetailsData = async () => {
    try {
      const response = await getPrivateRoomDetails();

      if (response?.success) {
        console.log(response?.data[0],"========getPrivateRoomDetailsData");
        
        setPrivateRoomDetails(response?.data[0]);

        setPrivateRoomDetailsData({
          ...privateRoomDetailsData,
          rate_per_day: response?.data[0]?.rate_per_day,
        });
        setPrivateRoomDetailsID(response?.data[0]?.id);
        setAmenities(response?.data[0]?.amenities);
        setOtherDetails(response?.data[0]?.other_details);
        setInstructions(response?.data[0]?.instructions);
        setRoomList(response?.data[0]?.private_rooms);
        setTempRRoomList(response?.data[0]?.private_rooms);
        setPrivateRoomData({
          ...privateRoomData,
          fk_private_room_details: response?.data[0]?.id,
        });
        // setSelectedPrivateRoomId(response?.data[0]?.ward_id)
        // setSinglePrivateRoom(response?.data[0])
      }
    } catch {}
  };

  const handleEditRoomList = (id)=>{
    
    const filteredRoom = roomList.filter((data) => data.id === id);
    setTempRoom({
      id:filteredRoom[0]?.id,
      fk_private_room_details:filteredRoom[0]?.fk_private_room_details,
      room_name: filteredRoom[0]?.room_name,
      floor: filteredRoom[0]?.floor,
      room_number: filteredRoom[0]?.room_number,
      room_status:filteredRoom[0]?.room_status
    })
    
    handleShowPrivateAdd()
  }

  return (
    <div className="px-2">
      <div className="">
        <div className="second-container row bg-light rounded">
          <div className="col-8 d-flex  gap-5 ">
            <p className="  d-flex align-items-center selected-ward pb-1">
              Rooms
            </p>
          </div>
          <div className="col-4 details-ward  d-flex align-items-center justify-content-end  ">
            <button className="px-4" onClick={handleShowPrivateDetail}>
              {addIconChange ? (
                <HiOutlineExclamationCircle
                  size={15}
                  style={{ marginRight: "5PX" }}
                />
              ) : (
                <GoPlus size={18} style={{ marginRight: "5PX" }} />
              )}{" "}
              Details
            </button>
            {/* <button className="px-4" onClick={handleShowPrivateAdd} ><GoPlus size={18} style={{ marginRight: "5PX" }} />Add Room</button> */}
            <button></button>
          </div>
        </div>
      </div>

      <div className="row gap-2 d-flex mt-3 px-1">
        <div className="col total-bed">
          <div className="d-flex ">
            <div className="me-3">
              <div className="">
                <img src={total_bed} alt="" />
              </div>
              <p className="h3 mt-2">{privateRoomDetails?.total_room_count}</p>
            </div>
            <div className="">
              <p className="head-bed m-0 mb-1">Total Room</p>
              <p className=" m-0 p-0 total-no">Total No. of Rooms</p>
            </div>
          </div>
        </div>
        <div className="col total-bed">
          <div className="d-flex">
            <div className="me-3">
              <div className="">
                <img src={available_room} alt="" />
              </div>
              <p className="h3 mt-2">
                {privateRoomDetails?.available_room_count}
              </p>
            </div>
            <div className="">
              <p className="head-bed m-0 mb-1">Available Room</p>
              <p className=" m-0 p-0 total-no">Total No. of Available Rooms</p>
            </div>
          </div>
        </div>
        <div className="col total-bed">
          <div className="d-flex">
            <div className="me-3">
              <div className="">
                <img src={occupied_room} alt="" />
              </div>
              <p className="h3 mt-2">
                {privateRoomDetails?.occupied_room_count}
              </p>
            </div>
            <div className="">
              <p className="head-bed m-0 mb-1">Occupied Room</p>
              <p className=" m-0 p-0 total-no">Total No. of Occupied Rooms</p>
            </div>
          </div>
        </div>
        <div className="col total-bed">
          <div className="d-flex ">
            <div className="me-3">
              <div className="">
                <img src={reserved_room} alt="" />
              </div>
              <p className="h3 mt-2">
                {privateRoomDetails?.reserved_room_count}
              </p>
            </div>
            <div className="">
              <p className="head-bed m-0 mb-1">Reserved Room</p>
              <p className=" m-0 p-0 total-no">Total No. of Reserved Rooms</p>
            </div>
          </div>
        </div>
      </div>

      <div className="">
        <div className="row mt-3 mx-2 ">
          <div className="col-4 d-flex align-items-center ">
            <h3>Room List</h3>
          </div>
          {/* <div className="bed-list-sub-out col-6 d-flex justify-content-between  ">
            <button className="px-5 bed-list-sub">All</button>
            <button>Available</button>
            <button>Occupied</button>
            <button>Reserved</button>
            <button
              className="px-4 private-add-room"
              onClick={handleShowPrivateAdd}
            >
              <GoPlus size={18} style={{ marginRight: "5PX" }} />
              Add Room
            </button>
          </div> */}

            <div className="bed-list-sub-out col-8 d-flex justify-content-between  ">
              <button
                className={`px-5 ${
                  selectedFilter === "all" ? "bed-list-sub " : ""
                }`}
                onClick={() => {
                  setSelectedFilter("all");
                  filteredPrivateRoom("all");
                }}
              >
                All
              </button>
              <button
                className={`px-5 ${
                  selectedFilter === "available" ? "bed-list-sub" : ""
                }`}
                onClick={() => {
                  setSelectedFilter("available");
                  filteredPrivateRoom("available");
                }}
              >
                Available
              </button>
              <button
                className={`px-5 ${
                  selectedFilter === "occupied" ? "bed-list-sub" : ""
                }`}
                onClick={() => {
                  setSelectedFilter("occupied");
                  filteredPrivateRoom("occupied");
                }}
              >
                Occupied
              </button>
              <button
                className={`px-5 ${
                  selectedFilter === "reserved" ? "bed-list-sub" : ""
                }`}
                onClick={() => {
                  setSelectedFilter("reserved");
                  filteredPrivateRoom("reserved");
                }}
              >
                Reserved
              </button>
              <button
              className="px-4 private-add-room"
              onClick={handleShowPrivateAdd}
            >
              <GoPlus size={18} style={{ marginRight: "5PX" }} />
              Add Room
            </button>
            </div>
        </div>
        <Tab.Container activeKey={"tab1"}>
          <div className="patient-list-container">
            <Tab.Content className="patient-table-container container-white">
              <TabPane eventKey="tab1" className="">
                <div className="table-responsive">
                  <table className="table patient-list-table ">
                    <thead className="patient-list-table-head">
                      <tr>
                        <th style={{ width: 0 }}>Sl.no</th>
                        <th style={{ width: 0 }}>Room ID</th>
                        <th style={{ width: 0 }}>Room Name</th>
                        <th style={{ width: 0 }}>Floor/Room No</th>
                        <th style={{ width: 0 }}>Patient Name</th>
                        <th style={{ width: 0 }}>Patient Id</th>
                        <th style={{ width: 0 }}>IP Number</th>
                        <th style={{ width: 0 }}>Status</th>
                        <th style={{ width: 0 }}>Action</th>
                      </tr>
                    </thead>
                    <tbody className="patient-list-table-body">
                      {roomList &&
                        roomList.map((item, index) => {
                          return (
                            <tr key={index} className="position-relative">
                              <td>
                                <label className="appointment-list-text">
                                  {index + 1}
                                </label>
                              </td>

                              {/* <td onClick={() => handleShowAppointment(item)}> */}
                              <td className="">
                                <label
                                  className="appointment-list-text patient-id-color text-uppercase text-wrap "
                                  role="button"
                                >
                                  {item?.room_id}
                                </label>
                              </td>
                              <td className="">
                                <label
                                  className="appointment-list-text text-wrap "
                                  role="button"
                                >
                                  {item?.room_name}
                                </label>
                              </td>
                              <td className="">
                                <label
                                  className="appointment-list-text text-wrap "
                                  role="button"
                                >
                                  {`${item?.floor}/${item?.room_number}`}
                                </label>
                              </td>
                              {/* <td>
													<label className="appointment-list-text">
														{item?.patient_details?.mobile}
													</label>
													</td> */}
                              <td>
                                <label className="appointment-list-text">
                                  {
                                    item?.ip_patient_accommodation_details
                                      ?.patient_info?.full_name
                                  }
                                </label>
                              </td>
                              <td>
                                <label className="appointment-list-text">
                                  {
                                    item?.ip_patient_accommodation_details
                                      ?.patient_info?.patient_id
                                  }
                                </label>
                              </td>
                              <td>
                                <label className="appointment-list-text">
                                  {
                                    item?.ip_patient_accommodation_details
                                      ?.ip_number
                                  }
                                </label>
                              </td>
                              <td>
                                <label
                                  className={`appointment-list-text text-uppercase ${
                                    item?.room_status == "available"
                                      ? "text-success"
                                      : item?.room_status == "occupied"
                                      ? "text-danger"
                                      : "text-warning"
                                  }`}
                                >
                                  {item?.room_status}
                                </label>
                              </td>
                              {/* <td >
													<label className="appointment-list-text">
														{item?.admission_date &&
															moment(new Date(item?.admission_date)).format(
															"DD/MM/YYYY"
															)}
													</label>
													
													</td> */}
                              {/* <td className="">
													{
														item?.admission_status == 'pending' ?
														<button>+ Admit</button> :
														<span>Admitted</span>
													}
													</td> */}
                              {/* {isMd ? <td className="">
													<span
														className="appointment-edit-btn"
														// onClick={() => handleAppointmentEdit(item)}
													>
														<MdOutlineModeEditOutline size={18} />
													</span>
													</td>:<td><BsThreeDotsVertical onClick={()=>setActiveDE(prev=>!prev)} /></td>} */}

                              <td>
                                <div className="d-flex text-center align-items-center">
                                  {/* {
																item?.admission_status == 'pending' ?
																<button className='admit-btn '><GoPlusCircle className='fs-4'/> Admit</button> :
																<span className='admitted-text'>Admitted</span>
															}
															<div className='btn'><BsInfoCircle className='fs-4'/> </div> */}
                                  <div className="btn">
                                    {/* <BsThreeDotsVertical className="fs-3" /> */}
                                    <span className="appointment-edit-btn">
                                    <MdOutlineModeEditOutline size={18} onClick={()=>handleEditRoomList(item?.id)} />
                                    </span>
                                  </div>
                                </div>
                              </td>
                              {/* {isMd && <td className="">
													<span
														className="appointment-del-btn"
														// onClick={() =>
														//   handleDeleteWithConfirmation(item)
														// }
													>
														<DeleteOutlineIcon size={18} />
													</span>
													</td>} */}
                              {/* {activeDE && (
													<div className="d-flex gap-3 position-absolute" style={{right:"60px"}}><span
													className="appointment-edit-btn"
													// onClick={() => handleAppointmentEdit(item)}
													>
													<MdOutlineModeEditOutline size={18} />
													</span>
													<span
														className="appointment-del-btn"
														// onClick={() =>
														//   handleDeleteWithConfirmation(item)
														// }
													>
														<DeleteOutlineIcon size={18} />
													</span>
													</div>
													)} */}
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </TabPane>
            </Tab.Content>
          </div>
        </Tab.Container>
      </div>
      <AddRoom
        showModalPrivateAdd={showModalPrivateAdd}
        getPrivateRoomDetailsData={getPrivateRoomDetailsData}
        privateRoomData={privateRoomData}
        setPrivateRoomData={setPrivateRoomData}
        handleClosePrivateAdd={handleClosePrivateAdd}
        tempRoom={tempRoom}
        setTempRoom={setTempRoom}
      />
      <PrivateDetailModal
        privateRoomDetailsID={privateRoomDetailsID}
        showModalPrivateDetail={showModalPrivateDetail}
        setShowModalPrivateDetail={setShowModalPrivateDetail}
        handleClosePrivateDetail={handleClosePrivateDetail}
        privateRoomDetailsData={privateRoomDetailsData}
        setPrivateRoomDetailsData={setPrivateRoomDetailsData}
        amenities={amenities}
        setAmenities={setAmenities}
        instructions={instructions}
        setInstructions={setInstructions}
        otherDetails={otherDetails}
        setOtherDetails={setOtherDetails}
        getPrivateRoomDetailsData={getPrivateRoomDetailsData}
        addIconChange={addIconChange}
      />
    </div>
  );
};

export default PrivateRoom;
