import React, { useState } from "react";

const AdmInsurance = ({ handleBack, handleSubmit ,value,setValue}) => {

  return (
    <div className="row">
      <div className="col-12 ps-4 pe-5 ">
        <h4 className="ps-4 pt-4">Insurance Details</h4>
        <hr className="ms-2" />
        <div className="col-12 row p-4">
          <div className="col-6">

            <div className="col-12 pt-3 pb-3">
            <label className=" label-text-content">Provider Name</label>
              <input
                type="text"
                className="p-2 border border-secondary rounded-3 w-100 "
                onChange={(e)=>setValue?.setProviderName(e.target.value)}
                value={value?.providerName}
              />
            </div>

            
            <div className="col-12 pt-3 pb-3">
            <label className=" label-text-content">Policy Holder Name</label>
              <input
                type="text"
                className="p-2 border border-secondary rounded-3 w-100 "
                onChange={(e)=>setValue?.setHolderName(e.target.value)}
                value={value?.holderName}
              />
            </div>

            
            <div className="col-12 pt-3 pb-3">
            <label className=" label-text-content">Policy Contact Number</label>
              <input
                type="text"
                className="p-2 border border-secondary rounded-3 w-100 "
                onChange={(e)=>setValue?.setHolderContact(e.target.value)}
                value={value?.holderContact}
              />
            </div>

            
            <div className="col-12 pt-3 pb-3">
              <label className=" label-text-content">Date of birth</label>
              <input
                type="date"
                className="p-2 border border-secondary rounded-3 w-100 "
                onChange={(e)=>setValue?.setDob(e.target.value)}
                value={value?.dob}
              />
            </div>

            <div className="col-12 pt-3 pb-3 d-flex ">
              <div className="col-6 pe-1">
                <label className=" label-text-content">Start Date of Validity</label>
                <input
                  type="date"
                  className="p-2 border border-secondary rounded-3 w-100 "
                  onChange={(e)=>setValue?.setStartDate(e.target.value)}
                  value={value?.startDate}
                />
              </div>

              <div className="col-6 ps-1">
                <label className=" label-text-content">End Date of Validity</label>
                <input
                  type="date"
                  className="p-2 border border-secondary rounded-3 w-100 "
                  onChange={(e)=>setValue?.setEndDate(e.target.value)}
                  value={value?.endDate}
                />
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="col-12 p-5">
              <div className="p-4 pb-5"></div>
              <div className="p-2 pb-3"></div>
            </div>
             
          <div className="col-12 pt-2 pb-3">
            <label className=" label-text-content">Plan Name</label>
              <input
                type="text"
                className="p-2 border border-secondary rounded-3 w-100 "
                onChange={(e)=>setValue?.setPlanName(e.target.value)}
                value={value?.planName}
              />
            </div>

            
            <div className="col-12 pt-3 pb-3">
            <label className=" label-text-content">Policy Number</label>
              <input
                type="text"
                className="p-2 border border-secondary rounded-3 w-100 "
                onChange={(e)=>setValue?.setPolicyNumber(e.target.value)}
                value={value?.policyNumber}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="co1-12 d-flex justify-content-end p-2">
          <div className="buttonviewdown" onClick={handleBack}>
            Back
          </div>
          <div className="buttonviewdown" onClick={handleSubmit}>
            Submit
          </div>
        </div>
      </div>
    </div>
  );
};
export default AdmInsurance;
