import React from 'react'
import { PiPencil } from "react-icons/pi";
import { RiDeleteBinLine } from "react-icons/ri";
import Swal from 'sweetalert2';
const DocumentUploadList = ({ data,datalist,setDFileId,patientDelete,setDList,setDvalidity,setDIssueDate,setDCategory}) => {
    const handleUpdate = (d) =>{
        let cat=datalist.filter(x=>x.text.toUpperCase() === d.category.toUpperCase())
        setDCategory(cat[0].value)
        setDIssueDate(d.issued_date)
        setDList(d.documents)
        setDvalidity(d.validity_upto)
        setDFileId(d.id)
    }
    const handleDeleteWithConfirmation = (data) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                handleDelete(data);
                Swal.fire(
                    'Deleted!',
                    'Your file has been deleted.',
                    'success'
                );
            }
        });
    };
    const handleDelete = (d) =>{
        // console.log("ooooooooooi",d)
        setDFileId(d.id)
        patientDelete()
    }
    return (
        <div className='mt-4'>
            <div className='align-items-center row mx-0 px-3'>
                <div className='upload-list-title col-1 ps-0'>Sl.no</div>
                <div className='upload-list-title col-5'>Category</div>
                <div className='upload-list-title col-2 px-0'>Issued Date</div>
                <div className='upload-list-title col-2'>Expiry</div>
            </div>
            <div className={`upload-list-container row ps-2`}>
                {data.map((file, index) => (
                    <div className={`d-flex upload-list-data col-12 row ms-0 ps-1`} key={index}>
                        <div
                            className="col-1 px-0 d-flex justify-content-center"
                        // style={{marginLeft:'0.25rem'}}
                        >
                            <div className='number '>{index + 1}</div>
                        </div>
                        <div className="text col-5 pe-0">{file.category}</div>
                        <div className="text col-2">{file.issued_date.split('-').reverse().join('-')}</div>
                        <div className="text col-2 me-0">{file.validity_upto.split('-').reverse().join('-')}</div>
                        <div className="text col-1 me-0"><PiPencil size={25} className='text-light rounded p-1' style={{backgroundColor:"#0a2278"}} onClick={()=>handleUpdate(file)}/></div>
                        <div className="text col-1 me-0"><RiDeleteBinLine size={25} className='text-light rounded p-1' style={{backgroundColor:"#de7c7c"}} onClick={()=>handleDeleteWithConfirmation(file)}/></div>
                    </div>
                ))}
                <div className='p-1'>

                </div>
            </div>
        </div>
    )
}

export default DocumentUploadList
