import React, { useEffect, useState } from "react";
import MultiInputBox from "../MultiInputBox";
import useAccountServices from "../../../../services/api-services/accountServices";
import useAccommodationServices from "../../../../services/api-services/accommodationServices";
import Swal from "sweetalert2";
import AccommodationDetails from "../AccommodationDetails";

const EditSuitRoom = ({
  handleClosePrivateSuit,
  editSuitRoomData,
  setEditSuitRoomData,
}) => {
  const { putSuteEdit } = useAccommodationServices();

  const addAmentiesAvailable = (e) => {};
  const deleteAmenties = (e) => {};
  const addInstructions = (e) => {};
  const deleteInstructions = (e) => {};
  
  const handleChangeSuteAddition = (e) => {
    const { name, value } = e.target;
    if (name === "eligible_for_insurance") {
      setEditSuitRoomData((prev) => ({
        ...prev,
        [name]: !prev.eligible_for_insurance,
      }));
    } else {
      setEditSuitRoomData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleSubmitSuteEdit = async () => {
    try {
      const data = JSON.stringify(editSuitRoomData);
      const response = await putSuteEdit(editSuitRoomData.id, data);
      if (response?.success) {
        Swal.fire("", response?.message, "success");
        handleClosePrivateSuit();
      } else {
        Swal.fire("", response?.message, "error");
      }
    } catch (error) {
      console.log(error, "error=========");
    }
  };

  return (
    <div className="px-2">
      <div className="private-suit-container mb-4 row rounded-3  bg-light">
        <div className="col-8 h-100 ">
          <p className="private-suit-head d-flex align-items-center">
            Private Suite Edit
          </p>
        </div>

        <div className="col-4 details-ward  d-flex align-items-center justify-content-end ">
          <button className="px-3" onClick={handleClosePrivateSuit}>
            {/* <HiOutlineExclamationCircle
              size={15}
              style={{ marginRight: "5PX" }}
            /> */}
            Cancel
          </button>
          <button className="px-4 ms-3" onClick={handleSubmitSuteEdit}>
            {/* <GoPlus size={18} style={{ marginRight: "5PX" }} /> */}
            Save
          </button>
        </div>
      </div>

      <div
        className="w-100 h-100 private-suit-container pb-3 mb-5"
        role="document"
      >
        <div className="modal-content  px-3">
          <div className="row gx-5 mt-4">
            <div className="col-md-6">
              <label htmlFor="" className="modal-label">
                Private suite ID
              </label>
              <div className="p-2 border bg-light rounded">
                <input
                  type="text"
                  name="suite_id"
                  value={editSuitRoomData.suite_id}
                  className="w-100 h-100"
                  readOnly
                />
              </div>
            </div>
            <div className="col-md-6">
              <label htmlFor="" className="modal-label">
                Private suite Name
              </label>
              <div className="p-2 border bg-light rounded ">
                <input
                  type="text"
                  name="suite_name"
                  value={editSuitRoomData.suite_name}
                  onChange={handleChangeSuteAddition}
                  className="w-100 h-100"
                />
              </div>
            </div>
          </div>

          <div className="row gx-5 mt-3">
            <div className="col-md-6">
              <div className="row ">
                <div className="col-12">
                  <label htmlFor="" className="modal-label">
                    Suite No
                  </label>
                  <div className="p-2 border bg-light rounded">
                    <input
                      type="text"
                      value={editSuitRoomData.suite_no}
                      name="suite_no"
                      onChange={handleChangeSuteAddition}
                      className="w-100 h-100"
                    />
                  </div>
                </div>
                <div className="col-12 mt-3">
                  <label htmlFor="" className="modal-label">
                    Rate per day
                  </label>
                  <div className="p-2 border bg-light rounded">
                    <input
                      type="text"
                      value={editSuitRoomData.rate_per_day}
                      name="rate_per_day"
                      onChange={handleChangeSuteAddition}
                      className="w-100 h-100"
                    />
                  </div>
                </div>
                <div className="col-12 mt-3">
                  {/* <label htmlFor=""></label> */}
                  <div className="p-2  d-flex align-items-center ">
                    <input
                      type="checkbox"
                      //   onChange={handleChangeWardAddition}
                      //   checked={!addWard && wardDetails.eligible_for_insurance}
                      name="eligible_for_insurance"
                      className="me-2"
                      onChange={handleChangeSuteAddition}
                      checked={editSuitRoomData.eligible_for_insurance}
                    />
                    <label htmlFor="" className="modal-label">
                      Eligible for insurance
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              {/* <div className="col-md-6"> */}
              <label htmlFor="" className="modal-label">
                Set Floor
              </label>
              <div className="p-2 border bg-light rounded">
                <input
                  type="text"
                  value={editSuitRoomData.floor_no}
                  onChange={handleChangeSuteAddition}
                  name="floor_no"
                  className="w-100 h-100"
                />
              </div>
              {/* </div> */}
              <label htmlFor="" className="modal-label mt-3">
                Room Image
              </label>
              <div className="p-2  d-flex align-items-center">
                <div className="p-2 d-flex align-items-center">
                  <input
                    type="file"
                    id="file-upload"
                    style={{
                      display: "none",
                    }}
                  />
                  <label
                    htmlFor="file-upload"
                    className="d-flex align-items-center justify-content-center img-upload-suit"
                  >
                    Choose file
                  </label>
                </div>
                <div className="p-2 d-flex align-items-center">
                  <input
                    type="file"
                    id="file-upload"
                    style={{
                      display: "none",
                    }}
                  />
                  <label
                    htmlFor="file-upload"
                    className="d-flex align-items-center justify-content-center img-upload-suit"
                  >
                    Choose file
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className="row gx-5 mt-3 mb-4">
            <div className="col-md-6">
              <div className="row ">
                <div className="col-12">
                  {/* <label htmlFor="" className="modal-label">
                    Amenties Available
                  </label> */}
                  <div className="p-2 border bg-light rounded">
                    <MultiInputBox
                      label={"Available Amenities"}
                      data={[]}
                      onAdd={(value) =>
                        addAmentiesAvailable(
                          value,
                          "room",
                          "amenity",
                          null
                          // privateRoomDetailsID
                        )
                      }
                      onRemove={(id) =>
                        deleteAmenties(
                          id,
                          "room",
                          "amenity",
                          null
                          // privateRoomDetailsID
                        )
                      }
                      containerClass={"col-12 mb-3"}
                      icon={"+"}
                      // disabled={!isActive}
                    />
                  </div>
                </div>
                <div className="col-12 mt-2">
                  <label htmlFor="" className="modal-label">
                    Other Details
                  </label>
                  <div className="p-2 border bg-light rounded">
                    <div className="p-2 d-flex align-items-center">
                      <textarea
                        className="w-100  border-0 p-0"
                        style={{
                          height: "3.5rem",
                          resize: "none",
                          outline: "none",
                          backgroundColor: "transparent",
                        }}
                        // placeholder="Enter text here..."
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <label htmlFor="" className="modal-label">
                Instructions
              </label>
              <div className="" style={{ height: "90%" }}>
                <AccommodationDetails
                  data={[]}
                  onAdd={(value) =>
                    addInstructions(
                      value,
                      "room",
                      "instruction",
                      null
                      // privateRoomDetailsID
                    )
                  }
                  onRemove={(id) =>
                    deleteInstructions(
                      id,
                      "room",
                      "instruction",
                      null
                      // privateRoomDetailsID
                    )
                  }
                  iconCol={true}
                  // disabled={!isActive}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditSuitRoom;
