import React from 'react'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import useAxios from '../../hooks/useAxios'

const useAccountServices = () => {
    const axiosPrivate = useAxiosPrivate()
    const axios = useAxios()

    // GET REQUESTS

    //get card content templates

    const getCardTemplates = async (data) => {
      const response = await axiosPrivate.get('/account/card_templates/', {
        params: {
          card_type:data,
        }
      })
      return response?.data
    }

    const getDashboard = async (data) => {
      const response = await axiosPrivate.get('/account/dashboard/')
      return response?.data
    }

    //get all id types

    const getIDTypes = async () => {
      const response = await axiosPrivate.get('/account/id_types')
      return response?.data
    }

    //get user id's

    const getNewID = async (data) => {
      const response = await axiosPrivate.get('/account/config/id/', {
        params: {
          id_type:data,
        }
      })
      return response?.data
    }

    // POST REQUESTS

    //login api

    const login = async (data) => {

        try {
            const response = await axios.post('/account/login/', data)
            return response.data
        } catch (err) {
            throw new Error("Login fialed")
        }
    };

    //post id config

    const postIdCofig = async (data) => {
      const response = await axiosPrivate.post('/account/config/id/', data)
      return response?.data
    }

    const postStaffRegister = async (data)=>{
      const response = await axiosPrivate.post('/HRMS/staff/create/',data
      //   {
      //   headers: { "Content-Type": "multipart/form-data" },
      // }
    );
      return response?.data
    }

    const getStaffList = async (data)=>{
      const response = await axiosPrivate.get('/HRMS/staff/create/')
      return response?.data
    }

    const updateStaffDetail = async (id,data)=>{
      const response = await axiosPrivate.post(`/HRMS/staff/detail/${id}`,data
      //   {
      //   headers: { "Content-Type": "multipart/form-data" },
      // }
    );
      return response?.data
    }

    const deleteStaffDetail = async (id,password)=>{
      const response = await axiosPrivate.delete(`/HRMS/staff/detail/${id}`,{
        data: { password: password },
      })
      return response?.data
    }

    const getAllAttendenceList = async (data)=>{
      const response = await axiosPrivate.get('/HRMS/all/staff/attendance/',{params:data})
      return response?.data
    }

    const postBulkUploadAttendance = async(data)=>{
      const response =await axiosPrivate.post(`/HRMS/bulk/staff/attendance/`,data)
      return response.data
  }

  const postStaffAttendance =async(id,data)=>{
    const response =await axiosPrivate.post(`/HRMS/single/staff/attendance/${id}/`,data)
      return response.data
  }
    // PUT REQUESTS

    // DELETE REQUESTS

    const getStaffAttendanceReport = async () => {
      const response = await axiosPrivate.get('/account/staff/attendance/report/')
      return response?.data
    }
  
    const getStaffAttendanceReportByDate = async (fromDate,toDate) => {
    	const response = await axiosPrivate.get('/account/staff/attendance/report/',{
    		params: {
    			from_date:fromDate,
    			to_date:toDate,
    		  }
    	})
    	return response?.data
    }
  
    const getStaffDetail = async (id) => {
      const response = await axiosPrivate.get(`/account/staff/detail/${id}/`)
      return response?.data
    }

  return {
    // GET services
    getCardTemplates,
    getIDTypes,
    getNewID,
    getDashboard,
    getStaffList,
    getAllAttendenceList,

    // POST services
    login,
    postIdCofig,
    postStaffRegister,
    postBulkUploadAttendance,
    postStaffAttendance,
    getStaffDetail,
    getStaffAttendanceReport,
    getStaffAttendanceReportByDate,


    // PUT services
    updateStaffDetail,
    // DELETE services
    deleteStaffDetail,
  }
}

export default useAccountServices
