import React, { useEffect, useState } from "react";
import "./AyurvedicRoom.css";
import total_bed from "../../../../../assets/svgs/total-bed.svg";
import available_room from "../../../../../assets/svgs/available-bed.svg";
import occupied_room from "../../../../../assets/svgs/occupied-bed.svg";
import reserved_room from "../../../../../assets/svgs/reserved-bed.svg";
import Mask_group_card from "../../../../../assets/svgs/Mask-group-card.svg";
import edit_icon from "../../../../../assets/svgs/edit-icon.svg";
import delete_red from "../../../../../assets/svgs/delete-red.svg";
import { GoPlus } from "react-icons/go";
import { HiOutlineExclamationCircle } from "react-icons/hi";
import useAccommodationServices from "../../../../services/api-services/accommodationServices";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Tab, TabPane } from "react-bootstrap";

const AyurvedicRoom = ({handleShowAyurvedicRoom}) => {
  const [roomList, setRoomList] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState("all");
  const [ayurvedicRoomDetails, setAyurvedicRoomDetails] = useState({});

  const {
    getAyurvedicRoom
  } = useAccommodationServices();

  useEffect(() => {
    getAyurvedicRoomData();
  }, []);

  const getAyurvedicRoomData = async () => {
    try {
      const response = await getAyurvedicRoom();
      if (response?.success) {
        console.log(response.data,"=====response ayuvedic");


      }
    } catch {}
  };





  // filter methode
  const filteredAyurvedicRoom = async (value) => {
    try {
      if (value === "all") {
        getAyurvedicRoomData();
      } else {
        // call function from useAccommodationServices

      }
    } catch (error) {
      console.log(error, "error=========");
    }
  };

  return (
    <div className="px-3">
      <div className="">
        <div className="second-container mb-4 row bg-light rounded">
          <div className="col-8 d-flex  gap-5 ">
          </div>

          <div className="col-4 details-ward  d-flex align-items-center justify-content-end ">
            <button className="px-4">
              <HiOutlineExclamationCircle
                size={15}
                style={{ marginRight: "5PX" }}
              />
              Details
            </button>
            <button className="px-4 ms-3" onClick={handleShowAyurvedicRoom}>
              <GoPlus size={18} style={{ marginRight: "5PX" }} />
              Add Ayuvedic Room
            </button>
          </div>
        </div>
      </div>

      <div className="row gap-2 d-flex mt-3 px-1">
        <div className="col total-therapy-bed">
          <div className="d-flex ">
            <div className="me-3">
              <div className="">
                <img src={total_bed} alt="" />
              </div>
              <p className="h3 mt-2">
                {ayurvedicRoomDetails?.total_room_count}
              </p>
            </div>
            <div className="">
              <p className="head-bed m-0 mb-1">Total Therapy <br /> Bed</p>
              <p className=" m-0 p-0 total-no">Total No. of Bed</p>
            </div>
          </div>
        </div>
        <div className="col total-therapy-bed">
          <div className="d-flex">
            <div className="me-3">
              <div className="">
                <img src={available_room} alt="" />
              </div>
              <p className="h3 mt-2">
                {ayurvedicRoomDetails?.available_room_count}
              </p>
            </div>
            <div className="">
              <p className="head-bed m-0 mb-1">Available Therapy Bed</p>
              <p className=" m-0 p-0 total-no">Total No. of Available Bed</p>
            </div>
          </div>
        </div>
        <div className="col total-therapy-bed">
          <div className="d-flex">
            <div className="me-3">
              <div className="">
                <img src={occupied_room} alt="" />
              </div>
              <p className="h3 mt-2">
                {ayurvedicRoomDetails?.occupied_room_count}
              </p>
            </div>
            <div className="">
              <p className="head-bed m-0 mb-1">Occupied Therapy Bed</p>
              <p className=" m-0 p-0 total-no">Total No. of Occupied Bed</p>
            </div>
          </div>
        </div>
        <div className="col total-therapy-bed">
          <div className="d-flex ">
            <div className="me-3">
              <div className="">
                <img src={reserved_room} alt="" />
              </div>
              <p className="h3 mt-2">
                {ayurvedicRoomDetails?.reserved_room_count}
              </p>
            </div>
            <div className="">
              <p className="head-bed m-0 mb-1">Reserved Therapy Bed</p>
              <p className=" m-0 p-0 total-no">Total No. of Reserved Bed</p>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-3 mx-2 ">
        <div className="col-6 d-flex align-items-center ">
          <h3>Room List</h3>
        </div>
        <div className="bed-list-sub-out col-6 d-flex justify-content-between  ">
          <button
            className={`px-5 ${
              selectedFilter === "all" ? "bed-list-sub " : ""
            }`}
            onClick={() => {
              setSelectedFilter("all");
              filteredAyurvedicRoom("all");
            }}
          >
            All
          </button>
          <button
            className={`px-5 ${
              selectedFilter === "available" ? "bed-list-sub" : ""
            }`}
            onClick={() => {
              setSelectedFilter("available");
              filteredAyurvedicRoom("available");
            }}
          >
            Available
          </button>
          <button
            className={`px-5 ${
              selectedFilter === "occupied" ? "bed-list-sub" : ""
            }`}
            onClick={() => {
              setSelectedFilter("occupied");
              filteredAyurvedicRoom("occupied");
            }}
          >
            Occupied
          </button>
          <button
            className={`px-5 ${
              selectedFilter === "reserved" ? "bed-list-sub" : ""
            }`}
            onClick={() => {
              setSelectedFilter("reserved");
              filteredAyurvedicRoom("reserved");
            }}
          >
            Reserved
          </button>
        </div>
      </div>

      <div className="row  mt-3 px-1">
        <Tab.Container activeKey={"tab1"}>
          <div className="patient-list-container">
            <Tab.Content className="patient-table-container container-white">
              <TabPane eventKey="tab1" className="">
                <div className="table-responsive">
                  <table className="table patient-list-table ">
                    <thead className="patient-list-table-head">
                      <tr>
                        <th style={{ width: 0 }}>Sl.no</th>
                        <th className="text-center" style={{ width: 0 }}>
                          Bed ID
                        </th>
                        <th style={{ width: 0 }}>Bed Name</th>
                        <th style={{ width: 0 }}>Floor/Room No</th>
                        <th style={{ width: 0 }}>Patient Name</th>
                        <th style={{ width: 0 }}>Patient ID</th>
                        <th style={{ width: 0 }}>IP Number</th>
                        <th style={{ width: 0 }}>Status</th>
                        <th style={{ width: 0 }}>Actions</th>
                      </tr>
                    </thead>
                    <tbody className="patient-list-table-body">
                      {/* {roomList &&
                        roomList.map((item, index) => {
                          return (
                            
                          );
                        })} */}
                    </tbody>
                  </table>
                </div>
              </TabPane>
            </Tab.Content>
          </div>
        </Tab.Container>
      </div>
    </div>
  );
};

export default AyurvedicRoom;
