import React, { useEffect, useState } from 'react'
import AvailableIcon from '../../../../../assets/svgs/available.svg' 
import ReservedIcon from '../../../../../assets/svgs/reserved.svg' 
import OccupiedIcon from '../../../../../assets/svgs/occupied.svg' 
const AdmWardBed = (props) => {
  const{
    handleBedSelection,
    days,
    value,
  }=props

  const [totalCost, setTotalCost] = useState(0);
  useEffect(() => {
    const wardRate = value?.singleWard?.rate_per_day || 0;
    setTotalCost(days * wardRate);
  }, [days, value?.singleWard]);

  return (
    <div className='row'>
        
        <div className="col-8 p-3">
          <h4>Bed List</h4>
          <div className="row mb-4" style={{height:"28rem",overflowY:"scroll"}}>
            {value?.bedList?.map((bed,i) => (
              <div className="col-4 p-3 px-5 pt-4" key={i}>
                <div
                  className={`col-12 border border-2 rounded-3 bed-card ${value?.selectedBed === bed?.id ? 'border-primary' : '' }`}
                  onClick={() => handleBedSelection(bed)}
                  style={{border: value?.selectedBed === bed?.id ? '2px solid blue' : '', cursor: bed?.bed_status === 'available' ? 'pointer' : 'help'}}
                >
                <span className='move-icon'>
                    {bed?.bed_status === 'available' && <img src={AvailableIcon} width={60} height={60} />}
                    {bed?.bed_status === 'reserved'&&<img src={ReservedIcon} width={60} height={60}/>}
                    {bed?.bed_status === 'occupied'&&<img src={OccupiedIcon} width={60} height={60}/>}
                </span>
                <div className='row text-center'>
                    <h6 className='col-12 p-2 pt-4 fs-6'>{bed?.bed_no}</h6>
                    <div className='col-12 p-2 d-flex justify-content-center'>
                    <div className={`col-5 p-2 rounded-3  ${ bed?.bed_status === 'available'? 'available-bed': bed?.bed_status === 'reserved'? 'reserved-bed': 'occupied-bed' }`}>
                    {bed?.bed_status[0].toUpperCase() + bed?.bed_status.slice(1).toLowerCase()}
                    </div>
                    </div>
                </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        
        <div className="col-4 p-4 py-3 ps-3 rounded-3" style={{backgroundColor:'#FAFAFA'}}>
          <h4>Ward Details</h4>
            <div className="row mb-3">
              <span className='col-4'>Ward ID</span>
              <input type="text" className=" col-8 p-2 px-1 border border-1 rounded-2" value={value?.singleWard?.ward_id} disabled />
            </div>
            <div className="row mb-3">
              <span className='col-4'>Ward Name</span>
              <input type="text" className=" col-8 p-2 px-1 border border-1 rounded-2" value={value?.singleWard?.ward_name} disabled />
            </div>
            <div className=" row mb-3">
              <span className='col-4'>Ward Type</span>
              <input type="text" className=" col-8 p-2 px-1 border border-1 rounded-2" value={value?.singleWard?.ward_type} disabled/>
            </div>
            <div className="row mb-3">
              <span className='col-4'>Total Bed</span>
              <input type="text" className=" col-8 p-2 px-1 border border-1 rounded-2" value={value?.singleWard?.total_bed_count} disabled/>
            </div>
            <div className="col-12 mb-3 d-flex p-1">
              <input type="checkbox" className='col-1' checked={value?.singleWard?.eligible_for_insurance} disabled/> 
              <span className='col text-start'>Eligible for Insurance</span>
            </div>
            <div className=" row mb-3">
                <div className='col-12'>
                <div className='fw-bold text-decoration-underline ps-1 pb-2'>Cost Estimation Based on Duration</div>
                </div>
                <div className='col-12 d-flex justify-content-between' >
                <span className='col-6 text-start p-1'>Estimated Date of Discharge</span>
                <span className='col-6 text-end p-1'>Rate per Day</span>
                </div>
                
                <div className='col-12 d-flex justify-content-between' >
                <span className='col-6 text-start p-1'>
                  <input
                    className='input-text-control p-1'
                    type="date"
                    value={value?.dischargeDate}
                    disabled
                  />
                </span>
                <span className='col-6 text-end p-1'>₹{value?.singleWard?.rate_per_day}.toFixed(2)</span>
                </div>
            </div>
            <hr />
            <h5 className='text-end p-2 fs-5 fw-400' style={{color:"#E3B075"}}><span className='text-secondary fs-6 fw-200'>No of Days : {days}</span><br/>Total : ₹{totalCost.toFixed(2)}</h5>
        </div>

    </div>
  )
}

export default AdmWardBed