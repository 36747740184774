import React from 'react'
import InputTextBox from '../../../../shared/form-components/form-text/InputTextBox'
import TextAreaBox from '../../../../shared/form-components/form-textarea/TextAreaBox'
import DropDownBox from '../../../../shared/form-components/dropdown/CustomDropDown'
const SalaryDetails = ({handleBack,handleNext,value,setValue,functions}) => {
  return (
    <div>
            <div className="row">
                <div className="col-9 page-title-label">
                    Staff Details
                </div>
            </div>
           
            <div className="row">
                <div className="col-6 left-split-container">
                  
                   <div className="col-12 p-1">
                             <InputTextBox
                                label={'Opening Balance'}
                                containerClass={'col-12 mb-3'}
                                onChange={setValue?.setOpeningBalance}
                                defvalue={value?.openingBalance}
                                // disabled={true}
                                // required={true}
                            />
                    </div>
                    
                    <div className="col-12 p-1">
                             {/* <InputTextBox
                                label={'Staff Grade'}
                                containerClass={'col-12 mb-3'}
                                onChange={setValue?.setStaffGrade}
                                defvalue={value?.staffGrade}
                                // disabled={true}
                                // required={true}
                            /> */}
                             <DropDownBox
                                label={'Staff Grade'}
                                containerClass={'col-12 mb-3'}
                                addNew={true}
                                fluid={true}
                                search={true}
                                selection={true}
                                state={'staff_grade'}
                                setNew={functions?.addPatientProperty}
                                options={value?.staffGradeList}
                                setDataValue={setValue?.setStaffGrade}
                                selectedValue={value?.staffGrade}
                                />
                    </div>
                   
                    <div className="col-12 p-1">
                             {/* <InputTextBox
                                label={'Designation'}
                                containerClass={'col-12 mb-3'}
                                onChange={setValue?.setDesignation}
                                defvalue={value?.designation}
                                // disabled={true}
                                // required={true}
                            /> */}
                             <DropDownBox
                                label={'Designation'}
                                containerClass={'col-12 mb-3'}
                                addNew={true}
                                fluid={true}
                                search={true}
                                selection={true}
                                state={'designation'}
                                setNew={functions?.addPatientProperty}
                                options={value?.designationList}
                                setDataValue={setValue?.setDesignation}
                                selectedValue={value?.designation}
                                />
                    </div>
                    <div className="col-12 d-flex justify-content-between">
                        <div className="col-6 p-1">
                            <InputTextBox
                                label={'Allowed Leave'}
                                containerClass={'col-12 mb-3'}
                                onChange={setValue?.setAllowedLeave}
                                defvalue={value?.allowedLeave}
                                // disabled={true}
                                // required={true}
                            />
                        </div>
                        <div className="col-6 p-1">
                            <InputTextBox
                                    label={'PF Cut'}
                                    containerClass={'col-12 mb-3'}
                                    onChange={setValue?.setPfCut}
                                    defvalue={value?.pfCut}
                                    // disabled={true}
                                    // required={true}
                                />
                        </div>
                    </div>

                 
                </div>
                <div className="col-6">
                <div className="col-12 d-flex justify-content-between">
                        <div className="col-6 p-1">
                            <InputTextBox
                                label={"Leave Cut"}
                                containerClass={'col-12 mb-3'}
                                onChange={setValue?.setLeaveCut}
                                defvalue={value?.leaveCut}
                                // disabled={true}
                                // required={true}
                            />
                        </div>
                        <div className="col-6 p-1">
                            <InputTextBox
                                    label={'LIC Cut'}
                                    containerClass={'col-12 mb-3'}
                                    onChange={setValue?.setLicCut}
                                    defvalue={value?.licCut}
                                    // disabled={true}
                                    // required={true}
                                />
                        </div>
                    </div>

                    <div className="col-12 d-flex justify-content-between">
                        <div className="col-6 p-1">
                            <InputTextBox
                                label={'Salary'}
                                containerClass={'col-12 mb-3'}
                                onChange={setValue?.setSalary}
                                defvalue={value?.salary}
                                // disabled={true}
                                // required={true}
                            />
                        </div>
                        <div className="col-6 p-1">
                            <InputTextBox
                                    label={'Esi Cut'}
                                    containerClass={'col-12 mb-3'}
                                    onChange={setValue?.setEsi}
                                    defvalue={value?.esi}
                                    // disabled={true}
                                    // required={true}
                                />
                        </div>
                    </div>

                    <div>
                    <TextAreaBox
                        label={'Remark'}
                        rows={'5'}
                        required={true}
                        containerClass={'col-12 mb-3'}
                        onChange={setValue?.setSalRemark}
                        defvalue={value?.salRemark}
                    />
                    </div>


                </div>
            </div>
            <div className='container-fluid mt-4 p-4 ps-3'>
                <div className='co1-12 d-flex justify-content-end p-2'>
                    <div className='buttonviewdown' onClick={handleBack}>Back</div>
                    <div className='buttonviewdown'  onClick={handleNext}>Next</div>
                </div>
            </div>
      </div>
  )
}

export default SalaryDetails