import React from "react";
import "./patientform.css"; // Import your custom styles here
import { MEDIA_URL } from "../../../../../axios";
import moment from "moment";
import { Image } from "antd";
import { IoPersonCircleSharp } from "react-icons/io5";

const PatientForm = ({patientData,otalOp,otalIp,opIpFullData}) => {
  console.log(patientData, "====!!!!!!!!!!=====patientData");

  return (
    <div className="container mt-4 p-1 m-0 m-auto mx-2 text-dark text-opacity-75">
      <form>
        {/* Patient Details */}
        <div className="row mb-3">
          <h5>Patient Details</h5>
          <div className="col-md-4">
            <label>Mobile Number</label>
            <input
              type="text"
              className="form-control mt-1"
              value={patientData?.mobileNumber}
              readOnly
            />
          </div>
          <div className="col-md-4">
            <label>First Name</label>
            <input
              type="text"
              className="form-control mt-1"
              value={patientData?.firstName}
              readOnly
            />
          </div>
          <div className="col-md-4">
            <label>Last Name</label>
            <input
              type="text"
              className="form-control mt-1"
              value={patientData?.lastName}
              readOnly
            />
          </div>
          <div className="col-md-4">
            <label>Date of Birth</label>
            <input
              type="date"
              className="form-control mt-1"
              value={patientData?.dOB}
              readOnly
            />
          </div>
          <div className="col-md-4">
            <label>Age</label>
            <input
              type="text"
              className="form-control mt-1"
              value={`${patientData?.ageYear} years ${patientData?.ageMonth} months ${patientData?.ageDate} days`}
              readOnly
            />
          </div>
          <div className="col-md-4">
            <label>Gender</label>
            <input
              type="text"
              className="form-control mt-1"
              value={patientData?.gender}
              readOnly
            />
          </div>
          <div className="col-md-4">
            <label>Email</label>
            <input
              type="email"
              className="form-control mt-1"
              value={patientData?.email}
              readOnly
            />
          </div>
          <div className="col-md-4">
            <label>UIN</label>
            <input
              type="text"
              className="form-control mt-1"
              value={patientData?.uIN}
              readOnly
            />
          </div>
          <div className="col-md-4">
            <label>Address Line 1</label>
            <input
              type="text"
              className="form-control mt-1"
              value={patientData?.address1}
              readOnly
            />
          </div>
          <div className="col-md-4">
            <label>Address Line 2</label>
            <input
              type="text"
              className="form-control mt-1"
              value={patientData?.address2}
              readOnly
            />
          </div>
          <div className="col-md-4">
            <label>City *</label>
            <input
              type="text"
              className="form-control mt-1"
              value={patientData?.city}
              readOnly
            />
          </div>
          <div className="col-md-4">
            <label>Pincode</label>
            <input
              type="text"
              className="form-control mt-1"
              value={patientData?.pincode}
              readOnly
            />
          </div>
          <div className="col-md-4">
            <label>State</label>
            <input
              type="text"
              className="form-control mt-1"
              value={patientData?.state}
              readOnly
            />
          </div>
          <div className="col-md-4">
            <label>District</label>
            <input
              type="text"
              className="form-control mt-1"
              value={patientData?.district}
              readOnly
            />
          </div>
          <div className="col-md-4">
            <label>Country</label>
            <input
              type="text"
              className="form-control mt-1"
              value={patientData?.country}
              readOnly
            />
          </div>
          <div className="col-md-4">
            <label>Admission Date</label>
            <input
              type="date"
              className="form-control mt-1"
              value={patientData?.admDate}
              readOnly
            />
          </div>
          <div className="col-md-4">
            <label>Admission Time</label>
            <input
              type="time"
              className="form-control mt-1"
              value={patientData?.admTime}
              readOnly
            />
          </div>
          <div className="col-md-4">
            <label>File Number</label>
            <input
              type="text"
              className="form-control mt-1"
              value={patientData?.fileNum}
              readOnly
            />
          </div>
          <div className="col-md-4">
            <label>Casualty</label>
            <input
              type="text"
              className="form-control mt-1"
              value={patientData?.casualty == true ? "Yes" : "No"}
              readOnly
            />
          </div>
          <div className="col-md-4">
            <label>Patient Type</label>
            <input
              type="text"
              className="form-control mt-1"
              value={patientData?.patientType}
              readOnly
            />
          </div>
          <div className="col-md-4">
            <label>Referred Type</label>
            <input
              type="text"
              className="form-control mt-1"
              value={patientData?.refferedType}
              readOnly
            />
          </div>
          <div className="col-md-4">
            <label>Mobile Number</label>
            <input
              type="text"
              className="form-control mt-1"
              value={patientData?.emgContName}
              readOnly
            />
          </div>
          <div className="col-md-4">
            <label>Emergency Contact Number</label>
            <input
              type="text"
              className="form-control mt-1"
              value={patientData?.emgContNumber}
              readOnly
            />
          </div>
        </div>
        {/* Document Category */}
        <div className="row mb-3">
          <h5 className="fs-5">Passport & Records</h5>
          <div className="col-md-4">
            <label>Passport Number</label>
            <input
              type="text"
              className="form-control mt-1"
              value={patientData?.passportNo}
              readOnly
            />
          </div>
          <div className="col-md-4">
            <label>Date of Expiry</label>
            <input
              type="text"
              className="form-control mt-1"
              value={moment(patientData?.passExpiry).format("DD-MM-YYYY")}
              readOnly
            />
          </div>
          <div className="col-md-4 d-flex flex-column">
            <label>Passport</label>
            {patientData.docFile ? (
              <Image
                src={MEDIA_URL + patientData?.docFile}
                alt="Patient Document"
                className="document-image mt-1"
                height={100}
                width={100}
              />
            ) : (
              <p>No document available</p>
            )}
          </div>
          <div className="col-md-4 mt-3">
            <label>Visa Type</label>
            <input
              type="text"
              className="form-control mt-1"
              value={patientData?.visaType}
              readOnly
            />
          </div>
          <div className="col-md-4 mt-3">
            <label>Validity Period</label>
            <input
              type="text"
              className="form-control mt-1"
              value={moment(patientData?.validityPeriod).format("DD-MM-YYYY")}
              readOnly
            />
          </div>

          {/* <div className="col-md-4">
            <label>Issued Date</label>
            <input
              type="text"
              className="form-control mt-1"
              value={moment(patientData?.issuedDate).format("DD-MM-YYYY")}
              readOnly
            />
          </div> */}
          <div className="col-md-4 d-flex flex-column mt-3">
            <label>Visa</label>
            {patientData?.visaDoc ? (
              <Image
                src={MEDIA_URL + patientData?.visaDoc}
                alt="Patient Document"
                className="document-image mt-1"
                height={100}
                width={100}
              />
            ) : (
              <p>No document available</p>
            )}
          </div>

          <div className="col-md-4">
            <label>Purpose of Visit</label>
            <textarea
              type="text"
              className="form-control mt-1"
              value={patientData?.visitPurpose}
              readOnly
            />
          </div>
          <div className="col-md-4">
            <label>Remarks</label>
            <textarea
              type="text"
              className="form-control mt-1"
              value={patientData?.Remarks}
              readOnly
            />
          </div>
        </div>

        {/* Total Op= */}
        <div className="row px-2 pe-3">
          <div className="col-md-6 pe-4  rounded-2">
            {/* <div className="p-2 bg-warning  d-flex align-items-center" style={{background:"#FAFAFA"}}> */}
            <div
              className="p-2 row op-div-first"
              style={{ borderBottom: "1px solid grey", background: "#FAFAFA" }}
            >
              <div className="p-2  col-md-6 ">
                <label htmlFor="" className="">
                  Total OP
                </label>
              </div>
              <div className="col-md-6">
                <div className="p-1 border bg-white rounded">
                  <input
                    type="text"
                    name="room_id"
                    className="w-100 h-100"
                    value={otalOp}
                    readOnly
                  />
                </div>
              </div>
            </div>

            {opIpFullData.map((data)=>(
              <div className="p-2 row " style={{ background: "#FAFAFA" }}>
              <div className="p-0 m-0 col-md-6  profile-name">
                <div className="w-100 h-100 d-flex ">
                  <div className="profile-div w-25 h-100 d-flex justify-content-center align-items-center">
                  <IoPersonCircleSharp size={50} />
                    {/* <img src="" alt="" /> */}
                  </div>
                  <div className="name-div w-75 h-100 ps-3  d-flex flex-lg-column justify-content-center">
                    <p className="p-0 m-0 fw-bold">{data.physician_id}</p>
                    {/* <p className="p-0 m-0">General physician</p> */}
                  </div>
                </div>
              </div>
              <div className="col-md-6 d-flex align-items-center">
                <div className="p-1 border bg-white rounded">
                  <input
                    type="text"
                    name="room_id"
                    value={data.op_count}
                    className="w-100 h-100"
                    readOnly
                  />
                </div>
              </div>
            </div>
            ))}

            <div
              className="p-2 row op-div-last"
              style={{ background: "#FAFAFA" }}
            >
              <div className="p-0 m-0 col-md-6 profile-name">
                <div className="w-100 h-100 d-flex ">
                  <div className="profile-div w-25 h-100 d-flex justify-content-center align-items-center">
                  <IoPersonCircleSharp size={50} />
                    {/* <img src="" alt="" /> */}
                  </div>
                  <div className="name-div w-75 h-100 ps-3 d-flex flex-lg-column justify-content-center">
                    <p className="p-0 m-0 fw-bold">Dr.Name</p>
                    <p className="p-0 m-0">General physician</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 d-flex align-items-center">
                <div className="p-1 border bg-white rounded">
                  <input
                    type="text"
                    name="room_id"
                    className="w-100 h-100"
                    readOnly
                  />
                </div>
              </div>
            </div>

            {/* <div className="p-2 row op-div-last" style={{background:"#FAFAFA"}}>
                <div className="p-2  col-md-6 ">
                <label htmlFor="" className="">
                    Total OP
                  </label>
                </div>
                <div className="col-md-6">
                <div className="p-2 border bg-light rounded">
                    <input type="text" name="room_id" className="w-100 h-100" />
                  </div>
                </div>
                </div> */}

            {/* </div> */}
          </div>

          <div className="col-md-6 ps-4  rounded-2">
            {/* <div className="p-2 bg-warning  d-flex align-items-center" style={{background:"#FAFAFA"}}> */}
            <div
              className="p-2 row op-div-first"
              style={{ borderBottom: "1px solid grey", background: "#FAFAFA" }}
            >
              <div className="p-2  col-md-6 ">
                <label htmlFor="" className="">
                  Total IP
                </label>
              </div>
              <div className="col-md-6">
                <div className="p-1 border bg-white rounded">
                  <input
                    type="text"
                    name="room_id"
                    value={otalIp}
                    className="w-100 h-100"
                    readOnly
                  />
                </div>
              </div>
            </div>

            {opIpFullData.map((data)=>(
              <div className="p-2 row " style={{ background: "#FAFAFA" }}>
              <div className="p-0 m-0 col-md-6  profile-name">
                <div className="w-100 h-100 d-flex ">
                  <div className="profile-div w-25 h-100 d-flex justify-content-center align-items-center">
                  <IoPersonCircleSharp size={50} />
                    {/* <img src="" alt="" /> */}
                  </div>
                  <div className="name-div w-75 h-100 ps-3  d-flex flex-lg-column justify-content-center">
                    <p className="p-0 m-0 fw-bold">{data.physician_id}</p>
                    {/* <p className="p-0 m-0">General physician</p> */}
                  </div>
                </div>
              </div>
              <div className="col-md-6 d-flex align-items-center">
                <div className="p-1 border bg-white rounded">
                  <input
                    type="text"
                    name="room_id"
                    value={data.ip_count}
                    className="w-100 h-100"
                    readOnly
                  />
                </div>
              </div>
            </div>
            ))}

            <div
              className="p-2 row op-div-last"
              style={{ background: "#FAFAFA" }}
            >
              <div className="p-0 m-0 col-md-6 profile-name">
                <div className="w-100 h-100 d-flex ">
                  <div className="profile-div w-25 h-100 d-flex justify-content-center align-items-center">
                  <IoPersonCircleSharp size={50} />
                    {/* <img src="" alt="" /> */}
                  </div>
                  <div className="name-div w-75 h-100 ps-3 d-flex flex-lg-column justify-content-center">
                    <p className="p-0 m-0 fw-bold">Dr.Name</p>
                    <p className="p-0 m-0">General physician</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 d-flex align-items-center">
                <div className="p-1 border bg-white rounded">
                  <input
                    type="text"
                    name="room_id"
                    className="w-100 h-100"
                    readOnly
                  />
                </div>
              </div>
            </div>

            {/* <div className="p-2 row op-div-last" style={{background:"#FAFAFA"}}>
                <div className="p-2  col-md-6 ">
                <label htmlFor="" className="">
                    Total OP
                  </label>
                </div>
                <div className="col-md-6">
                <div className="p-2 border bg-light rounded">
                    <input type="text" name="room_id" className="w-100 h-100" />
                  </div>
                </div>
                </div> */}

            {/* </div> */}
          </div>
        </div>
        {/* == */}
        <div className="row px-2 pe-3 mt-4">
          <div className="col-md-6 pe-4  rounded-2">
            <div className="p-2 row">
              <div className="p-2  col-md-3 ">
                <label htmlFor="" className="">
                  Total Revenue
                </label>
              </div>
              <div className="col-md-6 d-flex justify-content-start align-items-center">
                <div className="p-1 border bg-white rounded">
                  <input
                    type="text"
                    name="room_id"
                    className="w-100 h-100"
                    readOnly
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <h2 className="fs-5">Patient History</h2>
        <table className="tableStyle">
          <thead>
            <tr>
              <th className="table-headerStyle first-column">Sl.no</th>
              <th className="table-headerStyle">Appoinment No.</th>
              <th className="table-headerStyle">Date</th>
              <th className="table-headerStyle">Physician</th>
              <th className="table-headerStyle">Reason</th>
              <th className="table-headerStyle last-column">IP/OP</th>
            </tr>
          </thead>
          <tbody>
            {patientData?.treatmentHistory.map((data, index) => (
              <tr key={index}>
                <td className="table-cellStyle first-last-column">{data}</td>
                <td className="table-cellStyle second-third-column">{data}</td>
                <td className="table-cellStyle second-third-column">{data}</td>
                <td className="table-cellStyle">{data}</td>
                <td className="table-cellStyle">{data}</td>
                <td className="table-cellStyle first-last-column">{data}</td>
              </tr>
            ))}
          </tbody>
        </table>

        <h5 className="fs-5">Passposrt & Recordes</h5>
        <div className="row  ps-2  pe-3">
          <div className="col-md-6 pe-5  rounded-2">
            <div className=" row ">
              <div className="p-2  col-md-6 ">
                <label htmlFor="" className="">
                  Passposrt Number
                </label>
                <div className="p-1 border bg-light rounded">
                  <input
                    type="text"
                    value={patientData?.passportNo}
                    name="passport_number"
                    className="w-100 h-100"
                    readOnly
                  />
                </div>
                <label htmlFor="" className="mt-2">
                  Date of Expiry
                </label>
                <div className="p-1 border bg-light rounded">
                  <input
                    type="text"
                    value={patientData?.passExpiry}
                    name="date_of_expiry"
                    className="w-100 h-100"
                    readOnly
                  />
                </div>
              </div>
              <div className="col-md-6 d-flex align-items-center justify-content-center">
                <div className="p-1 border bg-light cursor-pointer rounded pdf-upload">
                  <embed
                    type="application/pdf"
                    // width="100%"
                    // height="600px"
                  />
                  {/* <input
                    type="file"
                    id="file-upload-left"
                    style={{
                      display: "none",
                    }}
                    readOnly
                  />
                  <label
                    htmlFor="file-upload-left"
                    className="d-flex align-items-center justify-content-center w-100 h-100 pdf-upload-label"
                  >
                    Choose file
                  </label> */}
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6 pe-4 ps-5 rounded-2">
            <div className=" row ">
              <div className="p-2  col-md-6 ">
                <label htmlFor="" className="">
                  Visa Type
                </label>
                <div className="p-1 border bg-light rounded">
                  <input
                    type="text"
                    value={patientData?.visaType}
                    name="visa_type"
                    className="w-100 h-100"
                    readOnly
                  />
                </div>
                <label htmlFor="" className="mt-2">
                  Validity Period
                </label>
                <div className="p-1 border bg-light rounded">
                  <input
                    type="text"
                    value={patientData?.validityPeriod}
                    name="validity_period"
                    className="w-100 h-100"
                    readOnly
                  />
                </div>
              </div>
              <div className="col-md-6 d-flex align-items-center justify-content-center">
                <div className="p-1 border bg-light  rounded pdf-upload">
                  <embed
                    type="application/pdf"
                    // width="100%"
                    // height="600px"
                  />
                  {/* <input
                    type="file"
                    id="file-upload-left"
                    style={{
                      display: "none",
                    }}
                    readOnly
                  />
                  <label
                    htmlFor="file-upload-left"
                    className="d-flex align-items-center justify-content-center w-100 h-100 pdf-upload-label"
                  >
                    Choose file
                  </label> */}
                </div>
              </div>
            </div>
            <div className="row  border-primary">
              <div className="col-md-6 ">
                <label htmlFor="" className="">
                  Purpose of visit
                </label>
                <div className="p-1 border bg-light rounded">
                  <input
                    type="text"
                    value={patientData?.visitPurpose}
                    name="visit_purpose"
                    className="w-100 h-100"
                    readOnly
                  />
                </div>
              </div>
              <div className="col-md-6 ">
                <label htmlFor="" className="">
                  Remark
                </label>
                <div className="p-1 border bg-light rounded">
                  <input
                    type="text"
                    value={patientData?.Remarks}
                    name="remarks"
                    className="w-100 h-100"
                    readOnly
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Medical Records */}
        <div className="medical-records m-0">
          <h5 className="fs-5">Medical Records</h5>

          {/* Previous Medical Issues */}
          <div className="previous-medical-issues">
            <label>Previous Medical Issues</label>
            <div className="issue-tags mt-1">
              {patientData?.medicalIssue.map((issue, index) => (
                <div
                  key={index}
                  className={`issue-tag ${
                    issue?.isActive ? "active" : "inactive"
                  }`}
                >
                  {issue?.issue}
                </div>
              ))}
            </div>
          </div>

          {/* Treatment History */}
          <div className="treatment-history">
            <label>Treatment History</label>
            <div className="history-list mt-1">
              {patientData?.treatmentHistory.map((history, index) => (
                <div key={index} className="history-item">
                  <span className="history-number">{index + 1}</span>
                  <span className="history-text">{history.treatment}</span>
                </div>
              ))}
            </div>
          </div>

          {/* Allergy */}

          <div className="previous-medical-issues">
            <label>Known Allergies</label>
            <div className="issue-tags mt-1">
              {patientData?.allergyList.map((allergy, index) => (
                <div
                  key={index}
                  className={`issue-tag ${
                    allergy?.isActive ? "active" : "inactive"
                  }`}
                >
                  {allergy?.issue}
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Appointment Detail */}
        {/* <div className="row mb-3">
          <h5 className="fs-5">Appointment Detail</h5>
          <div className="col-md-4">
            <label>Appointment Date</label>
            <input
              type="date"
              className="form-control mt-1"
              value={patientData?.appointmentDate}
              readOnly
            />
          </div>
          <div className="col-md-4">
            <label>Speciality</label>
            <select className="form-select mt-1" disabled>
              <option value={patientData?.speciality}>
                {patientData?.speciality}
              </option>
            </select>
          </div>
          <div className="col-md-4">
            <label>Doctor</label>
            <select className="form-select mt-1" disabled>
              <option value={patientData?.doctor}>{patientData?.doctor}</option>
            </select>
          </div>
          <div className="col-md-4">
            <label>Shift</label>
            <select className="form-select mt-1" disabled>
              <option value={patientData?.shift}>{patientData?.shift}</option>
            </select>
          </div>
          <div className="col-md-4">
            <label>Consultation Mode</label>
            <select className="form-select mt-1" disabled>
              <option value={patientData?.consultationMode}>
                {patientData?.consultationMode}
              </option>
            </select>
          </div>
        </div> */}
      </form>
    </div>
  );
};

export default PatientForm;
